import React, { useContext, useState, useMemo } from "react";
import { SigningContext, EsignContext } from "../../../signing-context";

import { userMatchesSigningLocation } from "../../../signing-modal.helper";
import { CpButton, CpInlineNotification, CpLoader } from "canopy-styleguide!sofe";
import { getKbaCredits } from "../../../kba/kba.resource";
import { handleError } from "src/handle-error.helper";
import { warningToast } from "toast-service!sofe";
import moment from "moment";
import { trim } from "lodash";

export const SendButton = ({
  clientId,
  signingLocations,
  kbaEnabled,
  documentType,
  finalizeSigningExperience,
  finalizeEsignExperience,
  updateKbaCredits,
  signingRequestIsValid,
  showClientRequestCreatedSuccessfullyModal,
  shouldShowSignatureEntryModal,
  descriptionError,
  hasEnoughKbaCredits,
}) => {
  const { esignContext, practitionerMustSign, notValidErrorMessage } = useContext(EsignContext);
  const { signersContext, isNewCrm } = useContext(SigningContext);
  const [loading, setLoading] = useState(false);
  const kbaError = kbaEnabled && !hasEnoughKbaCredits;

  const filteredCollaborators = useMemo(
    () =>
      signersContext?.filter((user) =>
        signingLocations?.find((location) => userMatchesSigningLocation(location, user) && location.signatory_user_id)
      ),
    [signersContext, signingLocations]
  );

  //check fields for each signer, each signer must have a signature or initial field (not just text or date fields)
  const getUserFieldTypeErrors = (locations) => {
    const userLocations = locations.reduce((acc, location) => {
      const userId = location.signatory_user_id;
      if (!acc[userId]) {
        acc[userId] = { text: 0, date: 0, signatureOrInitial: 0 };
      }

      if (location.type === "text") acc[userId].text++;
      if (location.type === "date") acc[userId].date++;
      if (location.type === "signature" || location.type === "initials") acc[userId].signatureOrInitial++;
      return acc;
    }, {});

    const errors = {
      allTextFields: false,
      allDateFields: false,
      allTextAndDateFields: false,
    };

    Object.values(userLocations).forEach(({ text, date, signatureOrInitial }) => {
      if (text > 0 && date === 0 && signatureOrInitial === 0) {
        errors.allTextFields = true;
      } else if (date > 0 && text === 0 && signatureOrInitial === 0) {
        errors.allDateFields = true;
      } else if (text > 0 && date > 0 && signatureOrInitial === 0) {
        errors.allTextAndDateFields = true;
      }
    });

    return errors;
  };

  const { allTextFields, allDateFields, allTextAndDateFields } = getUserFieldTypeErrors(signingLocations);
  const fieldError = allDateFields || allTextFields || allTextAndDateFields;

  let errorMessage;
  if (!signingRequestIsValid && notValidErrorMessage) {
    errorMessage = notValidErrorMessage;
  } else if (fieldError) {
    errorMessage = `${
      allDateFields ? "Date" : allTextFields ? "Text" : "Text and Date"
    } fields only. Add a signature or initial field to send request.`;
  } else {
    errorMessage = "The eSign request is not valid. Please check the fields and try again.";
  }

  const sendClientPortalInvites = () => {
    const invites = filteredCollaborators.filter((user) => user.sendClientPortalInvite);
    if (invites?.length && !isNewCrm) {
      SystemJS.import("clients-ui!sofe").then((clients) =>
        clients.showInviteClientModal(
          clientId,
          submit, //(users)
          undefined,
          false,
          false,
          false,
          "Cancel",
          false,
          undefined,
          invites
        )
      );
    } else {
      submit();
    }
  };

  const signThenSend = () => {
    if (practitionerMustSign) {
      return shouldShowSignatureEntryModal(true, undefined, sendClientPortalInvites, true);
    }
    return sendClientPortalInvites();
  };

  const submit = (invitesSent = false) => {
    setLoading(true);
    const { due_at, reminders, title, description } = esignContext;
    const due_date = due_at ? moment(due_at).format("YYYY-MM-DD") : due_at;
    const reminder_data =
      reminders && reminders.expiration_interval_days
        ? {
            expiration_interval_days: parseInt(reminders.expiration_interval_days, 10),
            interval: reminders.reminder_interval,
          }
        : null;
    const esignDoc = {
      title: trim(title),
      due_date,
      description,
      kba_enabled: kbaEnabled,
    };
    reminder_data ? (esignDoc.reminder_data = reminder_data) : null;
    if (documentType === "letter") {
      finalizeSigningExperience(esignDoc);
      return;
    }
    finalizeEsignExperience(esignDoc, invitesSent, (insufficientCredits) => {
      getKbaCredits().subscribe(({ qty_available, qty_used }) => {
        setLoading(false);

        if (insufficientCredits) {
          updateKbaCredits(qty_available, qty_used);
          warningToast(`Insufficient credits`);
        }
      }, handleError);
    });
  };

  const isValidEsignRequest = () => {
    return signingRequestIsValid && esignContext?.title?.length && !showClientRequestCreatedSuccessfullyModal;
  };

  return (
    <div className="cp-flex-column cp-pb-8">
      <div className="cp-mv-8 cp-flex justify-end">
        <CpButton
          btnType="primary"
          disabled={fieldError || !isValidEsignRequest() || descriptionError || kbaError}
          onClick={signThenSend}
        >
          {loading === true ? <CpLoader dark /> : `${practitionerMustSign ? "Sign and Send" : "Send request"}`}
        </CpButton>
      </div>
      {(fieldError || !signingRequestIsValid) && (
        <div>
          <CpInlineNotification type="warning" message={errorMessage} />
        </div>
      )}
    </div>
  );
};
