import { genQueries, queryOptions } from 'src/react-query';
import { getYTDRevenue, getOutstandingInvoiceTerms, getAvailableCredit } from '../dashboard/dashboard.resources';

export const billingHeaderQueries = genQueries(
  'billingHeaderQueries',
  ({ genKey }: { genKey: (key: string, params: object) => string }) => ({
    outstandingInvoiceTerms: ({ clientId, enabled }: { clientId: number; enabled: boolean }) =>
      queryOptions({
        queryKey: genKey('outstandingInvoiceTerms', { clientId }),
        queryFn: () => getOutstandingInvoiceTerms(clientId),
        staleTime: 1000 * 10,
        enabled,
      }),

    ytdRevenue: ({ clientId }: { clientId: number }) =>
      queryOptions({
        queryKey: genKey('ytdRevenue', { clientId }),
        queryFn: () => getYTDRevenue(clientId),
        staleTime: 1000 * 10,
      }),

    availableCredit: ({ clientId, clientBillingDash }: { clientId: number; clientBillingDash: boolean }) =>
      queryOptions({
        queryKey: genKey('availableCredit', { clientId }),
        queryFn: () => getAvailableCredit(clientId),
        staleTime: 1000 * 10,
        enabled: clientBillingDash,
      }),
  })
);
