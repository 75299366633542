import React, { Suspense, lazy } from "react";
import { HashRouter, Route, Redirect, Switch, useParams } from "react-router-dom";
import { CpLoader } from "canopy-styleguide!sofe";
import { NavContent } from "primary-navbar!sofe";
import { useHasAccess, UserTenantProps, useWithUserAndTenant } from "cp-client-auth!sofe";
import { featureEnabled } from "feature-toggles!sofe";

const AccountManagement = lazy(() =>
  import(/* webpackChunkName: "account-management-comp" */ "./account-management/account-management.component.js")
);
const TeamsAndRoles = lazy(() =>
  import(/* webpackChunkName: "teamsandroles-comp" */ "./team/teams-and-roles.component.js")
);
const User = lazy(() => import(/* webpackChunkName: "user-comp" */ "./profile/user.component.js"));
const Tags = lazy(() => import(/* webpackChunkName: "tags-comp" */ "./tags/tags.component.js"));
const Sources = lazy(() => import(/* webpackChunkName: "sources-comp" */ "./sources/sources.component.js"));
const CompanyTabs = lazy(() =>
  import(/* webpackChunkName: "company-tabs" */ "src/pages/company/company-tabs.component.js")
);
const Betas = lazy(() => import(/* webpackChunkName: "company-tabs" */ "src/pages/betas/betas.component.js"));
const FTLON = lazy(() => import(/* webpackChunkName: "ftl-admin" */ "src/pages/ftl-on.component.js"));
const Roles = lazy(() => import(/* webpackChunkName: "roles" */ "src/pages/roles/roles.component.js"));
const Role = lazy(() => import(/* webpackChunkName: "role" */ "src/pages/role/role.component.js"));
const OnboardingDashboard = lazy(() =>
  import(/* webpackChunkName: "onboarding" */ "src/pages/onboarding/onboarding-dashboard.component.js")
);
const Billing = lazy(() =>
  SystemJS.import("billing-ui!sofe").then((billingUI) =>
    billingUI.getBillingSettings().then((modal) => ({ default: modal }))
  )
);
const IntegrationsUI = lazy(() =>
  SystemJS.import("integrations-ui!sofe").then((module) => module.getIntegrationsComponent())
);

const DocumentSettings = lazy(() =>
  SystemJS.import("docs-ui!sofe").then((module) => {
    return module.getDocumentSettings();
  })
);

export default function Pages() {
  const hasOnboarding = useHasAccess("canopy_onboarding");
  return <PagesClass hasOnboarding={hasOnboarding} />;
}

function CanAcessUser({ children, redirectTo }) {
  let { userId } = useParams();
  const [user] = useWithUserAndTenant();
  // prevent users from viewing team member profiles without the appropriate permission
  // but also make sure they can still view their own profile
  const ownProfile = user.id === userId;
  const hasTeamMembers = useHasAccess("company_settings_team_members");
  if (hasTeamMembers === undefined) return null;
  return hasTeamMembers || ownProfile ? children : <Redirect to={redirectTo} />;
}

@UserTenantProps({
  waitForData: true,
  permissions: {
    hasEditRolesAndPermissions: "company_settings_roles_and_permissions",
    hasViewRolesAndPermissions: "company_settings_roles_and_permissions_view",
    hasAccountManagement: "company_settings_account_management",
    hasCompanyInfo: "company_settings_company_info",
    hasTeamMembers: "company_settings_team_members",
    hasTeams: "company_settings_teams",
    hasRoles: "company_settings_roles",
    hasTags: "company_settings_tags",
    hasBilling: "company_settings_billing",
    hasOnboarding: "canopy_onboarding",
    hasBetas: "company_settings_betas",
  },
})
class PagesClass extends React.PureComponent {
  render() {
    const {
      hasBetas,
      hasEditRolesAndPermissions,
      hasTeamMembers,
      hasCompanyInfo,
      hasViewRolesAndPermissions,
      hasAccountManagement,
      hasTeams,
      hasRoles,
      hasTags,
      hasBilling,
      hasOnboarding,
      hasIntegrations,
    } = this.props.permissions;
    const canAccessPermissions = hasEditRolesAndPermissions || (hasTeamMembers && hasViewRolesAndPermissions);

    return (
      <HashRouter>
        <Suspense
          fallback={
            <NavContent hasTopnavSecondary={true} clientMenuPossible={true}>
              <CpLoader />
            </NavContent>
          }
        >
          <Switch>
            <Route path="/global-settings/ftl-on" children={<FTLON />} />
            <PrivateRoute
              path="/global-settings/account-management"
              permitted={hasAccountManagement}
              children={<AccountManagement />}
            />
            <PrivateRoute path="/global-settings/company" permitted={hasCompanyInfo} children={<CompanyTabs />} />
            <PrivateRoute
              path="/global-settings/team-organization/users"
              permitted={hasTeamMembers}
              children={<TeamsAndRoles tab="users" />}
            />
            <PrivateRoute
              path="/global-settings/team-organization/teams"
              permitted={hasTeams}
              children={<TeamsAndRoles tab="teams" />}
            />
            <PrivateRoute
              path="/global-settings/team-organization/roles"
              permitted={hasRoles}
              children={<TeamsAndRoles tab="roles" />}
            />
            <Route exact path={`/global-settings/team`}>
              <Redirect to={`/global-settings/team-organization/users`} />
            </Route>
            <Route exact path={`/global-settings/team-organization`}>
              <Redirect to={`/global-settings/team-organization/users`} />
            </Route>
            <Route
              path="/global-settings/user/:userId"
              render={() => {
                return (
                  <CanAcessUser redirectTo="/403">
                    <User />
                  </CanAcessUser>
                );
              }}
            />
            <PrivateRoute path="/global-settings/tags" permitted={hasTags} children={<Tags />} />
            <Route path="/global-settings/sources" children={<Sources />} />
            {/* TODO routeProps will need to be updated for react router v6 */}
            <Route
              path="/global-settings/integrations"
              render={(routeProps) => (
                <Suspense fallback={<></>}>
                  <IntegrationsUI {...routeProps} />
                </Suspense>
              )}
            />
            <Route exact path={`/global-settings/roles`}>
              <Redirect to={`/global-settings/permission-sets`} />
            </Route>
            <Route
              exact
              path="/global-settings/roles/:id"
              children={<Redirect to={`/global-settings/permission-sets/:id`} />}
            />
            <PrivateRoute
              permitted={canAccessPermissions}
              path="/global-settings/permission-sets"
              children={<Roles />}
              exact
            />
            <PrivateRoute
              permitted={canAccessPermissions}
              path="/global-settings/permission-sets/:id"
              children={<Role />}
            />
            {/* TODO routeProps (uses {match, history}) will need to be updated for react router v6 */}
            <PrivateRoute
              permitted={hasBilling || hasIntegrations}
              path="/global-settings/billing/:tab?/:terms?"
              render={(routeProps) => <Billing {...routeProps} />}
            />
            <PrivateRoute
              permitted={featureEnabled("toggle_doc_automation_rename")} //userHasAccess("company_settings_files")
              path="/global-settings/files"
              render={(routeProps) => <DocumentSettings {...routeProps} />}
            />
            <PrivateRoute
              permitted={hasOnboarding}
              path={[
                "/global-settings/onboarding",
                "/global-settings/onboarding/workloads/:workloadId",
                "/global-settings/onboarding/workloads/:workloadId/jobs/:jobId",
              ]}
              exact
              children={<OnboardingDashboard />}
            />
            <PrivateRoute permitted={hasBetas} path="/global-settings/experimental-features" children={<Betas />} />;
          </Switch>
        </Suspense>
      </HashRouter>
    );
  }
}

const PrivateRoute = ({ render, children, permitted, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (permitted ? render ? render(props) : children : <Redirect to="/403" />)} />
  );
};
