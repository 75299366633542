import React, { useMemo } from "react";
import { CpLoader } from "canopy-styleguide!sofe";
import { AssignedClientsSection } from "src/contact-modal/view-contact/sections/assigned-clients/assigned-clients-section.component";
import { ClientPortalSection } from "src/contact-modal/view-contact/sections/client-portal/client-portal-section.component";
import { ContactInfoSection } from "src/contact-modal/view-contact/sections/contact-info-section.component";
import { tw } from "src/tailwind";
import usePreviewContactMergeQuery from "./use-contact-merge-query.hook";
import { mapContactDataToSections } from "src/contact-modal/view-contact/view-contact.helpers";

export function ConfirmMerge({ mainContactId, contactIds }: { mainContactId: string; contactIds: string[] }) {
  const { mergedContactPreview, mergedContactPreviewQuery } = usePreviewContactMergeQuery({
    main_contact_id: mainContactId,
    contact_ids: contactIds.filter((id) => id !== mainContactId),
  });
  const loading = mergedContactPreviewQuery.isLoading;
  const { contactInfo, clientPortalInfo, assignedClients } = useMemo(() => {
    return mapContactDataToSections(mergedContactPreview);
  }, [mergedContactPreview]);

  return loading ? (
    <CpLoader size="lg" />
  ) : (
    <div className={tw("flex flex-col gap-[1.6rem]")}>
      <ContactInfoSection contactInfo={contactInfo} wellLevel={1} />
      {clientPortalInfo.clientPortalEmail && (
        <ClientPortalSection
          clientPortalInfo={clientPortalInfo}
          contactId={mergedContactPreview?.id}
          contactName={mergedContactPreview?.name ?? ""}
          disableAllLinks={true}
          wellLevel={1}
        />
      )}
      {!!assignedClients.length && (
        <AssignedClientsSection assignedClients={assignedClients} disableAllLinks={true} wellLevel={1} />
      )}
    </div>
  );
}
