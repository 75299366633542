import React, { useState, useEffect, useRef, forwardRef, useContext } from "react";
import { ResizableBox } from "react-resizable";
import { useCss, k, a, m } from "kremling";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { signingFieldTypes, ResizableFieldDimensions, minFieldWidthAllowed, minFieldHeightAllowed } from "../constants";
import { userMatchesSigningLocation } from "../signing-modal.helper";

import { SigningContext } from "../signing-context";
import "react-resizable/css/styles.css";
import { CompletedField } from "./completed-field.component";
import { getDefaultFieldWidth, calculateFontSize } from "src/signing-resource.helper";

// TODO move to own component
// TODO also remove forwardRef
const ResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, signingObject, ...restProps } = props;
  const isTeamMember =
    signingObject?.isTeamMember ||
    signingObject?.signatory_user_role === "TeamMember" ||
    signingObject?.role === "TeamMember";

  return (
    <div
      ref={ref}
      className="custom-resize-handle"
      {...restProps}
      onMouseDown={(e) => {
        // prevent drag behavior on resize handle
        e.stopPropagation();
        e.preventDefault();

        // now call the original onMouseDown from react-resizable
        props.onMouseDown?.(e);
      }}
      style={{
        position: "absolute",
        width: "8px",
        height: "8px",
        backgroundColor: isTeamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)",
        border: `1px solid ${isTeamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"}`,
        borderRadius: "50%",
        right: "-4px",
        top: "-4px",
        cursor: "ne-resize",
        zIndex: 1000,
      }}
    />
  );
});
ResizeHandle.displayName = "ResizeHandle";

export const DraggableResizableField = ({
  fieldRef,
  context,
  documentRef,
  dropdownRef,
  handleShowToolbar,
  hasBeenSigned,
  isDateObject,
  isSignatureObject,
  openSignatureEntryModal,
  resizeHandleRef,
  showFieldHeader,
  signerFieldProps,
  signingLocationsAreDraggable,
  signingObject,
  updateSigningObject,
}) => {
  const scope = useCss(css);
  const defaultWidth = getDefaultFieldWidth(signingObject.type);
  const { activeSignerFieldId, setActiveSignerFieldId, hideSignerToolbar, setHideSignerToolbar } =
    useContext(SigningContext);

  // default starting height or width of the field
  const [size, setSize] = useState({
    width: signingObject?.width || defaultWidth,
    height: signingObject?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
  });
  const completedFieldRef = useRef(null);
  // TODO-BP finish cleanup of dynamic font size logic
  // const [signatureFontSize, setSignatureFontSize] = useState(12);
  const signatureFontSize = 12;

  const [maxConstraints, setMaxConstraints] = useState([400, 400]); // Default fallback height/width in case we can't get the page
  const [minConstraints, setMinConstraints] = useState([minFieldWidthAllowed, minFieldHeightAllowed]); // minimum height/width allowed
  const [textFieldValue, setTextFieldValue] = useState(signingObject?.value || "");

  const [showToolbar, setShowToolbar] = useState(false); // used to show toolbar once component is mounted
  const [fieldIsActive, setFieldIsActive] = useState(false);

  const signingObjectId = signingObject.esigning_location_id || signingObject.id;

  const isTeamMember =
    signingObject?.isTeamMember ||
    signingObject?.signatory_user_role === "TeamMember" ||
    signingObject?.role === "TeamMember";
  const isTextField = signingObject?.type === signingFieldTypes.TEXT;
  const textareaRef = useRef(null);
  const focusRef = useRef(null);

  const forCurrentUser = context?.loggedInUser
    ? userMatchesSigningLocation(signingObject, context.loggedInUser)
    : false;

  const previousType = useRef(signingObject.type);

  useEffect(() => {
    // Find the page container element
    const fieldElement = documentRef.current;
    if (fieldElement) {
      // get page size from page container so max field size is limited by page size
      const pageElement = fieldElement.closest('[id^="pageView"]');
      if (pageElement) {
        const pageWidth = pageElement.clientWidth;
        const pageHeight = pageElement.clientHeight;
        setMaxConstraints([pageWidth, pageHeight]);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    // set to true to show toolbar once component is mounted
    setShowToolbar(true);
    // initialize field size to signing object for templates
    if (!signingObject.width || !signingObject.height) {
      const initialFieldObject = {
        ...signingObject,
        width: signingObject?.width || defaultWidth,
        height: signingObject?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
      };
      updateSigningObject(initialFieldObject);
    }
  }, []);

  useEffect(() => {
    // show toolbar when new field is dropped on page
    if (showToolbar && typeof handleShowToolbar === "function") {
      const fieldElement = fieldRef.current?.querySelector("[data-field-container]");
      if (fieldElement) {
        handleShowToolbar({ target: fieldElement });
      }
      setShowToolbar(false);
    }
  }, [showToolbar, handleShowToolbar]);

  const handleResize = (e, { size: newSize }) => {
    //TODO move below into useLayoutEffect?
    if (isTextField && signingObject.value) {
      // create a temp textarea to get the content height
      const tempTextArea = document.createElement("textarea");
      tempTextArea.style.cssText = `
        position: absolute;
        visibility: hidden;
        height: auto;
        width: ${newSize.width}px; 
        font: ${window.getComputedStyle(textareaRef.current).font};
        line-height: ${window.getComputedStyle(textareaRef.current).lineHeight};
        white-space: pre-wrap;
        overflow-wrap: break-word;
        padding: 0;
        border: 0;
        margin: 0;
      `;
      tempTextArea.value = textareaRef.current.value;

      document.body.appendChild(tempTextArea);
      const contentHeight = tempTextArea.scrollHeight;
      document.body.removeChild(tempTextArea);

      const newWidth = Math.max(minFieldWidthAllowed, textareaRef.current.scrollWidth);
      const newHeight = Math.max(minFieldHeightAllowed, contentHeight);
      // set constraints to limit resizing of text fields to their content
      setMinConstraints([newWidth, newHeight]);

      setSize({
        width: Math.max(newSize.width, newWidth),
        height: Math.max(newSize.height, newHeight),
      });
    } else {
      setSize(newSize);

      // TODO-BP finish cleanup of dynamic font size logic
      // if (completedFieldRef.current) {
      //   const newFontSize = calculateFontSize(completedFieldRef.current, newSize.width, newSize.height);
      //   setSignatureFontSize(newFontSize);
      // }
    }
  };

  const handleResizeStop = (e, { size: newSize }) => {
    //TODO move below into useLayoutEffect?
    let minWidth = minFieldWidthAllowed;
    let minHeight = minFieldHeightAllowed;

    if (completedFieldRef.current) {
      const contentWidth = completedFieldRef.current.scrollWidth;
      const contentHeight = completedFieldRef.current.scrollHeight;

      // Update minWidth and minHeight based on the completed field's dimensions
      minWidth = Math.max(minFieldWidthAllowed, contentWidth);
      minHeight = Math.max(minFieldHeightAllowed, contentHeight);

      // TODO-BP finish cleanup of dynamic font size logic
      // const newFontSize = calculateFontSize(completedFieldRef.current, newSize.width, newSize.height);
      // setSignatureFontSize(newFontSize);
    }

    setSize({
      width: Math.max(newSize.width, minWidth),
      height: Math.max(newSize.height, minHeight),
    });

    if (isTextField && signingObject.value) setMinConstraints([minWidth, minHeight]);

    const updatedObject = {
      ...signingObject,
      width: Math.max(newSize.width, minWidth),
      height: Math.max(newSize.height, minHeight),
    };
    updateSigningObject(updatedObject);
  };

  // TODO-BP clean up the dynamic font size logic since not currently being used
  useEffect(() => {
    if (completedFieldRef.current && isSignatureObject) {
      // when placing signature in completed field, make sure width is not cut off
      // enlarge the field if needed to fit the content but only up to 400px
      const contentWidth = completedFieldRef.current.scrollWidth;
      if (signingObject.width < contentWidth) {
        setSize({
          width: Math.min(Math.max(contentWidth + 4, signingObject.width), 400),
          height: signingObject.height,
        });
      }
    }
  }, [signingObject.value, signingObject.completed_at, signingObject.hasBeenSigned]);

  useEffect(() => {
    if (previousType.current && previousType.current !== signingObject.type) {
      setSize({
        width: defaultWidth,
        height: ResizableFieldDimensions.DEFAULT_HEIGHT,
      });
      updateSigningObject({
        ...signingObject,
        width: defaultWidth,
        height: ResizableFieldDimensions.DEFAULT_HEIGHT,
      });
    }
    // Update ref for next comparison
    previousType.current = signingObject.type;
  }, [signingObject.type]);

  const handleTextChange = (e) => {
    const inputValue = e.target.value;
    if (!isTextField || !forCurrentUser) return;
    if (!textareaRef.current) return;
    const wouldOverflow = textareaRef.current.scrollHeight > textareaRef.current.clientHeight;

    if (!wouldOverflow) setTextFieldValue(inputValue);
  };

  const handleTextBlur = () => {
    if (!isTextField || !forCurrentUser) return;
    if (!textareaRef.current) return;

    const trimmedValue = textFieldValue.trim();
    setTextFieldValue(trimmedValue);

    const updatedObject = {
      ...signingObject,
      hasBeenSigned: trimmedValue.length > 0,
      value: trimmedValue,
      height: signingObject.height,
      width: signingObject.width,
      x: signingObject.x,
      y: signingObject.y,
    };
    updateSigningObject(updatedObject);
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
    // Check toolbar dropdown state before it can be changed by onClick
    const isDropdownOpen = dropdownRef.current?.isOpen;
    if (isDropdownOpen) {
      setFieldIsActive(true);
    } else {
      setFieldIsActive(false);
    }
  };

  useEffect(() => {
    // check if the field is on the current page and resize the field if
    // it is a text field and goes off the right edge of the page
    const documentId = documentRef.current?.id;
    const currentPageIndex = parseInt(documentId.split("pageView")[1]);
    const fieldOnCurrentPage = currentPageIndex === signingObject?.page;
    if (!isTextField || !fieldOnCurrentPage || !documentRef.current) return;

    const pageRect = documentRef.current.getBoundingClientRect();
    const fieldRect = fieldRef.current.getBoundingClientRect();
    let newWidth = size.width;
    const relativeFieldEdge = fieldRect.right - pageRect.left;

    if (relativeFieldEdge > pageRect.width) {
      const difference = relativeFieldEdge - pageRect.width;
      newWidth -= difference;
      // resize field to keep on page but not below minFieldWidthAllowed
      newWidth = Math.max(newWidth, minFieldWidthAllowed);
      setSize({ width: newWidth, height: size.height });
      const updatedObject = {
        ...signingObject,
        width: newWidth,
        height: size.height,
      };
      updateSigningObject(updatedObject);
    }
  }, [signingObject]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    !isDateObject && setActiveSignerFieldId(signingObjectId);

    // open signature entry modal if field is active and toolbar open
    if ((fieldIsActive || activeSignerFieldId) && forCurrentUser && !isDateObject && !signingObject?.signed) {
      openSignatureEntryModal();
    } else {
      handleShowToolbar(e);
    }
  };

  const handleClickShowToolbar = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    setActiveSignerFieldId(signingObjectId);
    handleShowToolbar(e);
  };

  const showActiveSignerFieldExtras =
    activeSignerFieldId === signingObjectId && !hasBeenSigned && !signingLocationsAreDraggable;

  useEffect(() => {
    // Focus the div if showActiveSignerFieldExtras is true so the onKeyDown opens the signature entry modal
    if (showActiveSignerFieldExtras && focusRef.current) {
      focusRef.current.focus();
    }
  }, [showActiveSignerFieldExtras]);

  return (
    <div
      {...scope}
      ref={fieldRef}
      onClick={() => setActiveSignerFieldId(signingObjectId)}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
      }}
    >
      {((showFieldHeader && signingLocationsAreDraggable) || showActiveSignerFieldExtras) && (
        <div
          className={a("custom-resizable-box-header").t(
            "header-background-teamMember",
            "header-background-client",
            isTeamMember
          )}
          style={{ maxWidth: `${size.width}px` }}
        >
          <CpTooltip text={signerFieldProps?.text}>
            <div className="header-content">
              {signerFieldProps?.icon && <CpIcon name={signerFieldProps.icon} />}
              <span className="header-text">{signerFieldProps?.text}</span>
            </div>
          </CpTooltip>
        </div>
      )}
      {
        <ResizableBox
          data-field-container
          width={size.width}
          height={size.height}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          minConstraints={minConstraints}
          maxConstraints={maxConstraints}
          resizeHandles={signerFieldProps?.allowResize ? ["ne"] : []}
          handle={
            signerFieldProps?.allowResize ? <ResizeHandle signingObject={signingObject} ref={resizeHandleRef} /> : null
          }
        >
          <div // border around the text field
            className={a("custom-resizable-box-border").m("custom-resizable-box-border-teamMember", isTeamMember)}
            style={{ height: `${size.height}px` }}
          >
            <div // transparent background
              className={a("custom-resizable-box-background").m(
                "custom-resizable-box-background-teamMember",
                isTeamMember
              )}
              onClick={handleClick}
            />
            {/* TODO move text field code to its own component, maybe CpSwitch?  */}
            {isTextField ? (
              <div>
                {/* text fields should be editable until doc has been sent and signed is true */}
                {signingObject.signed ? (
                  <CompletedField
                    {...signerFieldProps}
                    context={context}
                    forCurrentUser={forCurrentUser}
                    signingObject={signingObject}
                    completedFieldRef={completedFieldRef}
                    signatureFontSize={signatureFontSize}
                  />
                ) : (
                  <div>
                    {forCurrentUser && (
                      <>
                        <div
                          className={a("custom-resizable-box-placeholder-content cp-caption cp-ellipsis").m(
                            "custom-resizable-box-placeholder-content-teamMember",
                            isTeamMember
                          )}
                          style={{
                            position: "absolute",
                            pointerEvents: "none",
                            opacity: textFieldValue ? 0 : 1,
                            transition: "opacity 0.2s",
                          }}
                        >
                          {((forCurrentUser && context.loggedInUser.role === "TeamMember") ||
                            (showActiveSignerFieldExtras && !signingLocationsAreDraggable)) &&
                            "Click to add text"}
                        </div>
                        <textarea
                          ref={textareaRef}
                          value={textFieldValue}
                          onChange={handleTextChange}
                          onBlur={handleTextBlur}
                          rows={Math.max(1, Math.floor(size.height / 16))}
                          className={a("custom-textarea cp-caption").t(
                            "custom-textarea-active",
                            "custom-textarea-inactive",
                            forCurrentUser
                          )}
                          style={{ height: `${size.height}px` }}
                          onClick={handleClickShowToolbar}
                        />
                      </>
                    )}
                  </div>
                )}
                {!forCurrentUser && <div className="textarea-overlay" onClick={handleClickShowToolbar} />}
              </div>
            ) : (
              <div
                ref={focusRef}
                className={a("custom-resizable-box-placeholder-content cp-caption cp-ellipsis").m(
                  "custom-resizable-box-placeholder-content-teamMember",
                  isTeamMember
                )}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    forCurrentUser && !isDateObject && !hasBeenSigned && openSignatureEntryModal();
                  }
                }}
              >
                {forCurrentUser &&
                  (context.loggedInUser.role === "TeamMember" || showActiveSignerFieldExtras) &&
                  !isDateObject &&
                  !isTextField &&
                  !hasBeenSigned &&
                  `Click or enter to add ${isSignatureObject ? "signature" : "initials"}`}
                {(hasBeenSigned || !!signerFieldProps?.signedLocation) && (
                  <CompletedField
                    {...signerFieldProps}
                    context={context}
                    forCurrentUser={forCurrentUser}
                    signingObject={signingObject}
                    openSignatureEntryModal={openSignatureEntryModal}
                    signatureFontSize={signatureFontSize}
                    completedFieldRef={completedFieldRef}
                  />
                )}
              </div>
            )}
          </div>
        </ResizableBox>
      }
    </div>
  );
};

DraggableResizableField.displayName = "DraggableResizableField";

const css = k`
  .custom-resizable-box-header {
    display: flex;
    position: absolute;
    align-items: center;
    top: -22px;
    left: 0;
    width: fit-content;
    height: 22px;
    color: var(--cp-color-app-primary-dark-text);
    padding: 2px 4px;
    gap: 4px;
    border-radius: 8px 8px 0 0;
    z-index: 10;
    cursor: pointer;
  }

  .header-content {
    display: flex;
    align-items: center;
    min-width: 0;  
    flex: 1;
  }

  .header-text {
    font-size: 10px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin-left: 4px;
  }

  .header-background-teamMember {
    background-color: var(--cp-color-pill-team-text);
  }

  .header-background-client {
    background-color: var(--cp-color-default-badge-bg);
  }

  .custom-resizable-box-border {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cp-color-default-badge-bg);
  }

  .custom-resizable-box-border-teamMember {
    border: 1px solid var(--cp-color-pill-team-text);
  }

  .custom-resizable-box-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cp-color-pill-bg);
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
  }

  .custom-resizable-box-background-teamMember {
    background-color: var(--cp-color-pill-team-bg);
  }

  .custom-textarea {
    position: relative;
    display: flex;
    flex: 1;
    width: 100%; 
    min-height: 18px !important;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--cp-color-app-primary-text);
    outline: none;
    box-shadow: none !important;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 1;
    z-index: 4;
    pointer-events: auto;
    resize: none;
    white-space: pre-wrap;
  }

  .custom-textarea-active {
    cursor: move;
  }

  .custom-textarea-active:focus {
    cursor: text;
  }

  .custom-textarea-inactive {
    cursor: move !important;
    user-select: none !important;
    pointer-events: none !important;
  }

  .textarea-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: move;
  }

  .custom-resizable-box-placeholder-content {
    width: 100%;
    height: 100%;
    position: relative;
    color: var(--cp-color-default-badge-bg);
    z-index: 3;
    display: flex;
    flex-direction: column; 
    justify-content: flex-end;
  }

  .custom-resizable-box-placeholder-content-teamMember {
    color: var(--cp-color-pill-team-text) !important;
  }
`;
