import React from "react";
import { useFormContext } from "react-hook-form";
import { CpRadioField } from "canopy-styleguide!sofe";
import { TContact } from "src/common/types";
import { tw } from "src/tailwind";
import ContactTile from "./contact-tile.component";

type ContactSelectTilesProps = {
  contacts: TContact[];
};

export default function ContactSelectTiles({ contacts }: ContactSelectTilesProps) {
  const { control } = useFormContext();

  return (
    <CpRadioField name="mainContact" fieldName="mainContact" control={control}>
      <div className={tw("flex flex-wrap gap-[1.6rem]")}>
        {contacts.map((contact) => (
          <ContactTile key={contact.id} contact={contact} />
        ))}
      </div>
    </CpRadioField>
  );
}
