import { fetcher } from 'fetcher!sofe';

export const getOutstandingInvoiceTerms = clientId =>
  fetcher(`/api/dashboard/invoices/overdue_ranges${clientId ? `?client_id=${clientId}` : ''}`).then(data => {
    const pastDueValue =
      parseFloat((data.ranges ?? {})[30]) +
        parseFloat((data.ranges ?? {})[60]) +
        parseFloat((data.ranges ?? {})[90]) +
        parseFloat((data.ranges ?? {})['90_plus']) || 0;

    const outstandingValue = pastDueValue + parseFloat((data.ranges ?? {})['current']) || 0;
    return { ...data.ranges, outstandingValue, pastDueValue };
  });

export const getYTDRevenue = clientId =>
  fetcher(`/api/dashboard/invoices/ytd_revenue_by_month${clientId ? `?client_id=${clientId}` : ''}`).then(
    data => data.revenues
  );

export const getAvailableCredit = clientId =>
  fetcher(`/api/dashboard/invoices/outstanding${clientId ? `?client_id=${clientId}` : ''}`).then(
    data => +data.available_credit
  );
