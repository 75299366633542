import React, { useState } from "react";
import { CpModal, CpButton, CpCheckbox } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { handleError } from "src/error";
import { patchClients } from "src/client-list/client-list.resource";
import { clientQueries } from "src/queries";
import { pluralCount } from "src/common/string.helpers";
import NotificationMessage from "src/common/notification-message.component";
import { TNotificationMessage } from "src/client-portal-invite-modal/multi-client-portal-invite-modal/contact-invites";

export function BulkClientPortalInviteModal({
  onClose,
  filters,
  selection,
  search,
}: {
  onClose: () => void;
  filters: any;
  selection: any;
  search: string;
}) {
  const [show, setShow] = useState(true);
  const [includeSpouse, setIncludeSpouse] = useState(false);
  const [reinviteExisting, setReinviteExisting] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<TNotificationMessage | null>(null);

  function onInvite() {
    close();
    patchClients({
      search,
      filters,
      action: "client_portal_invite",
      [selection.type]: selection.toArray(),
      message: notificationMessage?.message,
      include_spouse: includeSpouse,
      reinvite_existing: reinviteExisting,
    }).subscribe(() => {
      successToast(`Bulk client portal invite successfully sent.
Undo`);
      selection.deselectAll();
      // Small delay before invalidating in case bulk job hasn't finished
      setTimeout(() => {
        clientQueries.invalidate();
      }, 1000);
    }, handleError);
  }

  function close() {
    setShow(false);
  }

  return (
    <CpModal show={show} onClose={close} onAfterClose={onClose} width={830}>
      <CpModal.Header title="Client Portal Access" />
      <CpModal.Body>
        <div className="flex flex-col cp-gap-16">
          <div>
            You are giving portal access to <b>{pluralCount(selection.totalSelected, "client", "clients")}</b> - the
            primary contacts from the selected client records will be sent an invite.
          </div>
          <div>
            <CpCheckbox checked={includeSpouse} onChange={setIncludeSpouse} label="Include spouse">
              Include applicable spouse contacts in invite
            </CpCheckbox>
            <CpCheckbox checked={reinviteExisting} onChange={setReinviteExisting} label="Resend invites">
              Include resending invites
            </CpCheckbox>
          </div>
          <i className="cp-caption-small cp-color-app-secondary-text">
            Please note that any selected contacts who are missing a primary email address will be excluded from this
            bulk action. Also, any contacts with the same email as an already invited contact will be excluded from this
            bulk action.
          </i>
          <NotificationMessage setupFunction={setNotificationMessage} />
        </div>
      </CpModal.Body>
      <CpModal.Footer className="flex cp-gap-8">
        <CpButton btnType="primary" onClick={onInvite} className="cp-mr-8">
          Send
        </CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
