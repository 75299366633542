import { generateClassNameHelpers } from "canopy-styleguide!sofe";

type ClassnameValue = string | undefined | null | false;

interface ClassNameHelpers {
  always: (...args: ClassnameValue[]) => string;
  maybe: (enabled: unknown, ...classname: ClassnameValue[]) => string;
  toggle: (
    enabled: unknown,
    classnameTrue: ClassnameValue | ClassnameValue[],
    classnameFalse: ClassnameValue | ClassnameValue[]
  ) => string;
  tw: (...args: ClassnameValue[]) => string;
}

const helpers: ClassNameHelpers = generateClassNameHelpers("clu");
export const { always, maybe, toggle, tw } = helpers;
