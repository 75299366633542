import { useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { pluralCount } from "src/common/string.helpers";
import { handleError } from "src/error";
import { contactQueries } from "src/queries";
import { patchContacts } from "src/resources/contacts.resource";
import { successToast } from "toast-service!sofe";
import { Selection } from "./contact-list.component";

export type SubView = {
  id: string;
  name: string;
  clientActionName: ({ selectionCount }: { selectionCount: number }) => string;
  onClientActionClick: ({
    selection,
    filters,
    search,
  }: {
    selection: Selection;
    filters: Record<string, any[]>;
    search: string;
  }) => void;
  clientActionPermissions?: string[];
  contextMenuActions?: string[];
  bulkActions?: string[];
  filter_data: {
    filters: Record<string, any[]>;
    sort_data: any[];
  };
};

const subViews: Record<string, SubView> = {
  archived: {
    id: "archived",
    name: "Archived Contacts",
    clientActionName: ({ selectionCount }) => `Unarchive ${pluralCount(selectionCount, "contact")}`,
    onClientActionClick: async ({ selection, filters, search }) => {
      const count = selection.totalSelected;
      try {
        await patchContacts({
          search,
          filters,
          action: "unarchive",
          [selection.type]: selection.toArray(),
        });
        selection.deselectAll();
        successToast(`${pluralCount(count, "contact has", "contacts have")} been unarchived.`);
        // Try to give the bulk action some time before invalidating
        setTimeout(() => contactQueries.invalidate(), 2000);
      } catch (e) {
        handleError(e);
      }
    },
    clientActionPermissions: ["contacts_archive"],
    contextMenuActions: ["unarchive", "delete"],
    bulkActions: [],
    filter_data: {
      filters: {
        is_archived: [{ equal_to: true }],
      },
      sort_data: [],
    },
  },
};

export function useContactListSubView({ routeId }: { routeId: string }) {
  const subView = useMemo<SubView | null>(() => subViews[routeId] || null, [routeId]);
  const allSubViews = useMemo(() => Object.values(subViews), []);
  const prevRouteId = useRef<string>();
  const history = useHistory();
  useEffect(() => {
    const allSubViewIds = allSubViews.map((subView) => subView.id);
    if (!subView && !allSubViewIds.includes(routeId)) {
      prevRouteId.current = routeId;
    }
  }, [routeId, subView, allSubViews]);
  return {
    subView,
    allSubViews,
    exitSubView: () => history.push(prevRouteId.current || "all"),
  };
}
