import React, { useReducer } from "react";
import { useCss, k } from "kremling";
import { cloneDeep } from "lodash";
import { captureException } from "error-logging!sofe";
import {
  CpButton,
  CpWell,
  CpIcon,
  CpTextarea,
  useIsMounted,
} from "canopy-styleguide!sofe";
import { warningToast } from "toast-service!sofe";
import { handleError } from "src/handle-error";
import {
  patchAttachmentsForComment,
  postAttachment,
  saveMessage,
} from "./comment.resource.js";
import AddFileButton from "./add-file-button.component.js";

const defaultState = {
  body: "",
  files: [],
  showButtons: false,
  loading: false,
  loadingFiles: false,
};

const commentFormReducer = (state = defaultState, action) => {
  let newState = cloneDeep(state);
  switch (action.type) {
    case "ADD_COMMENT":
      newState.loading = true;
      break;
    case "ADD_FILE":
      newState.files = state.files.concat(action.payload);
      newState.showButtons = true;
      newState.loadingFiles = false;
      break;
    case "REMOVE_FILE":
      newState.files.splice(action.payload, 1);
      if (newState.files.length === 0 && !newState.body) {
        newState.showButtons = false;
      }
      break;
    case "RESET_COMMENT":
      newState = defaultState;
      break;
    case "SET_ERROR":
      newState.loading = false;
      break;
    case "SET_MESSAGE":
      newState.body = `${action.payload}`;
      if (newState.files.length === 0 && !newState.body.trim()) {
        newState.showButtons = false;
      } else {
        newState.showButtons = true;
      }
      break;
    case "LOADING FILES":
      newState.loadingFiles = action.payload;
      break;
    default:
      return state;
  }
  return newState;
};

export default function CommentInput(props) {
  const [commentForm, dispatch] = useReducer(commentFormReducer, defaultState);
  const { showButtons } = commentForm;
  const isMounted = useIsMounted();

  const sendMessage = async () => {
    if (commentForm.loading) return;
    setLoadingFiles(true);
    try {
      const docsUI = await SystemJS.import("docs-ui!sofe");
      const files = commentForm.files.filter((file) => !!file.size);
      let filesToUpload = [];
      if (files.length > 0) {
        filesToUpload = await docsUI.uploadFiles({
          files,
          folderId: `CON${props.clientId}`,
          inbox: false,
        });
        if (!isMounted.current) return;
        if (props.resolutionCaseId) {
          let crId = props.clientRequestID.substring(1);
          let postedFiles = [];
          await Promise.all(
            filesToUpload.map(async (file) => {
              const commentFile = await postAttachment(
                props.clientId,
                crId,
                file
              ).toPromise(); //change to lastValueFrom on upgrade to rxjs v7
              file.file_id = commentFile.id[0];
              postedFiles.push(file);
            })
          );
        }
      }

      dispatch({ type: "ADD_COMMENT" });
      const fileIDs = filesToUpload.map((f) => f.id);
      saveMessage({
        clientRequestID: props.clientRequestID,
        clientId: props.clientId,
        message: commentForm.body,
        files: fileIDs,
      }).subscribe(
        (res) => {
          if (props.resolutionCaseId) {
            let fileObj = {
              ancestor_id: res.comment.id,
              ancestor_type: "comment",
              pivot_id: +props.clientRequestID.substring(1),
              pivot_type: "client_requests",
            };
            filesToUpload.map((file) => {
              patchAttachmentsForComment(
                props.clientId,
                file.file_id,
                fileObj
              ).subscribe(() => {});
            });
            props.setFilesByComment(res.comment.id, filesToUpload);
          }
          dispatch({ type: "RESET_COMMENT" });
          props.setComments((comments) => [
            ...(comments || []),
            {
              body: commentForm.body,
              created_by_user_id: props.user.id,
              id: res.comment.id,
              fileIDs: fileIDs,
            },
          ]);

          if (filesToUpload.length) {
            props.cueFileAdded(res.comment.id);
          }
        },
        (err) => {
          dispatch({ type: "SET_ERROR" });
          err.toastMessage =
            "We were unable to save your comment, please try again";
          handleError(err);
        }
      );
    } catch (e) {
      captureException(e);
      setLoadingFiles(false);
      warningToast({
        message: "File failed to upload.",
      });
    }
  };

  const addFiles = (files) => {
    dispatch({
      type: "ADD_FILE",
      payload: files,
    });
  };

  const removeFile = (index) => {
    dispatch({
      type: "REMOVE_FILE",
      payload: index,
    });
  };

  const setLoadingFiles = (bool) => {
    dispatch({
      type: "LOADING FILES",
      payload: bool,
    });
  };

  const handleChange = (value) => {
    dispatch({ type: "SET_MESSAGE", payload: value });
  };

  const filesListBottomBadding =
    2.4 + (commentForm.files.length ? 5.4 * (commentForm.files.length + 1) : 0);

  return (
    <div
      className={`comment-input-container ${
        props.hasComments ? "cps-margin-top-0" : ""
      }`}
      {...useCss(styles)}
    >
      <div className="comment-control-container">
        <div className="input-and-buttons">
          <CpTextarea
            className="comment-input"
            style={{ paddingBottom: `${filesListBottomBadding}rem` }}
            placeholder="Add a comment..."
            rows={1}
            onChange={handleChange}
            value={commentForm.body}
            disabled={commentForm.loading}
          />
          <div
            className="comment-buttons"
            style={{ paddingBottom: `${filesListBottomBadding}rem` }}
          >
            <AddFileButton
              clientId={props.clientId}
              clientRequestID={props.clientRequestID}
              handleFiles={addFiles}
              resolutionCaseId={props.resolutionCaseId}
            />
            <CpButton
              btnType="flat"
              disabled={!showButtons}
              onClick={sendMessage}
              showLoader={commentForm.loading || commentForm.loadingFiles}
            >
              Send
            </CpButton>
          </div>
        </div>
        {commentForm.files.length > 0 && (
          <div className="ready-to-upload">
            {commentForm.files.map((f, i) => (
              <CpWell
                level={2}
                className="ready-to-upload-file"
                key={`${f.name}${f.lastModified}`}
              >
                <CpIcon name="file-document" className="cp-mr-8" />
                <div className="file-name cp-ellipsis">{f.name}</div>
                <CpButton
                  small
                  icon="close-small"
                  aria-label="Remove file attachment"
                  onClick={() => removeFile(i)}
                />
              </CpWell>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

const styles = k`
  .File-remove {
    margin-left: auto;
  }

  textarea {
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    padding: 8px 12px 4px;
    margin: 1px; /* textarea is sometimes overstepping its bounds and displaying on top of parent, see RED-2198 */
  }

  .comment-input-container {
    @media #{$--phone-only} {
      position: sticky;
      bottom: 89px;
    }
  }

`;
