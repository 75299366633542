import React, { lazy, Suspense } from "react";
import { Router } from "@reach/router";
import { CpLoader } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { SurveyWrapper } from "./survey/survey-wrapper.component.js";
import { QuestionnaireWrapper } from "./questionnaire/questionnaire-wrapper.component.js";

const TodoRoot = lazy(() =>
  import(/* webpackChunkName: 'TodoRoot'*/ "./todo.component.js")
);
const ClientRequest = lazy(() =>
  import(
    /* webpackChunkName: 'clientRequest' */ "./client-request/client-request.component.js"
  ).then((m) => ({
    default: m.ClientRequest,
  }))
);
const Survey = lazy(() =>
  import(/* webpackChunkName: 'survey' */ "./survey/survey.component.js")
);
const Esign = lazy(() =>
  import(/* webpackChunkName: 'survey' */ "./esign-request/esign.component")
);
const Engagement = lazy(() =>
  import(
    /* webpackChunkName: 'survey' */ "./engagement/engagement.component.js"
  )
);
const Questionnaire = lazy(() =>
  import(
    /* webpackChunkName: 'questionnaire' */ "./questionnaire/questionnaire.component.js"
  )
);

export default function TodoRouter(props) {
  const { selectedClient } = props;

  return (
    <Suspense fallback={<CpLoader size="lg" />}>
      <Router>
        <TodoRoot selectedClient={selectedClient} path="/" />
        <ClientRequest clientId={selectedClient.id} path="request/:requestId" />
        <ClientRequest
          clientId={selectedClient.id}
          path="request/:requestId/resolution-case/:resolutionCaseId"
        />
        <SurveyWrapper
          exact
          clientId={selectedClient.id}
          path="survey/:surveyId/resolution-case/:resolutionCaseId/welcome"
        />
        <Survey
          clientId={selectedClient.id}
          path="survey/:surveyId/resolution-case/:resolutionCaseId/*"
        />
        <Esign clientId={selectedClient.id} path="esign/:esignId" />
        <Esign
          clientId={selectedClient.id}
          path="esign/:esignId/resolution-case/:resolutionCaseId"
        />
        <Engagement
          clientId={selectedClient.id}
          path="engagement/:engagementId"
        />
        {featureEnabled("toggle_custom_forms") && (
          <>
            <QuestionnaireWrapper
              exact
              clientId={selectedClient.id}
              path="client-request/:clientRequestId/questionnaire/:questionnaireId/welcome"
            />
            <Questionnaire
              clientId={selectedClient.id}
              path="client-request/:clientRequestId/questionnaire/:questionnaireId"
            />
          </>
        )}
      </Router>
    </Suspense>
  );
}
