import React, { Suspense, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useCss, k, a, m, t } from "kremling";
import moment from "moment";
import { unescape } from "lodash";

import { CpIcon, CpLoader } from "canopy-styleguide!sofe";
import { LinkedFileListLazy as LinkedFileList } from "docs-ui!sofe";

import { useMediaQuery } from "@hooks/use-media-query.hook";

CommentScroll.propTypes = {
  filesByComment: PropTypes.object,
  user: PropTypes.object,
  comments: PropTypes.array,
  message: PropTypes.string,
  firstSentBy: PropTypes.string,
  firstSentAt: PropTypes.string,
};

export function CommentScroll(props) {
  const { user, message, firstSentBy, firstSentAt } = props;
  const phoneOnly = useMediaQuery("--phone-only");
  const scrollableDiv = useRef();
  const sentByName =
    firstSentBy?.length > 25 ? firstSentBy.slice(0, 25) + "..." : firstSentBy;

  useEffect(() => {
    if (props.comments.length > 0) {
      scrollableDiv.current.scrollTop = scrollableDiv.current.scrollHeight;
    }
  });

  const getFileWidgets = (comment) => {
    if (comment.fileIDs && !props.filesByComment?.[comment.id]) {
      // this comment was just added
      return comment.fileIDs.map((f, i) => (
        <div className="File" key={f}>
          <CpIcon name="file-document" className="cp-mr-8" />
          Loading file...
        </div>
      ));
    }

    const files = comment.files
      ? comment.files
      : props.filesByComment
      ? props.filesByComment[comment.id]
      : [];

    return (
      <Suspense fallback={<CpLoader />}>
        <LinkedFileList
          cardWidth={"100%"}
          cardClass={"comment-file-card"}
          files={files || []}
          displayTwoPerLine={true}
        />
      </Suspense>
    );
  };

  return (
    <div
      {...useCss(styles)}
      className={a("all-comments-container")}
      ref={scrollableDiv}
    >
      <div className="message">
        <div
          className="message-content"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        {props.messageFiles && (
          <div className="description-files">
            <Suspense fallback={<CpLoader />}>
              <LinkedFileList files={props.messageFiles} />
            </Suspense>
          </div>
        )}
        <div className="message-date">
          Sent{firstSentBy ? ` by ${sentByName} ` : " "}on{" "}
          {DateTime.fromISO(firstSentAt).toLocaleString(DateTime.DATE_MED)}
        </div>
        {props.signatures && (
          <div className="signature-status">{props.signatures}</div>
        )}
      </div>
      {!user ? (
        <CpLoader />
      ) : (
        props.comments.map((comment) => {
          const isCurrentUser = comment.created_by_user_id === user.id;
          return (
            <div
              key={comment.id}
              className={a("comment-wrapper").t(
                "current-user-comment",
                "other-user-comment",
                isCurrentUser
              )}
            >
              <div
                className={a("comment-name-and-date").t(
                  "current-user-comment",
                  "other-user-comment",
                  isCurrentUser
                )}
              >
                <span
                  className={a("cps-wt-bold word-break").m(
                    "other-commenter-color",
                    !isCurrentUser
                  )}
                >
                  {!isCurrentUser &&
                    (comment.created_by_user_name || user.name)}
                </span>
                <span className={a("nowrap").m("dot-before", !isCurrentUser)}>
                  {moment(comment.created_at).format(
                    "MMM D[,] YYYY [at] h:mm a"
                  )}
                </span>
              </div>
              <div
                className={`cps-card message-and-files ${t(
                  "current-user-comment",
                  "other-user-comment other-user-comment-coloring",
                  isCurrentUser
                )}`}
              >
                <div className="word-break">{unescape(comment.body)}</div>
                <div className="Files comment-files">
                  {getFileWidgets(comment)}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

const styles = k`
  .all-comments-container {
    overflow: auto;
    display: flex;
    flex-direction: column; /* start scroll at bottom */
    max-height: 65rem;
    padding: 0rem 2.4rem;

    @media #{$--phone-only} {
      flex: 2;
    }
  }

  .comment-wrapper:first-child {
    margin-top: 8px;
  }

  .comment-wrapper:last-child {
    margin-bottom: 8px;
  }

  .comment-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }

  .message-and-files {
    padding: 14px 16px 6px 16px;
    margin: 4px 0px;
    border-radius: 5px;
    width: 100%;
  }

  .description-files {
    padding-top: 8px;
  }

  .comment-name-and-date {
    font-size: 11px;
    display: flex;
  }

  .current-user-comment {
    align-self: flex-end;
  }

  .other-user-comment {
    align-self: flex-start;
  }

  .other-user-comment-coloring {
    border: 1px solid var(--cp-color-app-primary);
    background-color: rgba(var(--cp-color-app-primary-rgb), 0.1);
  }

  .comment-files {
    margin-top: 6px;
  }

  .comment-files div {
    width: 100%;
  }

  .dot-before::before {
    content: "•";
    margin: 0px 8px;
    font-size: 11px;
  }

  .other-commenter-color {
    color: var(--cps-color-primary);
  }

  .nowrap {
    white-space: nowrap;
  }

  .comment-file-card {
    height: 32px;
    margin-right: 0px;
  }

  .word-break {
    word-break: break-word;
    white-space: pre-line;
  }

  .message-content p {
    margin: 0;
  }

  .message-content {
    padding-top: 10px;
  }

  .message-date {
    color: var(--cp-color-app-secondary-text);
    margin-bottom: 1.6rem;
  }
`;
