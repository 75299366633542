import React, { useEffect, useMemo, useRef, useState } from "react";
import { CpCard, CpCheckbox, CpLoader, CpInput } from "@components";
import { Controller, useForm } from "react-hook-form";
import { a } from "kremling";
import { SortController } from "../common/sort/sort-controller.component";
import { FilterFooter } from "../common/filter-footer.component";
import styles from "./select-filter.styles.css";
import { filterAlpha } from "../../../cp-select/common/utils";
import { useDebounce } from "@hooks";

export function SelectFilter({
  columnSchema,
  onClose,
  filterControl,
  getSelectionOptions,
  searchPlaceholder,
  isBool,
  refetchOnSearch,
}) {
  const [options, setOptions] = useState(null);
  const [initialOptionsCount, setInitialOptionsCount] = useState(0);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const callSetDebouncedSearch = useDebounce(
    (value) => setDebouncedSearch(value),
    300,
  );
  useEffect(
    () => refetchOnSearch && callSetDebouncedSearch(search),
    [refetchOnSearch, search, callSetDebouncedSearch],
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      sort: filterControl.sortData[columnSchema.id]?.direction,
      selection:
        filterControl.filters[columnSchema.id]?.data ||
        (isBool ? ["true", "false"] : []),
    },
  });

  function onSubmit(data) {
    let filterData = data.selection;
    if (isBool && filterData.length >= 2) {
      filterData = []; // Clear the filter if both true and false are selected
    }
    filterControl.applyFilter(columnSchema.id, {
      filterData,
      sortDir: data.sort,
    });
    onClose();
  }

  function onClear() {
    reset({
      sort: null,
      selection: isBool ? ["true", "false"] : [],
    });
  }

  const filterControlRef = useRef();
  filterControlRef.current = filterControl;
  useEffect(() => {
    const getValues = async () => {
      const result = await getSelectionOptions({
        filterControl: filterControlRef.current,
        search: debouncedSearch,
      });
      if (!debouncedSearch) {
        setInitialOptionsCount(result.length);
      }
      return result;
    };
    getValues().then((result) => {
      setOptions(result);
    });
  }, [getSelectionOptions, refetchOnSearch, debouncedSearch]);

  const visibleOptions = useMemo(() => {
    if (!options) return null;
    return refetchOnSearch ? options : options.filter(filterAlpha(search));
  }, [options, search, refetchOnSearch]);

  return (
    <CpCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CpCard.Body>
          {columnSchema.sort !== false && (
            <>
              <SortController
                control={control}
                dataType={columnSchema.dataType}
                ascLabel={columnSchema.sort.ascLabel}
                descLabel={columnSchema.sort.descLabel}
              />
              <div className="cp-divider" />
            </>
          )}
          {initialOptionsCount > 7 && (
            <>
              <CpInput
                placeholder={searchPlaceholder || "Search items"}
                value={search}
                onChange={setSearch}
              />
              <div className="cp-divider" />
            </>
          )}
          {visibleOptions ? (
            <div className={styles.selectionContainer}>
              <Controller
                control={control}
                name="selection"
                render={({ field: { value, onChange: onFormChange } }) =>
                  visibleOptions.map((opt) => (
                    <div key={opt.id} className={styles.selectionItem}>
                      <label
                        htmlFor={"option_" + opt.id}
                        className={a("cp-ellipsis", styles.selectionItemLabel)}
                        title={opt.name}
                      >
                        {opt.name}
                      </label>
                      <CpCheckbox
                        id={"option_" + opt.id}
                        checked={value.includes(opt.id)}
                        onChange={(checked) => {
                          onFormChange(
                            checked
                              ? [...value, opt.id]
                              : value.filter((id) => id !== opt.id),
                          );
                        }}
                      />
                    </div>
                  ))
                }
              />
            </div>
          ) : (
            <CpLoader />
          )}
        </CpCard.Body>
        <FilterFooter onCancel={onClose} onClear={onClear} />
      </form>
    </CpCard>
  );
}
