import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useCss } from "kremling";
import { handleError } from "src/handle-error";

import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { CommentScroll } from "./comment-scroll.component.js";
import CommentInput from "./comment-input.component.js";
import styles from "./comment-section.krem.css";
import { getRelatedFiles } from "./comment.resource.js";
import { cloneDeep } from "lodash";

CommentSection.propTypes = {
  clientRequest: PropTypes.object,
  refetchTask: PropTypes.func,
  message: PropTypes.string,
  clientId: PropTypes.number,
  firstSentBy: PropTypes.string,
  firstSentAt: PropTypes.string,
};

export function CommentSection(props) {
  let crComments = props.clientRequest?.relationships?.comments;
  const style = useCss(styles);
  const [comments, setComments] = useState(
    typeof crComments === "string" ? [] : crComments
  );
  const [filesByComment, setFilesByComment] = useState({});
  const [fileAdded, setFileAdded] = useState();
  const [user] = useWithUserAndTenant();

  useEffect(() => {
    if (props.filesByComment) {
      setFilesByComment(props.filesByComment);
    }
  }, [props.filesByComment]);

  useEffect(() => {
    if (fileAdded) {
      const obs = getRelatedFiles("comment", [fileAdded]).subscribe((res) => {
        setFileAdded();
        let files = cloneDeep(filesByComment);
        files[fileAdded] = res.comment[fileAdded];
        setFilesByComment(files);
      }, handleError);
      return () => obs.unsubscribe();
    }
  }, [fileAdded]);

  const cueFileAdded = (commentId) => {
    if (!props.clientRequest.resolution_case_id) {
      setFileAdded(commentId);
    }
    props.refetchTask();
  };

  const preventCollapse = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`CommentSection ${
        props.signatureBanner ? "commentsWithBanner" : ""
      }`}
      onClick={preventCollapse}
      {...style}
    >
      <CommentScroll
        message={props.message}
        messageFiles={props.clientRequest?.relationships?.files}
        firstSentBy={props.firstSentBy}
        firstSentAt={props.firstSentAt}
        comments={comments || []}
        user={user}
        filesByComment={filesByComment}
        signatures={props.signatures}
      />
      {user && !["COMPLETE", "DONE"].includes(props.clientRequest.status) && (
        <CommentInput
          clientRequestID={props.clientRequest.id}
          setComments={setComments}
          clientId={props.clientId}
          user={user}
          cueFileAdded={cueFileAdded}
          hasComments={!!comments?.length}
          resolutionCaseId={props.clientRequest.resolution_case_id}
          setFilesByComment={(id, files) =>
            setFilesByComment({ ...filesByComment, [id]: files })
          }
        />
      )}
    </div>
  );
}
