import React, { useContext, useEffect, useRef } from "react";
import { useCss, a, k } from "kremling";
import { CpIcon, CpSelectSingle, CpTooltip } from "canopy-styleguide!sofe";
import { SigningContext } from "../../signing-context";
import { getContactType } from "../../signing-modal.helper";

export const SignerName = ({
  isDragging,
  setSigner,
  signerFieldProps,
  signingLocationsAreDraggable,
  signingObject,
}) => {
  const scope = useCss(css);
  const { isNewCrm, scrollTo, setScrollTo, getSelectedSigners, signerTypesContext, setSelectedSigner } =
    useContext(SigningContext);
  const selectedSigners = getSelectedSigners();
  const scrollRef = useRef();

  const dragStyles = {
    opacity: isDragging ? 0 : 1,
    position: "relative",
  };

  useEffect(() => {
    if (scrollTo?.id === signingObject?.id) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollTo(null);
    }
  }, [scrollTo]);

  const openOnDrop = (toggle, e) => {
    // if the field has been filled, don't open the dropdown to change the signer
    if (signingObject.value) return;
    toggle(e);
  };

  const assignField = (user) => {
    // update the signer for selected field
    setSigner(user, signingObject);
    // update the shared selected signer for fields tab
    setSelectedSigner(user);
  };

  const signerAlreadyAssigned = (signer) =>
    signerTypesContext?.find((type) => type?.assignedTo?.id === signer?.id && signingObject.signer_type_id !== type.id);

  const signerTypeName = (signer) => {
    const type = signerTypesContext?.find((type) => type?.assignedTo?.id === signer?.id);
    return type?.name;
  };

  return (
    <div {...scope} style={dragStyles} ref={scrollRef}>
      <div style={{ position: "relative" }} className="cp-flex-center">
        <CpSelectSingle
          data={selectedSigners}
          onChange={assignField}
          contentWidth="md"
          transformData={(signer) => ({
            ...signer,
            name: `${signer?.isSelf ? "(You) " : ""}${signer.name} ${
              signerTypeName(signer) ? `(${signerTypeName(signer)})` : ""
            }`,
            ...(isNewCrm
              ? {
                  overline: getContactType(signer),
                }
              : {}),
            icon: "shape-circle-closed",
            iconColor:
              signer?.user_role === "TeamMember" || signer?.role === "TeamMember"
                ? "var(--cp-color-pill-team-text)"
                : "var(--cp-color-default-badge-bg)",
            isTeamMember: signer?.user_role === "TeamMember" || signer?.role === "TeamMember",
            disabled: !!signerAlreadyAssigned(signer),
          })}
          disabled={!signingLocationsAreDraggable}
          renderTrigger={({ open, toggle, ref }) => (
            <CpTooltip text="Change signer">
              <div className="signer-name" ref={ref} onClick={(e) => openOnDrop(toggle, e)}>
                <CpIcon
                  name="shape-circle-closed"
                  className="cp-mr-4"
                  fill={
                    signerFieldProps.teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"
                  }
                />
                <div className="cp-ellipsis">{signerFieldProps.text}</div>
              </div>
            </CpTooltip>
          )}
        />
      </div>
    </div>
  );
};

const css = k`

  .signer-name {
    display: flex;
    align-items: center;
    color: var(--cp-color-primary-nav-button-icon);
    margin-right: 8px;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    font-weight: 600;
    padding-left: 8px;
    height: 40px;
    max-width: var(--max-name-width-toolbar);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
