import { useRef, useState, useEffect } from "react";
import { orderBy, uniqBy } from "lodash";
import { canopyPaymentsQueries } from "billing-ui/externals!sofe";
import { genQueryKey, useQuery } from "src/react-query";
import {
  getBalance,
  getClientUsers,
  getCredits,
  getInvoices,
  getPayments,
  getRefunds,
  getScheduledPayment,
  getUpcomingPayments,
} from "./billing.resource";

const allFilter = {
  due_date: { order: "asc" },
  draft: {
    filter_params: false,
  },
};

const outstandingFilter = {
  due_date: { order: "asc" },
  status: {
    filter_params: [
      "Current",
      "1-30 days past due",
      "31-60 days past due",
      "61-90 days past due",
      "91+ days past due",
    ],
  },
  draft: {
    filter_params: false,
  },
};

export const useCanopyPaymentsQuery = (override) => {
  const { data } = useQuery(canopyPaymentsQueries.paymentsStatus({ override }));
  return {
    amexEnabled:
      !data?.amex_opt_out && data?.amex_submitted_status === "APPROVED",
    hasAdyen: data?.has_adyen,
    hasCanopyPayments: data?.is_active || override,
    hasPaysafe: data?.has_paysafe,
    surchargeEnabled: data?.surcharge,
    surchargeFeeText: data?.surcharge_fee,
    surchargeViewed: data?.surcharge_viewed,
  };
};

export function useClientUsers(clientId) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("usersAndAdmins", { clientId: Number(clientId) }),
    queryFn: () => getClientUsers(clientId),
    staleTime: 1000 * 60 * 30,
    enabled: !!clientId,
  });

  return {
    results: data || [],
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}

export const useBalance = (
  clientId,
  tenantBillingEnabled,
  hasBillingPermissions
) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("outstandingBalance", { clientId: Number(clientId) }),
    queryFn: () => getBalance(clientId),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId && !!tenantBillingEnabled && !!hasBillingPermissions,
  });
  return {
    balance: data?.unpaid_balance?.total || 0,
    credit: data?.available_credit || 0,
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
};

export const useInvoices = (clientId, includePaid = false) => {
  const [invoices, setInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("invoices", {
      clientId: Number(clientId),
      includePaid,
    }),
    queryFn: () =>
      getInvoices(clientId, includePaid ? allFilter : outstandingFilter),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId,
  });

  useEffect(() => {
    if (data) {
      setInvoices(data.invoices);
      setPaidInvoices(data.paidInvoices);
    }
  }, [data]);

  return {
    results: { invoices, paidInvoices },
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
};

export function usePaymentHistory(clientId) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("paymentHistory", { clientId: Number(clientId) }),
    queryFn: () =>
      Promise.all([getPayments(clientId), getRefunds(clientId)]).then(
        ([payments, refunds]) =>
          orderBy(uniqBy([...payments, ...refunds], "id"), "date", "desc")
      ),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId,
  });

  return {
    results: data || [],
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}

export function useUpcomingPayments(clientId) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("upcomingPayments", { clientId: Number(clientId) }),
    queryFn: () => getUpcomingPayments(clientId),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId,
  });

  return {
    results: data || null,
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}

export function useScheduledPayment(paymentId) {
  const defaultPaymentRef = useRef({});

  const { data } = useQuery({
    queryKey: genQueryKey("scheduledPayment", { paymentId }),
    queryFn: () => getScheduledPayment(paymentId),
    staleTime: 1000 * 60 * 3,
    enabled: !!paymentId,
  });

  return { results: data || defaultPaymentRef.current };
}

export function useCredits(clientId) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("credits", { clientId: Number(clientId) }),
    queryFn: () => getCredits(clientId),
    staleTime: 1000 * 60 * 3,
    enabled: !!clientId,
  });
  return {
    results: data || null,
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}
