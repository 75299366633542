import React, { useState } from "react";
import { CpModal, CpButton, CpSelectSingle, CpCheckbox } from "canopy-styleguide!sofe";
import { infoToast, successToast } from "toast-service!sofe";
import { exportFilterView } from "./export-contact-list.resource";
import { downloadText } from "src/common/utils.helpers";
import { getExportColumns } from "./export-contact-list.helper";
import { FilterViewType, Selection } from "../contact-list.component";
import { handleError } from "src/error";

export type ExportContactListModalProps = {
  show: boolean;
  onClose: () => void;
  filterViews: FilterViewType[];
  filterViewOverrides: Record<string, any>;
  selection: Selection;
  currentFilters: Record<string, any>;
  currentSortData: any[];
  currentFilterViewId: string;
  globalSearch: string;
  totalContactsCount: number;
  schema: Record<string, any>;
};

export default function ExportContactListModal(props: ExportContactListModalProps): React.ReactNode {
  const { show, onClose } = props;
  return (
    <CpModal show={show} onClose={onClose}>
      <ModalContent {...props} />
    </CpModal>
  );
}

type ModalContentProps = ExportContactListModalProps;

function ModalContent({
  filterViews,
  filterViewOverrides,
  selection,
  currentFilters,
  currentSortData,
  currentFilterViewId,
  globalSearch,
  onClose,
  totalContactsCount,
  schema,
}: ModalContentProps): React.ReactNode {
  const currentViewOption = {
    id: "current-view",
    name: `Current Filter View (${totalContactsCount})`,
    filter_data: { filter_data: currentFilters, sort_data: currentSortData },
  };
  const [search, setSearch] = useState("");
  const [value, setValue] = useState(currentViewOption);
  const [exportAll, setExportAll] = useState(false);

  function exportList() {
    const { closeToast } = infoToast(`Exporting contact list: ${value.name}. This may take a moment.`);
    const isCurrentView = value.id === "current-view" || value.id === "current-view-with-selections";
    const filterView = isCurrentView ? filterViews.find((d) => d.id === currentFilterViewId) : value;

    const override = filterViewOverrides[(filterView as FilterViewType)?.id];
    const visibleColumns = override?.visible_columns || (filterView as FilterViewType)?.filter_data.visible_columns;
    const columnOrder = override?.column_order || (filterView as FilterViewType)?.filter_data.column_order;
    const filterData = isCurrentView ? currentFilters : (filterView as FilterViewType)?.filter_data.filters;
    const sortData = isCurrentView ? currentSortData : (filterView as FilterViewType)?.filter_data.sort_data;

    exportFilterView({
      filters: filterData,
      sort_data: sortData?.filter((d) => !!d.sort),
      export_columns: getExportColumns({
        exportAll,
        visibleColumns,
        columnOrder,
      }).map((col: any) => schema[col].fieldId),
      search: globalSearch,
      ...(value.id === "current-view-with-selections" ? { [selection.type]: selection.toArray() } : {}),
    }).subscribe((csvText: string) => {
      closeToast();
      downloadText(`CanopyContactsExport - ${value.name}.csv`, csvText, "text/csv");
      successToast("Export complete");
    }, handleError);
    onClose();
  }

  return (
    <>
      <CpModal.Header title="Export List" />
      <CpModal.Body>
        Select a list to export
        <CpSelectSingle
          autoSelectOnSearch
          data={[
            {
              id: "current-view",
              data: [
                currentViewOption,
                ...(selection.totalSelected > 0
                  ? [
                      {
                        id: "current-view-with-selections",
                        name: `Selected Contacts (${selection.totalSelected})`,
                      },
                    ]
                  : []),
              ],
            },
            {
              id: "filter-views",
              data: filterViews,
            },
          ]}
          isGroupData
          groupType="border"
          searchValue={search}
          searchOnChange={setSearch}
          value={value}
          onChange={setValue}
          searchFilter={CpSelectSingle.filterAlpha(search)}
          triggerIsBlock
        />
        <div className="cp-mt-16">
          <CpCheckbox checked={exportAll} onChange={setExportAll}>
            Export all visible and hidden columns
          </CpCheckbox>
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={exportList} className="cp-mr-8">
          Export
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
