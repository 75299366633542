import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import {
  getEmailThreadMessages as getCommsEmailThreadMessages,
  GetEmailThreadMessagesParams as GetCommsEmailThreadMessagesParams,
  GetEmailThreadMessagesResponse as GetCommsEmailThreadMessagesResponse,
} from "src/communications/thread-view/messages.resource";
import {
  getEmailThreadMessages as getInboxEmailThreadMessages,
  GetEmailThreadMessagesParams as GetInboxEmailThreadMessagesParams,
  GetEmailThreadMessagesResponse as GetInboxEmailThreadMessagesResponse,
} from "src/inbox/inbox.resource";

export const emailMessageQueries = genQueries("messages", ({ genKey }) => ({
  commsEmailThreadMessages: (params: GetCommsEmailThreadMessagesParams) =>
    queryOptions<GetCommsEmailThreadMessagesResponse>({
      queryKey: genKey("comms-messages", params),
      queryFn: () => getCommsEmailThreadMessages(params),
      staleTime: 5 * 60 * 1000, // 5min
      retry: 3,
      retryDelay: (failureCount) => {
        return 800 * failureCount;
      },
    }),
  inboxEmailThreadMessages: (params: GetInboxEmailThreadMessagesParams, enabled: boolean = true) =>
    queryOptions<GetInboxEmailThreadMessagesResponse>({
      queryKey: genKey("inbox-messages", params),
      queryFn: () =>
        getInboxEmailThreadMessages(params).then((res) => ({
          ...res,
          messages: res.messages.reverse(),
        })),
      staleTime: 5 * 60 * 1000, // 5min
      meta: {
        // No toast on error, we will instead show an error state in the messages pane
        errorOptions: () => ({ showToast: false }),
      },
      enabled,
    }),
}));
