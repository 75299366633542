import React from "react";
import { MergeContactsStep } from "./merge-contacts.component";
import { tw } from "src/tailwind";
import MergeModalWell from "./merge-modal-well.component";

export const Instructions = ({ step }: { step: MergeContactsStep }) => {
  return (
    <MergeModalWell title="Instructions" className={tw("max-w-[21.6rem] max-h-fit")}>
      <div className={`cp-color-app-secondary-text ${tw("flex flex-col gap-[0.8rem]")}`}>
        {step === "selectMainContact" ? (
          "Select a main contact. The emails, phones, and addresses from the rest of the contacts will be merged into the main contact's record."
        ) : (
          <>
            <div>
              Emails, phone numbers, addresses, and client assignments from the other contacts have been added to the
              main contact.
            </div>
            <div>
              The first name, middle name, last name, birthdate, SSN, occupation, and employer have defaulted to the
              main contact’s information.
            </div>
          </>
        )}
      </div>
    </MergeModalWell>
  );
};
