export const CP_CONFLICT_MESSAGE =
  "Contacts with client portal logins cannot be merged together. Select one contact to proceed with the merge. The unselected contacts will be excluded from the merge.";
export const QBO_CONFLICT_MESSAGE =
  "QBO synced contacts are unable to merge together. Select one contact to proceed. The unselected contacts will be excluded from the merge.";

export const iconData = {
  noCp: {
    name: "remove-circle-open-small",
    fill: undefined,
    tooltip: "No client portal invite sent",
  },
  hasCp: {
    name: "checkmark-circle-open-small",
    fill: "var(--cp-color-app-success-text)",
    tooltip: "Client portal invite sent",
  },
};
