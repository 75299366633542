import { fetcher } from "fetcher!sofe";
import { EMPTY } from "rxjs";

export const getClientUsers = (clientId) =>
  fetcher(`/api/clients/${clientId}/users-and-admins`).then((res) => res.users);

export const getBalance = (clientId) =>
  fetcher(`/api/dashboard/invoices/outstanding?client_id=${clientId}`);

export const getInvoices = (clientId, body) =>
  fetcher(`/api/clients/${clientId}/invoices:search?limit=999999&page=1`, {
    method: "POST",
    body,
  }).then(({ invoices }) => ({
    invoices: invoices.filter((invoice) => invoice.status !== "paid"),
    paidInvoices: invoices.filter((invoice) => invoice.status === "paid"),
  }));

export const getPayments = (clientId) =>
  fetcher(`/api/payments:search?limit=999999999&page=1`, {
    method: "POST",
    body: { client_id: { filter_params: [clientId] } },
  }).then((res) => res.payments);

export const getRefunds = (clientId) =>
  fetcher(`/api/payments:search?limit=999999999&page=1`, {
    method: "POST",
    body: { client_id: { filter_params: [clientId] }, refunds_only: true },
  }).then((res) => res.payments);

export const getUpcomingPayments = (clientId) =>
  fetcher(
    "/api/payments/recurrences:search?scheduled_only=true&limit=999999999&page=1",
    {
      method: "POST",
      body: { client_name: { filter_params: [clientId] } },
    }
  ).then((res) => res.recurrences);

export const getScheduledPayment = (paymentId) => {
  if (!paymentId) return EMPTY;
  return fetcher(`/api/payments/recurrences/${paymentId}`).then(
    (res) => res.recurrences
  );
};

export const getCredits = (clientId) =>
  fetcher(`/api/clients/${clientId}/credits?limit=999999999&page=1`).then(
    (res) => res.credits
  );
