import React from "react";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import { capitalize } from "lodash";
import { TAssignedClient } from "src/common/types";

type AssignedClientProps = {
  assignedClient: TAssignedClient;
  closeModal?: () => void;
  disableAllLinks?: boolean;
};

export function AssignedClient({ assignedClient, closeModal, disableAllLinks = false }: AssignedClientProps) {
  const { name, contact_type, contact_description, has_access, id, latest_invite_sent } = assignedClient;
  const hasClientPortalAccess = !!latest_invite_sent;
  return (
    <div className="cp-flex-spread">
      <div style={{ overflow: "auto" }}>
        <div className="cp-caption cp-color-app-secondary-text">{capitalize(contact_type)}</div>
        <div className="cp-body-sm cp-ellipsis">
          {has_access && !disableAllLinks ? (
            <a href={`/#/client/${id}`} onClick={() => closeModal?.()}>
              {name}
            </a>
          ) : (
            name
          )}
        </div>
        <div className="cp-caption cp-color-app-secondary-text cp-ellipsis">
          <i>{contact_description}</i>
        </div>
      </div>
      {hasClientPortalAccess && (
        <CpTooltip text="Access to client portal">
          <CpIcon name="checkmark-circle-open-small" fill="var(--cp-color-app-success-text)" />
        </CpTooltip>
      )}
    </div>
  );
}
