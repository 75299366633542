import React from "react";
import styles from "./common-signing-field.styles.css";
import { CpIcon } from "canopy-styleguide!sofe";
import { signingFieldTypes } from "../constants.js";
import { convertDateToString, userMatchesSigningLocation } from "../signing-modal.helper.js";

export const CompletedField = (props) => {
  const { signingObject, context, teamMember, completedFieldRef, signatureFontSize, openSignatureEntryModal } = props;
  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialsObject = signingObject.type === signingFieldTypes.INITIAL;
  const isTextObject = signingObject.type === signingFieldTypes.TEXT;
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;

  return (
    <div
      ref={completedFieldRef}
      className={`${styles.filledSigningObjectField} ${styles.modalMode} ${isSignatureObject ? "cp-ellipsis" : ""}`}
      onClick={!isDateObject ? (forCurrentUser && !hasBeenSigned ? props.onClick : null) : null}
      style={{
        fontSize: `${signatureFontSize}px`,
        fontFamily: (isSignatureObject || isInitialsObject) && signingObject.font,
        cursor: !isDateObject && forCurrentUser && !hasBeenSigned ? "pointer" : "default",
        paddingBottom: isDateObject || isInitialsObject || (isSignatureObject && signingObject.signed) ? "6px" : "0px",
        bottom: isTextObject ? "auto" : "0px",
      }}
    >
      {/* Display filled signature */}
      {isDateObject ? convertDateToString(signDate) : signingObject.value}
      {forCurrentUser && isSignatureObject && signingObject.value && !signingObject.signed && (
        <CpIcon
          name="close-circle-filled"
          fill={teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"}
          onClick={(e) => {
            e.stopPropagation();
            openSignatureEntryModal();
          }}
        />
      )}
    </div>
  );
};

CompletedField.displayName = "CompletedField";
