import React from 'react';
import PropTypes from 'prop-types';

import { CpButton, CpCard, CpIcon, CpTooltip, CpWell } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import styles from './esign-file-well.styles.css';
import { EsignFileWellSigner } from './esign-file-well-signer.component';

export default class EsignFileWell extends React.Component {
  static propTypes = {
    requestTitle: PropTypes.string.isRequired,
    signers: PropTypes.arrayOf(
      PropTypes.shape({
        signatory_user_id: PropTypes.string.isRequired,
        signatory_user_name: PropTypes.string.isRequired,
        practitioner: PropTypes.bool.isRequired,
        signed: PropTypes.bool.isRequired,
        completed_at: PropTypes.string,
      })
    ),
    completedEsignFileId: PropTypes.string,
    toggleFilePreview: PropTypes.func.isRequired,
  };

  render() {
    const { signers, requestTitle, completedEsignFileId, toggleFilePreview, openEsign, kba, taskId, task } = this.props;
    const signingCompleted = !!completedEsignFileId;

    const { countSigned, signerChips } = signers.reduce(
      (data, signer, index) => {
        data.signerChips.push(
          <EsignFileWellSigner key={index} signer={signer} taskId={taskId} task={task} />
        );
        data.countSigned += signer.signed;
        return data;
      },
      {
        countSigned: 0,
        signerChips: [],
      }
    );

    return (
      <React.Fragment>
        {featureEnabled('toggle_files_esign_improvements') ? (
          <div className={`cp-p-16 ${styles.formBox}`}>
            <div className={`${styles.esignFileWellTitle} cp-mb-16`}>
              <h5 className="cp-body cp-wt-semibold cp-m-0">eSign Details</h5>
              {kba && (
                <div className={`${styles.kbaContainer}`}>
                  <CpIcon className="cp-mr-4" fill="var(--cp-color-app-icon)" name="misc-kba-shield" />
                  KBA
                </div>
              )}
            </div>
            <div className={`${styles.esignContainer}`}> 
              <div className="cp-body-sm cp-mb-8">File</div>
              <CpWell className={`${styles.fileCard} cp-p-16 cp-mb-16`}>
                <CpIcon
                  name="file-pdf"
                  fill="var(--cp-color-app-icon)"
                />
                <div className={`${styles.fileCardBody}`}>
                  <CpTooltip position="top" text={requestTitle}>
                    <p className="cp-m-0 cp-ellipsis">{requestTitle}</p>
                  </CpTooltip>
                </div>
                <CpButton
                  className={`${styles.noShrinkButton}`}
                  btnType="secondary"
                  onClick={signingCompleted ? toggleFilePreview : openEsign}
                >
                  View
                </CpButton>
              </CpWell>
              <div className="cp-body-sm cp-mb-8">{`${countSigned} of ${signerChips.length} signers completed`}</div>
              <div>{signerChips}</div>
            </div>
          </div>
        ) : (
          <div className={`cp-p-16 ${styles.formBox}`}>
            <div className={`${styles.esignFileWellTitle} cp-mb-12`}>
            <CpIcon className="cp-mr-4" fill="var(--cp-color-app-icon)" name="communication-signature-pen" />
            <h5 className="cp-body cp-m-0">eSign file</h5>
            {kba && (
              <div className={`${styles.kbaContainer}`}>
                <CpIcon className="cp-mr-4" fill="var(--cp-color-app-icon)" name="misc-kba-shield" />
                KBA
              </div>
            )}
          </div>
          <div className={`${styles.esignContainer}`}>
            <CpCard className={`${styles.fileCard}`}>
              <CpIcon
                name="file-pdf"
                fill="var(--cp-color-app-icon)"
              />
              <div className={`${styles.fileCardBody}`}>
                <CpTooltip position="top" text={requestTitle}>
                  <p className="cp-m-0 cp-ellipsis">{requestTitle}</p>
                </CpTooltip>
                <p
                  className={`cp-m-0 cp-wt-bold ${signingCompleted && styles.signedFileCardResend}`}
                >{`${countSigned}/${signerChips.length} Signed`}</p>
              </div>
              <CpButton
                className={`${styles.noShrinkButton}`}
                btnType="secondary"
                onClick={signingCompleted ? toggleFilePreview : openEsign}
              >
                View
              </CpButton>
            </CpCard>
            <div className={`${styles.signersContainerResend}`}>
              <div className="cp-mb-12">
                <div>Signers:</div>
              </div>
              <div>{signerChips}</div>
            </div>
          </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
