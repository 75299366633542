import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { CpButton, CpCard, CpIcon, CpNameChip, CpWell, CpPillButton, CpTooltip } from 'canopy-styleguide!sofe';
import { featureEnabled } from 'feature-toggles!sofe';
import { resendEsignRequest } from 'src/resources/esign.resource';
import { handleError } from 'src/common/error.helper';
import { CustomPillButton} from './esign-custom-pill-button.component';

import styles from './esign-file-well.styles.css';

export function EsignFileWellSigner({ signer, taskId, task }) {
  const signerResentInfo = task?.request_tools[0].relationships.assigned_to.find(relationship =>
    signer.signer_id
      ? relationship.signer_id === signer.signer_id
      : !relationship.signer_id && relationship.user_id === signer.signatory_user_id
  );

  const [resent, setResent] = useState(false);
  const [resentDate, setResentDate] = useState(
    signerResentInfo?.last_resent_at
      ? DateTime.fromISO(signerResentInfo.last_resent_at).toLocaleString(DateTime.DATE_SHORT)
      : null
  );
  const dateFirstSent = 
    task?.request_tools[0].first_sent_at
      ? DateTime.fromISO(task.request_tools[0].first_sent_at).toLocaleString(DateTime.DATE_SHORT)
      : null

  const signingChipStyle = useMemo(() => {
    return signer.practitioner ? styles.signerChipGreen : styles.signerChipBlue;
  }, [signer]);

  const handleClick = useCallback(() => {
    resendEsignRequest(taskId, signer).subscribe(() => {
      setResent(true);
    }, handleError);
    setResentDate(DateTime.now().toLocaleString(DateTime.DATE_SHORT));
  }, [signer, taskId]);

  const renderCustomPillButton = (text, date) => (
    <div className={`${styles.signatureInfoPills}`}>
      <CustomPillButton
        disabled
        text={
          <span>
            <span className="cp-wt-semibold">{text}</span> {date}
          </span>
        }
        type="" // blank so no icon and we can use custom icon in CustomPillButton
      />
    </div>
  );

  return (
    <div>
      {featureEnabled('toggle_files_esign_improvements') ? (
        <CpWell className={`${styles.fileCardSignerWell}`}>
          <div className={`${styles.fileCardSignerContainer}`}>
            <CpNameChip
              name={signerResentInfo?.name || signer.signatory_user_name}
              className={signer.practitioner ? styles.signerPillGreen : styles.signerPillBlue}
              style={{
                backgroundColor: signer.practitioner
                  ? 'var(--cp-color-pill-team-text)'
                  : 'var(--cp-color-default-badge-bg)',
              }}
            />
            <div className={`${styles.signerInfoContainer}`}>
              <CpTooltip text={signerResentInfo?.name || signer.signatory_user_name} position="bottom-start">
                <div className="cp-body cp-wt-semibold cp-ellipsis">{signerResentInfo?.name || signer.signatory_user_name}</div>
              </CpTooltip>
              <CpTooltip text={signerResentInfo?.email} position="bottom-start">
                <div className="cp-ellipsis">{signerResentInfo?.email}</div>
              </CpTooltip>
            </div>
          </div>
          {!signer.signed ? (
            <div className="cp-flex cp-mt-8">
              <CpPillButton disabled iconColor="var(--cp-color-default-badge-bg)" text="No Signature" type="status" />
              {resentDate ? renderCustomPillButton("Resent", resentDate) : renderCustomPillButton("Sent", dateFirstSent)}
            </div>
          ) : (
            <div className="cp-flex cp-mt-8">
              <CpPillButton disabled iconColor="var(--cp-color-checkbox-selected-bg)" text="Completed" type="status" />
              {renderCustomPillButton("Sent", dateFirstSent)}
              {renderCustomPillButton("Signed", DateTime.fromISO(signer.completed_at).toLocaleString(DateTime.DATE_SHORT))}
            </div>
          )}
          {!signer.signed && (
            <CpButton
              className={`${styles.resendButtonFlat}`}
              btnType="secondary"
              disabled={resent}
              onClick={handleClick}
            >
              Resend
            </CpButton>
          )}
        </CpWell>
      ) : (
        <CpCard className={`${styles.fileCardResend}`}>
          {!signer.signed ? (
            <CpIcon name="shape-circle-dashed" fill="var(--cp-color-app-icon)" />
          ) : (
            <CpIcon name="checkmark-circle-filled-large" fill="var(--cp-color-app-primary)" />
          )}
          <div className={`${styles.signerChipResend} ${signingChipStyle}`}>
            <div className="cp-ellipsis cp-text-center">{signer.signatory_user_name}</div>
          </div>
          {!signer.signed ? (
            <>
              {resentDate ? (
                <div className={`${styles.signatureResentInfo}`}>Resent {resentDate}</div>
              ) : (
                <div className={`${styles.signatureInfo}`}>No Signature</div>
              )}
            </>
          ) : (
            <div className={`${styles.signatureInfo}`}>
              Signed {DateTime.fromISO(signer.completed_at).toLocaleString(DateTime.DATE_SHORT)}
            </div>
          )}
          {!signer.signed && (
            <CpButton
              className={`${styles.noShrinkButton} ${styles.resendButton}`}
              btnType="flat"
              disabled={resent}
              onClick={handleClick}
            >
              Resend
            </CpButton>
          )}
        </CpCard>
      )}
    </div>
  );
}
