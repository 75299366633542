import { pluck } from "rxjs/operators";
import { fetchAsObservable, fetchWithSharedCache } from "fetcher!sofe";
import { queryClient } from "src/react-query";
import { clientQueries } from "src/queries";
import { from } from "rxjs";

export function getSources(forceBust = false) {
  return fetchWithSharedCache(`api/client_sources`, "#/client", forceBust).pipe(pluck("client_sources"));
}

export function getClientAndAll(clientId) {
  if (!clientId || clientId === "") {
    throw new Error("cannot get client without a clientID");
  }
  const includes =
    "users,clients,tags,client_for,client_sources,integrations,roles,general_assigned,contacts,client_groups";

  return from(
    queryClient.fetchQuery({
      ...clientQueries.client({
        clientId,
        includes,
      }),
      staleTime: 1000 * 60, // 1min,
      meta: {
        errorOptions: {
          redirectOrCatch: true,
        },
      },
    })
  );
}

export function getUsers(role) {
  return fetchAsObservable(`wg/users${role ? "?role=" + role : ""}&basic=true`).pipe(pluck("users"));
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}
