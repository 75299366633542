import { TContact } from "src/common/types";
import { UseQueryResult } from "@tanstack/react-query";
import { contactMergeQueries } from "src/queries";
import { useQuery } from "src/react-query";
import { MergeContactsParams, MergeContactsResult } from "src/resources/contacts.resource";

export default function usePreviewContactMergeQuery(params: Omit<MergeContactsParams, "action">): {
  mergedContactPreviewQuery: UseQueryResult<MergeContactsResult, unknown>;
  mergedContactPreview: TContact;
} {
  const mergedContactPreviewQuery = useQuery(contactMergeQueries.previewMerge(params));
  const mergedContactPreview = mergedContactPreviewQuery.data?.contact;

  return {
    mergedContactPreviewQuery,
    mergedContactPreview,
  };
}
