// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-form-client-base-client-type-picker-client-type-picker-styles__typeOption {
  border-radius: 26px;
  text-decoration: none;
  -webkit-user-select: none;
          user-select: none;
  font-size: 1.3rem;
  padding: 6px 12px;
  background-color: var(--cps-color-bumble);
  border: 1px solid var(--cps-color-af);
  color: var(--cps-color-monsoon);
  margin-left: 0px;
}

.src-common-form-client-base-client-type-picker-client-type-picker-styles__typeOption:hover,
.src-common-form-client-base-client-type-picker-client-type-picker-styles__typeOption:focus {
  outline: 0;
  cursor: pointer;
  background-color: var(--cps-color-ash);
  border: 1px solid var(--cps-color-monsoon);
  color: var(--cps-color-cool-gray);
}

.src-common-form-client-base-client-type-picker-client-type-picker-styles__selectedTypeOption {
  background-color: rgba(var(--cps-color-primary-rgb), 0.05);
  color: var(--cps-color-primary);
  border: 1px solid var(--cps-color-primary);
}

.src-common-form-client-base-client-type-picker-client-type-picker-styles__selectedTypeOption:hover,
.src-common-form-client-base-client-type-picker-client-type-picker-styles__selectedTypeOption:focus {
  outline: 0;
  background-color: rgba(var(--cps-color-primary-rgb), 0.05);
  color: var(--cps-color-primary);
  border: 1px solid var(--cps-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/common/form/client/base/client-type-picker/client-type-picker.styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,yBAAiB;UAAjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,yCAAyC;EACzC,qCAAqC;EACrC,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;;EAEE,UAAU;EACV,eAAe;EACf,sCAAsC;EACtC,0CAA0C;EAC1C,iCAAiC;AACnC;;AAEA;EACE,0DAA0D;EAC1D,+BAA+B;EAC/B,0CAA0C;AAC5C;;AAEA;;EAEE,UAAU;EACV,0DAA0D;EAC1D,+BAA+B;EAC/B,0CAA0C;AAC5C","sourcesContent":[".typeOption {\n  border-radius: 26px;\n  text-decoration: none;\n  user-select: none;\n  font-size: 1.3rem;\n  padding: 6px 12px;\n  background-color: var(--cps-color-bumble);\n  border: 1px solid var(--cps-color-af);\n  color: var(--cps-color-monsoon);\n  margin-left: 0px;\n}\n\n.typeOption:hover,\n.typeOption:focus {\n  outline: 0;\n  cursor: pointer;\n  background-color: var(--cps-color-ash);\n  border: 1px solid var(--cps-color-monsoon);\n  color: var(--cps-color-cool-gray);\n}\n\n.selectedTypeOption {\n  background-color: rgba(var(--cps-color-primary-rgb), 0.05);\n  color: var(--cps-color-primary);\n  border: 1px solid var(--cps-color-primary);\n}\n\n.selectedTypeOption:hover,\n.selectedTypeOption:focus {\n  outline: 0;\n  background-color: rgba(var(--cps-color-primary-rgb), 0.05);\n  color: var(--cps-color-primary);\n  border: 1px solid var(--cps-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeOption": `src-common-form-client-base-client-type-picker-client-type-picker-styles__typeOption`,
	"selectedTypeOption": `src-common-form-client-base-client-type-picker-client-type-picker-styles__selectedTypeOption`
};
export default ___CSS_LOADER_EXPORT___;
