import * as Sentry from "@sentry/browser";
import canopyUrls from "canopy-urls!sofe";
import * as singleSpaHelpers from "./single-spa.helpers.js";
import * as urlHashHelpers from "./url-hash.helpers.js";
import { beforeSend, handleToaster } from "./sentry.helper.js";

export { getSquad as serviceNameToSquad } from "./error.helpers.js";
export { ErrorBoundary } from "./error-boundary";

let suppressErrors;

export function setupHandleError(service, config = {}) {
  return (error, opts = {}) => {
    error.service = service;
    const { showToast, toastMessage, skipLogging, redirectOrCatch, ...rest } =
      opts;
    if (typeof showToast === "boolean") {
      error.showToast = showToast;
    }
    if (typeof toastMessage === "string") {
      error.toastMessage = toastMessage;
    }
    if (redirectOrCatch) {
      // migrated from fetcher
      if (error.status === 404 || error.status === 403) {
        const findString = /\/(api|wg)\/\S+/.exec(error.message);
        const urlString = findString ? findString[0] : "A request";
        console.info(`${urlString} returned ${error.status}, redirecting`);
        window.history.replaceState(null, "", `/#/${error.status}`);
        return;
      }
    }

    let updatedError = handleToaster(error);

    if (skipLogging || error?.skipLogging) return;
    console.error(error);

    const options = { ...config, ...rest };
    captureException(updatedError, options);
  };
}

export function init(version) {
  if (typeof version !== "string") {
    throw new Error(
      `sentry must be initialized with a string "version" of the code`
    );
  }

  singleSpaHelpers.init();
  urlHashHelpers.init();

  if (canopyUrls.getEnvironment() === canopyUrls.DEVELOPMENT_ENVIRONMENT) {
    locallyTrackErrors();
    return; // We don't want to do sentry handling in development
  }

  loadSentry(version);
}

function locallyTrackErrors() {
  const errCallback = window.onerror;
  window.onerror = function (message, url, line, col, err) {
    errCallback && errCallback.apply(window, arguments);
    if (!suppressErrors) {
      handleToaster(err);
    }
  };
}

export function setUser(user) {
  sentrySetUser(user);
}

// this is exported for being able to add breadcrumbs to sentry when it becomes important to debug something.
// see https://docs.sentry.io/enriching-error-data/breadcrumbs/ for documentation
export function addBreadcrumb(message, category = "misc", level = "info") {
  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
}

export function captureMessage(msg, extraData = {}) {
  if (typeof msg === "string") {
    msg = new String(msg);
    msg.showToast = false;
  }

  const level = extraData.level || "info";
  const tags = extraData.tags || {};
  delete extraData.tags;
  delete extraData.level;
  addTags(tags);

  Sentry.withScope((scope) => {
    for (let tag in tags) {
      if (tags.hasOwnProperty(tag)) scope.setTag(tag, tags[tag]);
    }

    for (let extra in extraData) {
      if (extraData.hasOwnProperty(extra))
        scope.setExtra(extra, extraData[extra]);
    }

    Sentry.captureMessage(msg, level);
  });
}

export function captureException(ex = {}, extraData = { tags: {} }) {
  // The default argument will not guarantee the presence of extraData.tags
  const tags = extraData.tags || {};
  delete extraData.tags;
  addTags(tags);

  Sentry.withScope((scope) => {
    for (let tag in tags) {
      if (tags.hasOwnProperty(tag)) scope.setTag(tag, tags[tag]);
    }

    for (let extra in extraData) {
      if (extraData.hasOwnProperty(extra))
        scope.setExtra(extra, extraData[extra]);
    }

    Sentry.captureException(ex);
  });
}

function addTags(tags) {
  singleSpaHelpers.addActiveApps(tags);
  urlHashHelpers.addUrlHash(tags);
}

function sentrySetUser(loggedInUser) {
  Sentry.configureScope((scope) => {
    if (loggedInUser) {
      scope.setUser({
        email: loggedInUser.email,
        name: loggedInUser.name,
        id: loggedInUser.id,
        role: loggedInUser.role,
      });

      //Allows search by userEmail and userName in sentry
      scope.setTag("userEmail", loggedInUser.email);
      scope.setTag("userName", loggedInUser.name);
      scope.setTag("userRole", loggedInUser.role);
    }
  });
}

function loadSentry(version) {
  const dashIndex = version.indexOf("-");
  let release = dashIndex >= 0 ? version.substring(0, dashIndex) : version;

  Sentry.init({
    dsn: "https://8ca7bd5b6afd45e389d5cc45dd8ab1a1@o4504080391733248.ingest.sentry.io/4504080460480512",
    beforeSend: beforeSend,
    environment: canopyUrls.getEnvironment(),
    maxValueLength: 5000,
    release,
    ignoreErrors: [
      "TypeError: Load failed",
      "Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "TypeError: cancelled",
      "AbortError",
      "Synchronous XHR in page dismissal",
      "Error: ResizeObserver loop limit exceeded",
      "ChunkLoadError",
      /Error: (.*) died in status BOOTSTRAPPING(.*)/,
      "Missing credentials cookie",
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag("version", version);
    scope.setTag("environment", canopyUrls.getEnvironment());

    singleSpaHelpers.sentryLoaded(scope);
    urlHashHelpers.sentryLoaded(scope);
  });
}
