import React from 'react';
import { CpIcon, CpPillButton } from 'canopy-styleguide!sofe';

export const CustomPillButton = ({ ...props }) => (
  <div style={{ position: 'relative' }}>
    <CpPillButton {...props} 
      className="cp-pl-28"
      style={{ maxHeight: '24px' }}
    />
    <CpIcon 
      name="misc-calendar"
      fill="var(--cp-color-app-icon)"
      style={{
        position: 'absolute',
        height: '16px',
        width: '16px',
        left: '8px',
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none'
      }}
    />
  </div>
);