import React, { useContext } from "react";
import { DateTime } from "luxon";
import {
  CpCard,
  CpCheckbox,
  CpEmptyState,
  CpIcon,
  CpLoader,
} from "canopy-styleguide!sofe";
import { CurrencyInput } from "src/common/currency-input/currency-input.component";
import { BillingContext, PaymentContext } from "../billing-context";
import { formatNumber } from "../billing-helpers";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { useBalance } from "../billing-hooks";

export default function SelectInvoices({ clientId }) {
  const { hasBillingPermissions, showCpBilling } = useContext(BillingContext);
  const { balance } = useBalance(
    clientId,
    showCpBilling,
    hasBillingPermissions
  );
  const { paymentDetails, actions } = useContext(PaymentContext);
  const phoneOnly = useMediaQuery("--phone-only");

  const handleInvoiceSelected = (id, isChecked) => {
    const updatedInvoices = paymentDetails.invoices.map((invoice) => {
      if (invoice.id === id) {
        invoice.selected = isChecked;
        if (isChecked) {
          invoice.amountToPay = invoice.balance;
        } else {
          invoice.amountToPay = "";
        }
      }
      return invoice;
    });

    actions.updateInvoices(updatedInvoices);
  };

  const handleAmountToPayChanged = (id, amount, validate) => {
    const updatedInvoices = paymentDetails.invoices.map((invoice) => {
      if (invoice.id === id) {
        if (validate) {
          if (amount > invoice.balance) {
            invoice.amountToPay = invoice.balance;
            if (invoice.amountToPay > 0) {
              invoice.selected = true;
            }
          } else if (amount > 0) {
            invoice.amountToPay = amount;
            invoice.selected = true;
          } else {
            invoice.amountToPay = "";
            invoice.selected = false;
          }
        } else {
          invoice.amountToPay = amount;
        }
      }
      return invoice;
    });

    actions.updateInvoices(updatedInvoices);
  };

  const amountToPayInput = (invoice) => (
    <div onClick={(e) => e.preventDefault()}>
      <CurrencyInput
        value={invoice.amountToPay}
        onChange={(newValue, validate) =>
          handleAmountToPayChanged(invoice.id, newValue, validate)
        }
      />
    </div>
  );

  const ScheduledPaymentNotice = ({ payments }) => (
    <div className="scheduled-payment-notice">
      <div className="scheduled-payment-notice__icon">
        <CpIcon name="information-circle-open-large" />
      </div>
      <div>
        <div className="cps-wt-semibold">
          {payments.length === 1 ? (
            <>
              {`This invoice has a payment scheduled for ${DateTime.fromISO(
                payments[0].next_occurrence
              ).toLocaleString(DateTime.DATE_MED)} of ${formatNumber(
                payments[0].amount,
                true,
                2
              )}.`}
              <div>Any payments made may affect this scheduled payment.</div>
            </>
          ) : (
            <>
              <div>This invoice has multiple payments scheduled.</div>
              <div>Any payments made may affect these scheduled payments.</div>
              <ul className="cp-mt-8">
                {payments
                  .sort(
                    (a, b) =>
                      DateTime.fromISO(a.next_occurrence) -
                      DateTime.fromISO(b.next_occurrence)
                  )
                  .map((payment) => (
                    <li key={payment.recurrence_id}>
                      {`${DateTime.fromISO(
                        payment.next_occurrence
                      ).toLocaleString(DateTime.DATE_MED)} of ${formatNumber(
                        payment.amount,
                        true,
                        2
                      )}`}
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {paymentDetails.invoices?.length > 0 && (
        <>
          <div className="cps-subheader-sm cps-wt-semibold">
            Outstanding Invoices
          </div>
          <div className="cp-color-app-secondary-text">
            Current account balance: {formatNumber(balance, true, 2)}
          </div>
        </>
      )}
      <div className="invoice-list">
        {!paymentDetails.invoices && <CpLoader />}
        {paymentDetails.invoices?.length === 0 && (
          <CpEmptyState img="es_thumbs_up" text="Account paid in full" />
        )}
        {paymentDetails.invoices?.map((invoice) => {
          const isOverdue =
            DateTime.fromISO(invoice.due_date) <
            DateTime.local().startOf("day");
          const scheduledPayments = invoice.scheduled_payments.filter(
            (payment) =>
              payment.next_occurrence &&
              payment.recurrence_id != paymentDetails.paymentId
          );
          return (
            <React.Fragment key={invoice.id}>
              <CpCard
                className={`invoice-card ${invoice.selected ? "selected" : ""}`}
                level={2}
              >
                <CpCheckbox
                  onChange={(isChecked) =>
                    handleInvoiceSelected(invoice.id, isChecked)
                  }
                  checked={invoice.selected}
                  style={{ alignItems: "center" }}
                >
                  <div>
                    <div>
                      <span className="currency-symbol-small">$</span>
                      <span className="cps-title">
                        {formatNumber(invoice.balance, false, 2)}
                      </span>{" "}
                      <span>Balance</span>
                    </div>
                    <div className="cp-color-app-secondary-text">
                      <span
                        className={isOverdue ? "cp-color-app-error-text" : ""}
                      >
                        Due{" "}
                        {DateTime.fromISO(invoice.due_date).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </span>
                      <span className="cp-ml-8">
                        Invoice #{invoice.invoice_number}
                      </span>
                    </div>
                  </div>
                  {phoneOnly && amountToPayInput(invoice)}
                </CpCheckbox>
                {!phoneOnly && (
                  <div style={{ paddingRight: "24px" }}>
                    {amountToPayInput(invoice)}
                  </div>
                )}
              </CpCard>
              {invoice.selected && scheduledPayments.length > 0 && (
                <ScheduledPaymentNotice payments={scheduledPayments} />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
