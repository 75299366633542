import React, { useRef } from "react";

import { CpButton, useIsMounted } from "canopy-styleguide!sofe";
import { warningToast } from "toast-service!sofe";

export default function AddFileButton(props) {
  const inputRef = useRef();
  const isMounted = useIsMounted();

  const selectFile = () => {
    const allFiles = Array.from(inputRef.current.files);
    const files = allFiles.filter((file) => !!file.size);
    if (allFiles.length !== files.length) {
      const numberOfEmptyFiles = allFiles.length - files.length;
      warningToast(
        `Unable to upload (${numberOfEmptyFiles}) empty file${
          numberOfEmptyFiles > 1 ? "s" : ""
        }.`
      );
    }
    if (!files.length) return;
    if (!isMounted.current) return;
    props.handleFiles(files);
  };

  const clearValue = (e) => {
    e.target.value = null;
  };

  return (
    <div style={{ position: "relative" }}>
      <CpButton
        icon="misc-paperclip"
        onClick={() => {
          inputRef.current.click();
        }}
        aria-label="Add file"
        btnType="flat"
      />
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        ref={inputRef}
        onChange={selectFile}
        onClick={clearValue}
      />
    </div>
  );
}
