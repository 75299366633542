import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

const headers =
  typeof Headers !== "undefined"
    ? new Headers({
        "Content-Type": "application/json",
      })
    : {};

export function getAttachments(clientId, parentId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/attachments/?pivot_type=client_requests&pivot_id=${parentId}`
  ).pipe(pluck("attachments"));
}

export function postAttachment(clientId, parentId, file) {
  return fetchAsObservable(
    `/api/clients/${clientId}/attachments?pivot_type=request_comment&pivot_id=${parentId}`,
    {
      method: "POST",
      body: JSON.stringify({ attachments: { file } }),
      headers,
    }
  ).pipe(pluck("attachments"));
}

export function patchAttachmentsForComment(clientId, fileId, file) {
  return fetchAsObservable(`/api/clients/${clientId}/attachments/${fileId}`, {
    method: "PATCH",
    body: JSON.stringify({
      attachments: { ...file, id: fileId },
      notifications: { client_url: "", teammember_url: "" },
    }),
  });
}

export function saveMessage({ clientRequestID, message, files }) {
  const comment = {
    message: message,
    file_ids: files,
  };
  return fetchAsObservable(`/api/client-requests/${clientRequestID}/comments`, {
    method: "POST",
    body: JSON.stringify({ comment }),
    headers,
  });
}

export function getRelatedFiles(type, ids, parentId) {
  let filesInfo = { related_id_mappings: {} };
  if (ids.length) {
    filesInfo.related_id_mappings[type] = ids;
  }
  if (parentId) {
    filesInfo.related_id_mappings.request_tool = [parentId];
  }
  return fetchAsObservable(`/api/docs/related-files`, {
    method: "POST",
    body: JSON.stringify(filesInfo),
    headers,
  }).pipe(pluck("related_files_for"));
}
