import React from "react";
import PropTypes from "prop-types";
import { findIndex, cloneDeep } from "lodash";
import styles from "../signing-modal.styles.css";
import { dragTypes, signingFieldTypes, signatureElements, ResizableFieldDimensions } from "../constants.js";
import PdfPage from "../document/pdf-page.component";
import { DocumentDropWrapper } from "./drop-wrapper.component";
import { featureEnabled } from "feature-toggles!sofe";
import { SigningObjects } from "../signing-items/signing-fields.component";
import { v4 as uuid } from "uuid";

export const dropSigningField = (item, monitor, props) => {
  //List of signingObjects
  const signingObjects = [...props.signingLocations];
  //Creates/Moves the dropped signingObjects
  const signingObject = buildSigningObject(monitor, props);
  if (signingObject.id) {
    //Replace signingObjects
    const index = findIndex(signingObjects, (item) => item.id === signingObject.id);
    signingObjects[index] = signingObject;
    props.setSigningLocations(signingObjects);
    props.setSigningField(signingObject);
  } else {
    //Create new signingObjects
    const newSigningObject = { ...signingObject, id: uuid() };
    signingObjects.push(newSigningObject);
    props.setSigningLocations(signingObjects);
  }
};

/**
 * Helper function that builds the new signingObject
 */
function buildSigningObject(monitor, props) {
  //data from item that was dropped
  const draggedItemData = monitor.getItem();
  //Drop Coordinates
  const signingObjectCoordinates = calculateSigningObjectCoordinates(monitor, props.page);

  return { ...draggedItemData, ...signingObjectCoordinates, page: props.page };
}

/**
 * Calculates the coordinates for signingField placement on page
 */
function calculateSigningObjectCoordinates(monitor, page) {
  const itemType = monitor.getItemType();
  const item = monitor.getItem();
  const isSigningField = itemType === dragTypes.SIGNING_FIELD;
  const isExistingResizableField = item.x && item.y && item.width && item.height;

  //Difference between cursor and element being dragged
  const startDragCursorLocation = monitor.getInitialClientOffset();
  const startDragItemLocation = monitor.getInitialSourceClientOffset();
  const xStartDragOffset = startDragCursorLocation.x - startDragItemLocation.x;
  const yStartDragOffset = startDragCursorLocation.y - startDragItemLocation.y;

  //DropZone location
  const dropAreaElement = document.getElementById(`pageView${page}`);
  const dropAreaLocation = dropAreaElement.getBoundingClientRect();
  const dropAreaWidth = dropAreaLocation.width;
  const dropAreaHeight = dropAreaLocation.height;

  //Padding calculations will change when we switch to .SVG
  const dropAreaStyles = dropAreaElement.currentStyle || window.getComputedStyle(dropAreaElement);
  const dropAreaPaddingLeft = parseInt(dropAreaStyles.paddingLeft, 10) || 0;
  const dropAreaPaddingTop = parseInt(dropAreaStyles.paddingTop, 10) || 0;

  const endDragCursorLocation = monitor.getClientOffset();

  let x = endDragCursorLocation.x - dropAreaLocation.left - dropAreaPaddingLeft;
  x = isSigningField ? x - xStartDragOffset : x;

  let y = endDragCursorLocation.y - dropAreaLocation.top - dropAreaPaddingTop;
  y = isSigningField ? y - yStartDragOffset : y;
  if (featureEnabled("toggle_files_esign_improvements")) {
    // Adjust drop to position NEW fields by bottom of field
    if (!isExistingResizableField) {
      y = y + 32; //new fields always have a default height of 32
    }
  }

  let menuX = endDragCursorLocation.x;
  menuX = isSigningField ? menuX - xStartDragOffset : menuX;

  let menuY = endDragCursorLocation.y;
  menuY = isSigningField ? menuY - yStartDragOffset : menuY;

  // min/max for keeping element on the page
  x = x >= 0 ? x : 0;
  if (featureEnabled("toggle_files_esign_improvements") && isExistingResizableField) {
    const pageWidthMinusBuffer = dropAreaWidth - 36;
    x = x <= pageWidthMinusBuffer ? x : pageWidthMinusBuffer;

    const fieldHeight = item.height || ResizableFieldDimensions.DEFAULT_HEIGHT;
    const bottomFieldEdge = y;
    const topFieldEdge = y - fieldHeight;
    // if top field edge is above the top of the page, snap field to top of current page
    if (topFieldEdge <= 0) {
      y = fieldHeight;
    }
    // if bottom field edge is below the bottom of the page, snap field to top of current page
    if (bottomFieldEdge >= dropAreaHeight) {
      y = dropAreaHeight;
    }
  } else {
    x = x <= 780 ? x : 780;
  }
  y = Math.abs(y);

  return { x, y, menuX, menuY };
}

export default class DocumentView extends React.Component {
  static propTypes = {
    offset: PropTypes.object,

    signingLocationsAreDraggable: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    loggedInUserSignature: PropTypes.object.isRequired,
    shouldShowSignatureEntryModal: PropTypes.func.isRequired,
    signingSVG: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    signingLocations: PropTypes.array.isRequired,
    setSigningLocations: PropTypes.func.isRequired,
    clientCollaborators: PropTypes.object.isRequired,
    isClient: PropTypes.bool.isRequired,
    openClientModal: PropTypes.func.isRequired,
    setDocumentSize: PropTypes.func.isRequired,
    onDropSignature: PropTypes.func,
    onRemoveSignature: PropTypes.func,
    displayShield: PropTypes.bool,
    modalMode: PropTypes.bool,
    onSignatureClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSelectSignerModal: false,
      signingField: null,
    };
    this.imgRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.documentRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.handleAddPageRef) {
      this.props.handleAddPageRef({ ref: this.documentRef, page: this.props.page });
    }
  }

  render() {
    const { context, documentSizes, page, phoneOnly, setDocumentSize, signingLocations, signingSVG } = this.props;
    const { width, height } = documentSizes?.[page] ? documentSizes?.[page] : { width: 816, height: 1056 };

    return (
      <DocumentDropWrapper {...this.props} setSigningField={(signingField) => this.setState({ signingField })}>
        <div>
          <div
            ref={this.documentRef}
            id={`pageView${page}`}
            className={`${styles.document} cps-depth-2`}
            style={{ ...(phoneOnly && { width: "fit-content" }), width: `${width}px`, height: `${height}px` }}
          >
            <SigningObjects
              signingLocations={signingLocations.filter((signingObject) => signingObject.page === page)}
              allSigningLocations={signingLocations}
              setSigningLocations={this.props.setSigningLocations}
              page={page}
              loggedInUserSignature={this.props.loggedInUserSignature}
              signingLocationsAreDraggable={this.props.signingLocationsAreDraggable}
              context={context}
              isClient={this.props.isClient}
              clientCollaborators={this.props.clientCollaborators}
              displayShield={this.props.displayShield}
              modalMode={this.props.modalMode}
              updateSigningObjectFieldType={this.updateSigningObjectFieldType}
              deleteSigningField={this.deleteSigningField}
              duplicateSigningField={this.duplicateSigningField}
              showSignerDropdown={this.props.showSignerDropdown}
              preAuthSigner={this.props.preAuthSigner}
              onSignatureClick={this.onSignatureClick}
              isTemplateMode={this.props.isTemplateMode}
              getFilledSignature={this.getFilledSignature}
              setSigner={this.setSelectSignerOrType}
              openSignatureEntryModal={(signingObject, objectId) => this.onSignatureClick(signingObject, objectId)}
              documentRef={this.documentRef}
              markFieldAsSigned={this.markFieldAsSigned}
            />

            {featureEnabled("esign_pdf") ? (
              <PdfPage key={`preview-${page}`} src={signingSVG.url} onLoad={this.onPdfLoad.bind(this)} />
            ) : (
              <img
                src={signingSVG.url}
                ref={this.imgRef}
                onLoad={() => {
                  const { clientWidth: width, clientHeight: height } = this.imgRef.current;
                  this.props.determineDocumentLoaded();
                  setDocumentSize(page, { width, height });
                }}
              />
            )}
          </div>
        </div>
      </DocumentDropWrapper>
    );
  }

  isLoggedInUser = (id) => {
    return id === this.props.context.loggedInUser.id;
  };

  /**
   * Sets the signer name/id for fields that will need to be signed by a collaborator
   */
  setSigner = (clientId, clientName, signingFieldId, signerId = null, role) => {
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingFieldId);
    if (this.props.isTemplate) {
      return this.props.assignSignerTypes(
        { signatory_user_id: clientId, ...(signerId && { signer_id: signerId }), role },
        signingObjects[index].signer_type,
        clientName
      );
    }
    signingObjects[index] = {
      ...signingObjects[index],
      signatory_user_id: clientId,
      ...(signerId && { signer_id: signerId }),
      role,
    };
    !signerId && delete signingObjects[index].signer_id;
    this.props.setSigningLocations(signingObjects, undefined);
  };

  setSelectSignerOrType = (selected, signingObject) => {
    const { isTemplateMode } = this.props;
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingObject.id);
    const isTeamMember = selected.role === "TeamMember";
    if (this.props.isTemplate) {
      return this.props.assignSignerTypes(selected, { id: signingObjects[index].signer_type_id });
    }

    signingObjects[index] = {
      ...signingObjects[index],
      ...(!isTemplateMode && { signatory_user_id: selected.user_id || selected.id }),
      ...(!isTemplateMode && !isTeamMember && selected?.user_id && { signer_id: selected.id }),
      ...(isTemplateMode && { signer_type_id: selected.id }),
      ...(isTemplateMode && { signer_type_name: selected.name }),
      role: selected?.role || selected?.user_role || "Client",
      isTeamMember: selected?.role === "TeamMember" || selected?.user_role === "TeamMember",
    };

    !selected.user_id && delete signingObjects[index].signer_id;
    this.props.setSigningLocations(signingObjects, undefined);
  };

  resetSigner = (signingFieldId) => {
    const signingObjects = cloneDeep(this.props.signingLocations);
    const index = findIndex(signingObjects, (item) => item.id === signingFieldId);
    if (signingObjects[index]?.signatory_user_id) signingObjects[index].signatory_user_id = null;
    if (signingObjects[index]?.signer_id) signingObjects[index].signer_id = null;
    this.props.setSigningLocations(signingObjects);
  };

  markFieldAsSigned = (signingFieldId) => {
    const signingObjects = this.props.signingLocations;

    const index = findIndex(signingObjects, (item) => (item.id || item.esigning_location_id) === signingFieldId);
    signingObjects[index] = { ...signingObjects[index], hasBeenSigned: true };
    this.props.setSigningLocations(signingObjects);
  };

  deleteSigningField = (id) => {
    const signingObjects = this.props.signingLocations;
    const index = findIndex(signingObjects, (item) => item.id === id);
    const uniqueUser = signingObjects[index].signer_id || signingObjects[index].signatory_user_id;
    this.props.onRemoveSignature && this.props.onRemoveSignature(uniqueUser);
    signingObjects.splice(index, 1);
    this.props.setSigningLocations(signingObjects);
  };

  duplicateSigningField = (signingObject) => {
    const signingObjects = this.props.signingLocations;
    const newSigningObject = { ...signingObject, id: uuid(), y: signingObject.y + signingObject.height + 10 };
    signingObjects.push(newSigningObject);
    this.props.setSigningLocations(signingObjects);
  };

  updateSigningObjectFieldType = (signingFieldId, newType) => {
    const signingObjects = [...this.props.signingLocations];
    const index = findIndex(signingObjects, (item) => item.id === signingFieldId);

    const newSignatureElement = signatureElements.find((element) => element.type === newType);

    signingObjects[index] = {
      ...signingObjects[index],
      ...newSignatureElement,
      id: signingObjects[index].id,
      signatory_user_id: signingObjects[index].signatory_user_id,
      signer_type_id: signingObjects[index].signer_type_id,
    };

    this.props.setSigningLocations(signingObjects);
  };

  onSignatureClick = (signingObject, objectId) => {
    if (this.props.modalMode) {
      this.props.shouldShowSignatureEntryModal(true, signingObject.type, this.markFieldAsSigned.bind(this, objectId));
    } else {
      this.props.onSignatureClick(this.markFieldAsSigned.bind(this, objectId));
    }
  };

  getFilledSignature = (loggedInUserSignature, signingObject) => {
    return this.isLoggedInUser(signingObject.signer_id ? signingObject.signer_id : signingObject.signatory_user_id) &&
      loggedInUserSignature.signatureText
      ? {
          value:
            signingObject.type === signingFieldTypes.SIGNATURE
              ? loggedInUserSignature.signatureText
              : signingObject.type === signingFieldTypes.TEXT
              ? signingObject.value
              : loggedInUserSignature.initials,
          font: loggedInUserSignature.font,
          completed_at: loggedInUserSignature.completed_at,
        }
      : {};
  };

  onPdfLoad = ({ width, height }) => {
    this.props.setDocumentSize({ width, height });
  };
}
