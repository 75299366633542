import React from "react";
import { useFormContext } from "react-hook-form";
import { CpIcon, CpRadioField, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import { tw } from "src/tailwind";
import { TContact } from "src/common/types";
import { iconData } from "../merge-contacts.helpers";
import { QboIcon } from "src/common/qbo-icon.component";

type ContactRadioWellProps = {
  contacts: TContact[];
  conflictType: "cp" | "qbo";
};

export function ContactRadioWell({ contacts, conflictType }: ContactRadioWellProps) {
  const { control } = useFormContext();
  return (
    <CpWell level={2} className={tw("flex flex-col gap-2 p-4")}>
      <div className="cp-caption cp-wt-semibold">Select contact</div>
      <CpRadioField name={conflictType} fieldName={conflictType} control={control} required>
        <div className={tw("flex flex-col gap-2")}>
          {contacts.map((contact) => (
            <div key={contact.id} className={tw("flex flex-row justify-between items-center")}>
              <div className={tw("flex flex-row")}>
                <CpRadioField.Item id={contact.id} />
                <div className={tw("flex flex-col")}>
                  <div className="cp-body">{contact.name}</div>
                  <div className="cp-caption cp-color-app-secondary-text">{contact.primary_email}</div>
                </div>
              </div>
              <CpTooltip text={conflictType === "cp" ? "Client portal invite sent" : "QBO sync contact"}>
                {conflictType === "cp" ? <CpIcon name={iconData.hasCp.name} fill={iconData.hasCp.fill} /> : <QboIcon />}
              </CpTooltip>
            </div>
          ))}
        </div>
      </CpRadioField>
    </CpWell>
  );
}
