import React, { useState } from "react";
import useContactQuery from "src/common/queries/use-contact-query.hook";
import { TContact } from "src/common/types";
import { CreateEditContact } from "./create-edit-contact/create-edit-contact.component";
import { ViewContact } from "./view-contact/view-contact.component";

export type TContactModalMode = "view" | "edit" | "create";
export type ContactModalContentProps = {
  contactId?: string;
  placeholderContact?: Omit<TContact, "id">;
  focusField?: string;
  onContactSave?: (contactResponse: any) => void;
  closeModal?: () => void; // should be provided if not using modalService
  onSubmit?: () => void; // this comes from modalService
  closeAfterSave?: boolean;
  showAssignedClients?: boolean;
  disableAllLinks?: boolean;
} & ModeProp;

type ModeProp =
  | {
      mode: "view";
      /** Disables the edit button on the view modal even if the user has permission */
      disableEdit?: boolean;
    }
  | {
      mode: TContactModalMode;
      disableEdit?: never;
    };

export function ContactModalContent({
  contactId: initialContactId,
  placeholderContact,
  focusField,
  mode: initialMode = "view",
  disableEdit,
  disableAllLinks = false,
  onContactSave,
  closeModal,
  onSubmit,
  closeAfterSave,
  showAssignedClients = true,
}: ContactModalContentProps) {
  const [mode, setMode] = useState(initialMode);
  const [contactId, setContactId] = useState(initialContactId);

  const { contact } = useContactQuery(
    { contactId: contactId || "", placeholderContact },
    {
      enabled: mode === "view" || mode === "edit",
    }
  );
  const close = () => {
    closeModal?.();
    onSubmit?.();
  };

  return (
    <>
      {mode === "edit" || mode === "create" ? (
        <CreateEditContact
          contact={contact}
          contactId={contactId}
          close={close}
          mode={mode}
          setMode={setMode}
          focusField={focusField}
          onContactSave={(res) => {
            onContactSave?.(res);
            setContactId(res.contact.id);
          }}
          closeAfterSave={closeAfterSave}
          showAssignedClients={showAssignedClients}
        />
      ) : (
        <ViewContact
          contact={contact}
          setMode={setMode}
          close={close}
          disableEdit={disableEdit}
          disableAllLinks={disableAllLinks}
        />
      )}
    </>
  );
}
