import React, { ReactNode, useMemo, useState } from "react";
import { ActionItemList } from "src/common/client-contact/action-item-list";
import { ArchiveContactModal } from "./archive-contact-modal";
import { handleError } from "src/error";
import { contactQueries } from "src/queries";
import { MergeContacts } from "./merge-contacts/merge-contacts.component";
import { Selection } from "./contact-list.component";
import { featureEnabled } from "feature-toggles!sofe";

export type BulkAction = {
  id: string;
  label: string;
  icon: string;
  iconRight?: string | React.ReactNode;
  permissions: string[];
  disabledCheck?: (selection: Selection) => boolean;
  disabledLabel?: string | React.ReactNode;
  minSelection?: number;
  execute?: (props: { search: string; filters: any; selection: Selection }) => void;
  renderModal?: (props: { search: string; filters: any; selection: Selection; closeModal: () => void }) => ReactNode;
};
const bulkActions: BulkAction[] = [
  {
    id: "email",
    label: "Email",
    icon: "communication-envelope",
    permissions: ["clients_bulk_email"],
    execute: ({ search, filters, selection }: { search: string; filters: any; selection: Selection }) => {
      const params = {
        crm_type: "contacts",
        v2Filters: { [selection.type]: selection.toArray(), filters, search },
      };
      SystemJS.import("communications-ui!sofe")
        .then((communicationsUI: any) => {
          communicationsUI.sendBulkEmail(params).subscribe((emailAction: any) => {
            if (emailAction.updatedContacts) {
              contactQueries.invalidate();
            }
          }, handleError);
        })
        .catch(handleError);
    },
  },
  ...(featureEnabled("toggle_merge_contacts")
    ? [
        {
          id: "merge",
          label: "Merge contacts",
          icon: "af-merge",
          permissions: [], // toggle_merge_contacts TODO: add permissions when ready
          disabledCheck: (selection: Selection) => selection.totalSelected > 10,
          disabledLabel: "Unable to merge more than 10 contacts",

          minSelection: 2,
          renderModal: ({ selection, closeModal }: { selection: Selection; closeModal: () => void }) => {
            return <MergeContacts selection={selection} onClose={closeModal} />;
          },
        },
      ]
    : []),
  {
    id: "archive",
    label: "Archive",
    icon: "crud-archive",
    permissions: ["contacts_archive"],
    renderModal: ({
      search,
      filters,
      selection,
      closeModal,
    }: {
      search: string;
      filters: any;
      selection: Selection;
      closeModal: () => void;
    }) => {
      return <ArchiveContactModal search={search} filters={filters} selection={selection} onAfterClose={closeModal} />;
    },
  },
];

type BulkActionsProps = {
  allowedActions?: string[];
  filters: any;
  selection: Selection;
  search: string;
};

export function BulkActions({ allowedActions, filters, search, selection }: BulkActionsProps) {
  const filteredActions = useMemo(() => {
    return bulkActions.filter((actionItem) => {
      const hasMinSelection = !!actionItem.minSelection ? selection.totalSelected >= actionItem.minSelection : true;
      const isAllowed = !!allowedActions ? allowedActions?.includes(actionItem.id) : true;
      return isAllowed && hasMinSelection;
    });
  }, [allowedActions, selection]);
  const [renderModal, setRenderModal] = useState<() => ReactNode>();
  return (
    <>
      <ActionItemList
        actionItems={filteredActions}
        onActionClick={(actionItem) => {
          if (actionItem.execute) {
            actionItem.execute({ search, filters, selection });
          }

          if (actionItem.renderModal) {
            setRenderModal(
              () => () =>
                actionItem.renderModal?.({ search, filters, selection, closeModal: () => setRenderModal(undefined) })
            );
          }
        }}
        selection={selection}
      />
      {renderModal?.()}
    </>
  );
}
