import { genQueryKey, useQuery } from '../../react-query';
import { getRoles } from 'src/resources/utils.resource';

export function useRoles(showRoles=true) {
  const { data: roles=[], isLoading, error } = useQuery({
    queryKey: genQueryKey("roles"),
    queryFn: () => getRoles().toPromise(),
    staleTime: 5 * 60 * 1000,
    enabled: showRoles,
  })

  return { roles, isLoading, error };
}