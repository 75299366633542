import React, { useState } from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { TContact } from "src/common/types";
import { tw } from "src/tailwind";
import { ContactRadioWell } from "./contact-radio-well.component";
import { useForm, FormProvider } from "react-hook-form";
import { CP_CONFLICT_MESSAGE, QBO_CONFLICT_MESSAGE } from "../merge-contacts.helpers";

export type MergeConflictModalReturnData = {
  contactIdsToExclude: string[];
};
type MergeConflictModalProps = {
  cpConflictContacts: TContact[];
  qboConflictContacts: TContact[];
  onClose: (returnData?: MergeConflictModalReturnData) => void;
};

export function MergeConflictModal({ cpConflictContacts, qboConflictContacts, onClose }: MergeConflictModalProps) {
  const [show, setShow] = useState(true);
  const closeModal = () => setShow(false);
  const onCancel = () => closeModal();

  const [hasCPConflict, setHasCPConflict] = useState(cpConflictContacts.length > 0);
  const hasQboConflict = qboConflictContacts.length > 0;

  const [conflictsToResolve, setConflictsToResolve] = useState<TContact[]>(
    cpConflictContacts.length ? cpConflictContacts : qboConflictContacts
  );

  // always resolve the cp conflict first
  const conflictType = hasCPConflict ? "cp" : "qbo";

  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  const onContinue = handleSubmit((formData) => {
    const cpSelectedContactId = formData.cp;
    const cpContactIdsToExclude = cpConflictContacts
      .filter((contact) => contact.id !== cpSelectedContactId)
      .map((contact) => contact.id);

    if (conflictType === "cp" && hasQboConflict) {
      const qboContactsAfterExclusion = qboConflictContacts.filter(
        (contact) => !cpContactIdsToExclude.includes(contact.id)
      );
      if (qboContactsAfterExclusion.length > 1) {
        // mark cp conflict as resolved and continue to resolve qbo conflict
        setConflictsToResolve(qboContactsAfterExclusion);
        setHasCPConflict(false);
        return;
      }
    }

    const qboSelectedContactId = formData.qbo;
    const qboContactsIdsToExclude = qboConflictContacts
      .filter((contact) => contact.id !== qboSelectedContactId)
      .map((contact) => contact.id);

    const contactIdsToExclude = [...cpContactIdsToExclude, ...qboContactsIdsToExclude];

    onClose({ contactIdsToExclude });
  });

  return (
    <CpModal show={show} onClose={closeModal} onAfterClose={onClose} width={600}>
      <CpModal.Header title="Merge Conflicts Detected" />
      <CpModal.Body className={`cp-body ${tw("flex flex-col gap-4")}`}>
        <div>{conflictType === "cp" ? CP_CONFLICT_MESSAGE : QBO_CONFLICT_MESSAGE}</div>
        <FormProvider {...formMethods}>
          <ContactRadioWell contacts={conflictsToResolve} conflictType={conflictType} />
        </FormProvider>
      </CpModal.Body>
      <CpModal.Footer className={tw("flex gap-2")}>
        <CpButton btnType="primary" onClick={onContinue}>
          Continue
        </CpButton>
        <CpButton btnType="flat" onClick={onCancel}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
