import React from "react";
import { tw } from "src/tailwind";
import { TContact } from "src/common/types";

export function ContactTileData({ contact }: { contact: TContact }) {
  const email = contact.primary_email || "—";
  const phone = contact.primary_phone || "—";
  const address = contact.primary_address?.value;
  const hasAddress =
    address?.address_1 || address?.address_2 || address?.locality || address?.region || address?.postal_code;

  return (
    <div className={tw("flex flex-col gap-[1.6rem]")}>
      <div className={tw("flex flex-col")}>
        <div className={`cp-caption cp-color-app-secondary-text ${tw("truncate pb-[0.4rem]")}`}>Email</div>
        <div className={`cp-body-sm ${tw("truncate")}`}>{email}</div>
      </div>
      <div className={tw("flex flex-col")}>
        <div className={`cp-caption cp-color-app-secondary-text ${tw("truncate pb-[0.4rem]")}`}>Phone</div>
        <div className={`cp-body-sm ${tw("truncate")}`}>{phone}</div>
      </div>
      <div className={tw("flex flex-col")}>
        <div className={`cp-caption cp-color-app-secondary-text ${tw("truncate pb-[0.4rem]")}`}>Address</div>
        {hasAddress ? (
          <>
            <div className={`cp-body-sm ${tw("truncate")}`}>{address?.address_1}</div>
            <div className={`cp-body-sm ${tw("truncate")}`}>{address?.address_2}</div>
            <div className={`cp-body-sm ${tw("truncate")}`}>
              {address?.locality}
              {address?.region && `, ${address.region}`}
              {address?.postal_code && `, ${address.postal_code}`}
            </div>
            <div className={`cp-body-sm ${tw("truncate")}`}>{address?.country}</div>
          </>
        ) : (
          <div className="cp-body-sm">—</div>
        )}
      </div>
    </div>
  );
}
