import React from "react";
import Disposable from "react-disposable-decorator";
import { Scoped, k } from "kremling";
import {
  CpButton,
  CpLoader,
  CpEmptyState,
  CpOverlay,
  CpSelectSingle,
} from "canopy-styleguide!sofe";
import { handleError } from "src/handle-error";
import { infoToast, warningToast, successToast } from "toast-service!sofe";
import { isEmpty, get } from "lodash";
import { onPusher } from "fetcher!sofe";
import {
  isPaidClientLimitModelTier,
  UserTenantProps,
} from "cp-client-auth!sofe";

import {
  startClientsSync,
  getSyncPreview,
  prepareClientSync,
  getIntegrationStatus,
  getClientsUsage,
  getSubscriptionDetail,
} from "../../integrations.resource";
import ClientDuplicatesSection from "./sections/clients-duplicates-section.component";
import ClientUnmatchedCanopySection from "./sections/clients-unmatched-canopy-section.component";
import ClientMatchesSection from "./sections/clients-matches-section.component";
import NewClientsSection from "./sections/clients-new-section.component";
import PreviewNav from "./preview-nav.component";
import {
  findSectionsWithData,
  getInstructionPageObjects,
  getActiveSection,
  sections,
  getInactiveQboClientCount,
  getAllSectionsVisited,
  getLastSection,
  getPaginator,
} from "./sync-preview.helpers";
import Instructions from "./instructions.component";
import ClientLimitInfo from "../../common/client-limit-info.component";
import PurchaseClientsModal from "../../common/purchase-client-modal.component";

@Disposable
@UserTenantProps({
  permissions: {
    hasCanopyAdminTranscripts: "CANOPY_ADMIN_TRANSCRIPTS",
  },
  waitForData: true,
})
export default class ClientsPreview extends React.Component {
  state = {
    activeSection: sections.CLIENTS_INSTRUCTIONS,
    loading: false,
    syncing: false,
    clientPreviewData: {
      duplicates: [],
      unmatchedCanopy: [],
      matches: [],
      newClients: [],
      inactiveClientsCount: 0,
    },
    sectionsVisited: [],
    sectionsWithData: [],
    needToLogin: false,
    clientLimit: 20,
    clientsUsed: 0,
    showPurchaseClientsModal: false,
    clientInfoLoading: true,
    importFilter: { id: "active", name: "Active clients only" },
    paginatedData: [],
    syncUnmatchedThirdPartyClients: true,
    overriddenSyncId: null,
  };

  componentDidMount() {
    if (!this.props.hasClientsPermissions) {
      this.setState({
        activeSection: sections.CLIENTS_PERMISSION_INSTRUCTIONS,
      });
    } else {
      this.props.cancelWhenUnmounted(
        getSubscriptionDetail().subscribe(
          () => this.getClientUsageInfo(),
          (err) => {
            this.getClientUsageInfo();
            handleError(err);
          },
        ),
      );
    }
  }

  getClientUsageInfo = () => {
    this.props.cancelWhenUnmounted(
      getClientsUsage().subscribe(
        (res) =>
          this.setState({
            clientLimit: res.allowed,
            clientsUsed: res.used,
            clientInfoLoading: false,
          }),
        (err) => {
          this.setState({
            clientInfoLoading: false,
          });
          handleError(err);
        },
      ),
    );
  };

  prepareClientSync = () => {
    this.props.cancelWhenUnmounted(
      prepareClientSync(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.state.importFilter.id,
      ).subscribe(
        (data) => {
          if (get(data, "need_to_login")) {
            this.setState({
              needToLogin: true,
              loading: false,
              activeSection: "",
            });
          } else {
            this.getSyncPreviewData(data.id);
            this.props.setNewClientId(data.id);
          }
        },
        (err) => {
          if (get(err, "need_to_login")) {
            this.setState({
              needToLogin: true,
              loading: false,
              activeSection: "",
            });
          } else {
            handleError(err);
          }
        },
      ),
    );
  };

  // listening while client sync data is being prepared
  clientsPreviewTickle = (newclientSyncId) => {
    // if the tickle hasn't returned after 15 seconds then try to get the preview data again
    const timeout = setTimeout(() => {
      if (clientPreviewTickle) {
        clientPreviewTickle.unsubscribe();
      }
      this.getSyncPreviewData(newclientSyncId);
    }, 15000);

    const clientPreviewTickle = onPusher("clients-preview").subscribe(
      (data) => {
        if (data?.id) {
          clearTimeout(timeout);
          this.getSyncPreviewData(data.id);
          this.props.setNewClientId(data.id);
        }
      },
      (err) => {
        clearTimeout(timeout);
        handleError(err);
      },
    );

    this.props.cancelWhenUnmounted(clientPreviewTickle);
  };

  handleImageClick = () => {
    const syncId = prompt("Please enter the sync ID to override:");
    if (syncId) {
      this.setState({ overriddenSyncId: syncId });
    }
  };

  getSyncPreviewData = (syncId) => {
    const { overriddenSyncId } = this.state;
    const idToUse = overriddenSyncId || syncId;

    this.props.cancelWhenUnmounted(
      getSyncPreview(this.props.qboIntegrationInfo.id, idToUse).subscribe(
        (previewData) => {
          if (previewData.status === "ERROR_PREPARING") {
            warningToast({
              message: "An issue occurred while preparing QBO data.",
              actionText: "Try again",
              actionCallback: () =>
                this.props.setShowSyncPreview({
                  showSyncPreview: true,
                }),
            });
            return this.props.setShowSyncPreview({
              showSyncPreview: false,
            });
          } else if (previewData.status === "PREPARING") {
            // if the data isn't ready yet then start the tickle
            return this.clientsPreviewTickle(syncId);
          }

          let clientPreviewData = this.state.clientPreviewData;

          clientPreviewData = {
            duplicates: previewData?.duplicates || [],
            unmatchedCanopy: previewData?.unmatched_canopy || [],
            matches: previewData?.to_match_details || [],
            newClients: previewData?.to_create_in_canopy || [],
            inactiveClientsCount: getInactiveQboClientCount(
              previewData?.to_create_in_canopy,
            ),
          };

          const { activeSection, sectionsVisited, sectionsWithData } =
            findSectionsWithData(clientPreviewData);

          this.setState({
            clientPreviewData,
            loading: false,
            activeSection,
            sectionsVisited,
            sectionsWithData,
          });
        },
        handleError,
      ),
    );
  };

  startClientsSync = () => {
    this.setState({ loading: true, syncing: true });

    this.props.cancelWhenUnmounted(
      startClientsSync(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.props.newClientId,
        this.state.syncUnmatchedThirdPartyClients,
      ).subscribe(() => {
        this.getClientSyncStatus();
      }, handleError),
    );
  };

  getClientSyncStatus = () => {
    this.props.cancelWhenUnmounted(
      getIntegrationStatus(
        this.props.qboIntegrationInfo.id,
        this.props.qboIntegrationInfo.type,
        this.props.newClientId,
      ).subscribe((data) => {
        if (data.status === "IN_PROGRESS") {
          return this.clientsSyncedTickle();
        } else if (data.status === "SUCCESS") {
          this.props.setClientSyncId(this.props.newClientId);

          successToast("QuickBooks Online clients successfully synced!");
        } else if (data.status === "PARTIAL_SUCCESS") {
          this.props.setClientSyncId(this.props.newClientId);

          infoToast({
            message:
              "An issue occurred while syncing and some clients may be affected.",
            actionText: " See integration report",
            actionCallback: () =>
              this.props.setShowSyncPreview({
                showSyncPreview: true,
                syncPreviewReadOnly: true,
              }),
          });
        } else if (data.status === "FATAL_ERROR_SYNCING") {
          this.props.setShowSyncPreview({
            showSyncPreview: false,
          });

          warningToast({
            message: "An issue occurred while syncing.",
            actionText: "Try again",
            actionCallback: () =>
              this.props.setShowSyncPreview({
                showSyncPreview: true,
              }),
          });

          return this.props.setShowSyncPreview({
            showSyncPreview: false,
          });
        }

        this.props.setNewClientId();
        this.setState({
          sectionsVisited: [],
          sectionsWithData: [],
          activeSection: sections.CLIENTS_CONFIRMATION,
          loading: false,
          syncing: false,
        });
      }, handleError),
    );
  };

  // listening to when client sync is done
  clientsSyncedTickle = () => {
    // if the tickle hasn't returned after 15 seconds then try to get sync status again
    const timeout = setTimeout(() => {
      if (clientSyncTickle) {
        clientSyncTickle.unsubscribe();
      }
      this.getClientSyncStatus();
    }, 15000);

    const clientSyncTickle = onPusher("clients-synced").subscribe(
      () => {
        clearTimeout(timeout);
        this.getClientSyncStatus();
      },
      (err) => {
        clearTimeout(timeout);
        handleError(err);
      },
    );
  };

  setActiveSection = (activeSection) => {
    const visitedPreviously = this.state.sectionsVisited.find(
      (section) => section === activeSection,
    );
    if (this.state.activeSection === activeSection) {
      return;
    } else if (visitedPreviously) {
      this.setState({ activeSection, paginatedData: [] });
    } else {
      this.setState((prevState) => {
        return {
          activeSection,
          paginatedData: [],
          sectionsVisited: [...prevState.sectionsVisited, activeSection],
        };
      });
    }
  };

  nextSection = (sectionsWithData, currentSection) => {
    const currentIndex = sectionsWithData.findIndex(
      (section) => section === currentSection,
    );
    this.setActiveSection(sectionsWithData[currentIndex + 1]);
  };

  renderInstructionsComponent = () => {
    const {
      clientsConfirmationObject,
      clientsOnlyConfirmationObject,
      clientsInstructionsObject,
      permissionsInstructionsObject,
    } = getInstructionPageObjects(
      this.state.activeSection,
      this.props.newCrmEnabled,
    );

    const { showClientsInstructions, showClientsConfirmation } =
      getActiveSection(this.state.activeSection);

    let instructions = {};
    if (showClientsInstructions) {
      instructions = clientsInstructionsObject;
      if (this.props.permissions.hasCanopyAdminTranscripts) {
        instructions.onImageClick = this.handleImageClick;
      }
    } else if (showClientsConfirmation) {
      if (this.props.hasInvoicePermissions) {
        instructions = clientsConfirmationObject;
      } else {
        instructions = clientsOnlyConfirmationObject;
      }
    } else {
      instructions = permissionsInstructionsObject;
    }

    return <Instructions {...instructions} />;
  };

  getNavButtonText = (
    showInstructions,
    allSectionsVisited,
    lastSection,
    activeSection,
    showClientsConfirmation,
    clientLimitExceeded,
  ) => {
    if (showInstructions && !showClientsConfirmation) {
      return "Start";
    } else if (
      (allSectionsVisited || activeSection === sections.NEW_CLIENTS) &&
      clientLimitExceeded &&
      isPaidClientLimitModelTier(this.state.clientLimit)
    ) {
      return "Purchase clients";
    } else if (
      (allSectionsVisited || activeSection === sections.NEW_CLIENTS) &&
      clientLimitExceeded
    ) {
      return "Upgrade plan";
    } else if (allSectionsVisited || lastSection === activeSection) {
      return "Sync clients";
    } else {
      if (
        activeSection === sections.CLIENTS_CONFIRMATION &&
        !this.props.hasInvoicePermissions
      ) {
        return "Finish";
      }
      return "Next";
    }
  };

  getNavBtnClickFunction = (
    showInstructions,
    allSectionsVisited,
    showClientsConfirmation,
    lastSection,
    clientLimitExceeded,
  ) => {
    const { sectionsWithData, activeSection } = this.state;

    if (showInstructions && !showClientsConfirmation) {
      this.setState({ loading: true, activeSection: "" });

      return this.prepareClientSync();
    } else if (
      (allSectionsVisited || lastSection === activeSection) &&
      clientLimitExceeded
    ) {
      this.setState({ showPurchaseClientsModal: true });
    } else if (allSectionsVisited) {
      return this.startClientsSync();
    } else if (showClientsConfirmation) {
      this.props.setShowServiceItemsPreview();
    } else {
      return this.nextSection(sectionsWithData, activeSection);
    }
  };

  render() {
    const {
      activeSection,
      loading,
      syncing,
      clientPreviewData,
      sectionsVisited,
      sectionsWithData,
      needToLogin,
      clientsUsed,
      clientLimit,
      showPurchaseClientsModal,
      clientInfoLoading,
      paginatedData,
      syncUnmatchedThirdPartyClients,
    } = this.state;

    const {
      showClientsInstructions,
      showClientsConfirmation,
      duplicatesInView,
      unmatchedCanopyInView,
      matchesInView,
      newClientsInView,
      showPermissionInstructions,
    } = getActiveSection(activeSection);

    const newClientsCount = syncUnmatchedThirdPartyClients
      ? get(clientPreviewData, "newClients", []).length
      : 0;
    const inactiveClientCount = syncUnmatchedThirdPartyClients
      ? clientPreviewData.inactiveClientsCount
      : 0;

    // A client limit of 0 indicates unlimited clients
    const clientLimitExceededBy =
      clientLimit === 0
        ? 0
        : clientsUsed + (newClientsCount - inactiveClientCount) - clientLimit;

    const showInstructions =
      showClientsInstructions ||
      showPermissionInstructions ||
      showClientsConfirmation;
    const showEmptyState = !showInstructions && isEmpty(sectionsWithData);
    const allSectionsVisited = getAllSectionsVisited(
      sectionsWithData,
      sectionsVisited,
    );
    const lastSection = getLastSection(clientPreviewData);

    return (
      <>
        <CpOverlay.Header title="Sync Clients">
          <Scoped css={css}>
            {!showEmptyState && !showPermissionInstructions && (
              <>
                {clientLimitExceededBy > 0 &&
                  (allSectionsVisited ||
                    activeSection === sections.NEW_CLIENTS) && (
                    <div
                      className="cp-color-app-warning-text"
                      style={{ float: "left", margin: "0.8rem 1.6rem" }}
                    >
                      *You will exceed your client limit by{" "}
                      <strong>{clientLimitExceededBy}</strong> client(s). To
                      continue you need to
                      {isPaidClientLimitModelTier(clientLimit)
                        ? " purchase more clients."
                        : " upgrade your plan."}
                    </div>
                  )}

                {!showClientsInstructions && (
                  <CpButton
                    btnType="primary"
                    onClick={() =>
                      this.getNavBtnClickFunction(
                        showInstructions,
                        allSectionsVisited,
                        showClientsConfirmation,
                        lastSection,
                        clientLimitExceededBy > 0,
                      )
                    }
                    disabled={
                      (!showInstructions &&
                        !allSectionsVisited &&
                        lastSection === activeSection &&
                        clientLimitExceededBy <= 0) ||
                      loading
                    }
                  >
                    {this.getNavButtonText(
                      showInstructions,
                      allSectionsVisited,
                      lastSection,
                      activeSection,
                      showClientsConfirmation,
                      clientLimitExceededBy > 0,
                      clientLimit,
                    )}
                  </CpButton>
                )}
                {showClientsInstructions && (
                  <div className="cp-flex">
                    <div className="cp-mr-16 cp-flex-center">
                      <div>Sync</div>
                      <CpSelectSingle
                        data={[
                          { id: "active", name: "Active clients only" },
                          { id: "inactive", name: "Inactive clients only" },
                          {
                            id: "all",
                            name: "All clients (inactive and active)",
                          },
                        ]}
                        className="cp-ml-8"
                        contentWidth="md"
                        placeholder="Select Person"
                        onChange={(importFilter) =>
                          this.setState({ importFilter })
                        }
                        value={this.state.importFilter}
                      />
                    </div>
                    <CpButton
                      onClick={() => {
                        this.setState({ loading: true, activeSection: "" });
                        this.prepareClientSync();
                      }}
                    >
                      Start
                    </CpButton>
                  </div>
                )}
              </>
            )}
          </Scoped>
        </CpOverlay.Header>
        <CpOverlay.Body>
          <Scoped css={css}>
            {showInstructions ? (
              this.renderInstructionsComponent()
            ) : !loading && !showEmptyState ? (
              <div className="sync-preview">
                <div className="left-nav-container">
                  <PreviewNav
                    syncPreviewData={clientPreviewData}
                    duplicatesInView={duplicatesInView}
                    unmatchedCanopyInView={unmatchedCanopyInView}
                    matchesInView={matchesInView}
                    newClientsInView={newClientsInView}
                    setActiveSection={this.setActiveSection}
                    sectionsVisited={sectionsVisited}
                    isActiveBox
                    isClients
                  />
                  {clientLimit > 0 && (
                    <ClientLimitInfo
                      clientsUsed={clientsUsed}
                      clientLimit={clientLimit}
                      qboActiveClients={newClientsCount - inactiveClientCount}
                      inactiveClients={inactiveClientCount}
                      showPurchaseClientModal={() =>
                        this.setState({ showPurchaseClientsModal: true })
                      }
                      loading={clientInfoLoading}
                    />
                  )}
                </div>
                <div className="right-side-container">
                  {duplicatesInView && (
                    <ClientDuplicatesSection
                      duplicates={paginatedData}
                      newCrmEnabled={this.props.newCrmEnabled}
                    />
                  )}
                  {unmatchedCanopyInView && (
                    <ClientUnmatchedCanopySection
                      unmatchedCanopyClients={paginatedData}
                      newCrmEnabled={this.props.newCrmEnabled}
                    />
                  )}
                  {matchesInView && (
                    <ClientMatchesSection
                      matches={paginatedData}
                      newCrmEnabled={this.props.newCrmEnabled}
                    />
                  )}
                  {newClientsInView && (
                    <NewClientsSection
                      newClients={paginatedData}
                      newClientsAmount={
                        clientPreviewData?.newClients?.length || 0
                      }
                      syncUnmatchedThirdPartyClients={
                        this.state.syncUnmatchedThirdPartyClients
                      }
                      setSyncUnmatchedThirdPartyClients={(
                        syncUnmatchedThirdPartyClients,
                      ) => this.setState({ syncUnmatchedThirdPartyClients })}
                      newCrmEnabled={this.props.newCrmEnabled}
                    />
                  )}
                </div>
              </div>
            ) : showEmptyState && !loading ? (
              <div className="sync-preview">
                <CpEmptyState
                  img={needToLogin ? "es_caution" : "es_clients"}
                  text={
                    needToLogin
                      ? "Your QuickBooks Online login has expired, please disconnect and connect again"
                      : "No client data available for syncing"
                  }
                />
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                {syncing ? "Syncing clients" : "Getting clients from QBO"}
                <CpLoader />
              </div>
            )}
            <PurchaseClientsModal
              show={showPurchaseClientsModal}
              close={() => this.setState({ showPurchaseClientsModal: false })}
              clientLimit={clientLimit}
            />
          </Scoped>
        </CpOverlay.Body>
        <div
          style={{
            marginBottom: "1.6rem",
            marginTop: "0.8rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!syncing &&
            getPaginator({
              sectionsInView: {
                duplicatesInView,
                unmatchedCanopyInView,
                matchesInView,
                newClientsInView,
              },
              setPaginatedData: (paginatedData) =>
                this.setState({ paginatedData }),
              previewData: clientPreviewData,
            })}
        </div>
      </>
    );
  }
}

const css = k`
  .sync-preview {
    display: flex;
  }

  .left-nav-container {
    margin-left: 1.6rem;
    position: fixed;
  }

  .right-side-container {
    margin-left: 28.8rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
