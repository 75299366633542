import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { MergeContactsParams, mergeContacts } from "src/resources/contacts.resource";

export const contactMergeQueries = genQueries("contact-merge", ({ genKey }) => ({
  previewMerge: (params: Omit<MergeContactsParams, "action">) => {
    const { main_contact_id, contact_ids } = params;
    return queryOptions<any>({
      queryKey: genKey("previewMerge", params),
      queryFn: () => mergeContacts({ action: "preview", main_contact_id, contact_ids }),
      staleTime: 60_000, // 1 min
    });
  },
}));
