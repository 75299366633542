import React, { useState } from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { tw } from "src/tailwind";

type NoMergePossibleModalProps = {
  onClose: () => void;
};

export function NoMergePossibleModal({ onClose }: NoMergePossibleModalProps) {
  const [show, setShow] = useState(true);
  const closeModal = () => setShow(false);

  return (
    <CpModal show={show} onClose={closeModal} onAfterClose={onClose} width={600}>
      <CpModal.Header title="Merge Conflicts Detected" />
      <CpModal.Body className={tw("cp-body")}>
        The selected contacts either are all synced to QBO or all have client portal logins. We are unable to merge
        contacts with these parameters. Please return to the contact list and make another selection of contacts to
        merge.
      </CpModal.Body>
      <CpModal.Footer className={tw("flex gap-2")}>
        <CpButton onClick={closeModal} btnType="primary">
          Select other contacts
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
