// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-cell-styles__selectStatus--t9dI9 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: 28px;
  background-color: var(--cp-color-well-l1-bg);
  border: 1px solid var(--cp-color-well-border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  white-space: nowrap;
}

.status-cell-styles__statusDot--V4DYY {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/dashboard/table/status-cell.styles.css"],"names":[],"mappings":"AAAA;EACE,8BAAsB;EAAtB,2BAAsB;EAAtB,sBAAsB;EACtB,YAAY;EACZ,4CAA4C;EAC5C,6CAA6C;EAC7C,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".selectStatus {\n  max-width: fit-content;\n  height: 28px;\n  background-color: var(--cp-color-well-l1-bg);\n  border: 1px solid var(--cp-color-well-border);\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  padding: 0 12px;\n  white-space: nowrap;\n}\n\n.statusDot {\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectStatus": `status-cell-styles__selectStatus--t9dI9`,
	"statusDot": `status-cell-styles__statusDot--V4DYY`
};
export default ___CSS_LOADER_EXPORT___;
