import { decimalNumberFilter } from 'src/billing-helpers';
import { InvoiceLineItemType } from '../invoice-editor.types';

export const removeCommas = (value: string | number) => {
  const num = Number(value.toString().replace(/,/g, ''));
  return isNaN(num) ? 0 : num;
};

export const roundToHundredth = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const calculateAmount = (lineItem: InvoiceLineItemType) => {
  const { quantity = 0, rate_amount = 0, sub_items } = lineItem;
  const amount = !!sub_items?.length
    ? sub_items.reduce((acc, subItem) => acc + +(subItem?.quantity ?? 0) * +(subItem?.rate_amount ?? 0), 0)
    : +quantity * +rate_amount;

  let rounded = Math.round(amount * 1000) / 1000;

  if (amount.toFixed(3) != rounded.toFixed(3)) {
    rounded = Math.ceil(amount);
  }

  return roundToHundredth(rounded);
};

export const calculateLineTotal = (lineItem: InvoiceLineItemType) => {
  const {
    quantity = 0,
    rate_amount = 0,
    manual_adjustment_amount = 0,
    discount_is_percent,
    discount_amount = 0,
    discount_percent = 0,
    sub_items,
  } = lineItem;
  const subtotal = !!sub_items?.length
    ? sub_items.reduce(
        (acc, subItem) =>
          acc +
          (+(subItem?.quantity ?? 0) * +(subItem?.rate_amount ?? 0) +
            removeCommas(subItem?.manual_adjustment_amount ?? 0)),
        0
      )
    : +quantity * +rate_amount + +removeCommas(manual_adjustment_amount);
  const totalDiscount = discount_is_percent
    ? +subtotal * (Number(discount_percent) / 100)
    : Number(discount_amount) ?? 0;
  const total = roundToHundredth(Number(subtotal) - Number(totalDiscount));
  return decimalNumberFilter(total, 2, false, true);
};
