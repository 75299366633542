import React, { useMemo } from "react";
import { CpButton, CpIcon, CpDropdown } from "../";
import { range } from "lodash";
import { Crumb } from "./crumb.component";
import { Icons } from "../../types/icon";

const maxVisible = 3;

export type CpBreadcrumbsProps = {
  breadcrumbs: Array<SingleCrumb>;
  currentCrumb?: SingleCrumb | null;
  linkStyle?: boolean;
  onClick: (crumb: SingleCrumb) => void;
  disableCrumbs?: boolean;
};

export type SingleCrumb = {
  id: string;
  name: string;
  icon?: Icons;
};

export const CpBreadcrumbs = ({
  breadcrumbs = [],
  currentCrumb = null,
  linkStyle = false,
  onClick = () => {},
  disableCrumbs = false,
}: CpBreadcrumbsProps) => {
  const icon = linkStyle ? "caret-small-right" : "caret-large-right";

  const activeCrumb = currentCrumb
    ? breadcrumbs.findIndex((crumb) => crumb?.id === currentCrumb?.id) + 1
    : breadcrumbs?.length;

  const shouldTruncate = activeCrumb > maxVisible;

  const { crumbsIndexesToRender, crumbsIndexesToHide } = useMemo(() => {
    if (!shouldTruncate) {
      //render all the crumbs
      return {
        crumbsIndexesToRender: range(0, activeCrumb),
        crumbsIndexesToHide: [],
      };
    }
    if (activeCrumb > activeCrumb - maxVisible) {
      //render the last three crumbs
      return {
        crumbsIndexesToRender: range(activeCrumb - maxVisible, activeCrumb),
        crumbsIndexesToHide: range(0, activeCrumb - maxVisible),
      };
    }

    return { crumbsIndexesToRender: [], crumbsIndexesToHide: [] };
  }, [shouldTruncate, activeCrumb]);

  return (
    <div className="cp-flex-center min-w-0">
      {shouldTruncate && (
        <CpDropdown
          contentWidth="auto"
          renderTrigger={({ toggle }) => (
            <div className="cp-ml-12 cp-flex-center shrink-0">
              <CpButton
                icon="misc-ellipsis"
                onClick={toggle}
                small={linkStyle}
                disabled={disableCrumbs}
                aria-label="Show hidden"
              />
              <CpIcon name={icon} />
            </div>
          )}
          renderContent={() => (
            <div className="cp-select-list">
              {crumbsIndexesToHide?.map((crumb: number) => (
                <button
                  key={breadcrumbs[crumb]?.id}
                  onClick={() => onClick(breadcrumbs[crumb])}
                >
                  {breadcrumbs[crumb]?.icon && (
                    <CpIcon
                      className="cp-select-list__icon-left"
                      name={breadcrumbs[crumb]?.icon as Icons}
                    />
                  )}
                  {breadcrumbs[crumb].name}
                </button>
              ))}
            </div>
          )}
        />
      )}
      {crumbsIndexesToRender?.map((crumbIndex: number, index: number) => (
        <Crumb
          key={breadcrumbs[crumbIndex]?.id}
          crumb={breadcrumbs[crumbIndex]}
          firstCrumb={index === 0}
          linkStyle={linkStyle}
          onClick={onClick}
          lastCrumb={index === crumbsIndexesToRender.length - 1}
          disableCrumbs={disableCrumbs}
        />
      ))}
    </div>
  );
};
