import React from "react";
import { CpDropdown, CpButton, CpTooltip } from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";
import { infoToast, successToast } from "toast-service!sofe";
import { exportFilterView } from "./export-contact-list.resource";
import { getExportColumns } from "./export-contact-list.helper";
import { downloadText } from "src/common/utils.helpers";
import { FilterViewType, Selection } from "../contact-list.component";
import { handleError } from "src/error";

type ExportContactListButtonProps = {
  filterView: FilterViewType;
  filterViewOverrides: Record<string, any>;
  filters: Record<string, any>;
  sortData: { sort: string }[];
  search: string;
  selection: Selection;
  subView?: {
    name: string;
  };
  schema: Record<string, any>;
};

export function ExportContactListButton({
  filterView,
  filterViewOverrides,
  filters,
  sortData,
  search,
  selection,
  subView,
  schema,
}: ExportContactListButtonProps): React.ReactNode {
  const hasExportAccess = useHasAccess("clients_export");

  function exportCurrentList(includeSelections: boolean) {
    const { closeToast } = infoToast(
      `Exporting contact list: ${subView ? subView.name : filterView.name}. This may take a moment.`
    );
    const override = filterViewOverrides[filterView.id];
    const visibleColumns = override?.visibleColumns || filterView.filter_data.visible_columns;
    const columnOrder = override?.columnOrder || filterView.filter_data.column_order;
    exportFilterView({
      export_columns: getExportColumns({
        exportAll: false,
        visibleColumns,
        columnOrder,
      }).map((col: any) => schema[col].fieldId),
      filters: filters,
      sort_data: sortData.filter((d) => !!d.sort),
      search: search,
      ...(includeSelections
        ? {
            [selection.type]: selection.toArray(),
          }
        : {}),
    }).subscribe((csvText: string) => {
      closeToast();
      downloadText(`CanopyContactsExport - ${subView ? subView.name : filterView.name}.csv`, csvText, "text/csv");
      successToast("Export complete");
    }, handleError);
  }

  if (!hasExportAccess) return null;

  return (
    <CpDropdown
      renderTrigger={({ toggle }: any) => (
        <CpTooltip text="Export current list">
          <CpButton
            icon="af-line-square-up"
            btnType="icon"
            aria-label="Export"
            onClick={selection.totalSelected > 0 ? toggle : () => exportCurrentList(false)}
          />
        </CpTooltip>
      )}
      renderContent={() => (
        <div className="cp-select-list">
          <button onClick={() => exportCurrentList(false)}>Export current list</button>
          <button onClick={() => exportCurrentList(true)}>Export selected contacts</button>
        </div>
      )}
    />
  );
}
