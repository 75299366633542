import { capitalize } from "lodash";
import { CpTable, currency } from "canopy-styleguide!sofe";
import ClientNameCell from "./cells/client-name-cell.component";
import CustomFieldCell from "./cells/custom-field-cell.component";
import QboCell from "./cells/qbo-cell.component";
import BirthdayCell from "./cells/birthday-cell.component";
import RoleCell from "./cells/role-cell.component";
import { fetchSelectionOptions } from "../client-list.resource";
import { MaskedCell } from "src/common/client-contact/masked-cell.component";
import { AssignedContactsCell } from "./cells/assigned-contacts-cell.component";
import { ClientPortalUserInvitedCell } from "./cells/client-portal-user-invited-cell.component";
import DisplayNameCell from "./cells/display-name-cell.component";
import { PhoneNumberCell } from "src/common/client-contact/phone-number-cell.component";
import { featureEnabled } from "feature-toggles!sofe";

const customFieldTypeToFilter = {
  text: CpTable.InputFilter,
  multiselect: CpTable.ConditionalSelectFilter,
  dropdown: CpTable.ConditionalSelectFilter,
  date: CpTable.DateFilter,
};

export function genClientListSchema({ customFields, roles, crmHierarchyEnabled }) {
  const defs = {
    [crmHierarchyEnabled ? "name" : "display_name"]: {
      fieldId: crmHierarchyEnabled ? "name" : "display_name",
      label: crmHierarchyEnabled ? "Client Name" : "Display Name",
      dataType: "string",
      width: "md",
      section: "client",
      filter: {
        props: {
          hideConditionOptions: crmHierarchyEnabled ? ["include_empty"] : [],
        },
      },
      sort: true,
      sticky: crmHierarchyEnabled ? "left" : undefined,
      cell: crmHierarchyEnabled ? { component: DisplayNameCell } : undefined,
    },
    ...(crmHierarchyEnabled
      ? {
          business_name: {
            fieldId: "business_name",
            label: "Business Name",
            dataType: "string",
            width: "md",
            section: "business",
            filter: true,
            sort: true,
          },
        }
      : {}),
    business_type: {
      fieldId: "business_type",
      label: "Business Type",
      dataType: "string",
      width: "md",
      section: "business",
      filter: true,
      sort: true,
    },
    client_owes: {
      fieldId: "client_owes",
      label: "Client Owes",
      dataType: "number",
      width: "md",
      formatValue: (value) => {
        let num = value;
        // Getting string and number values from BE
        if (typeof value === "string") {
          num = parseFloat(value);
        }
        return !num ? null : currency(num);
      },
      section: "firm",
      filter: false,
      sort: false,
    },
    client_owner: {
      fieldId: "client_owner",
      label: "Client Owner",
      dataType: "string",
      width: "md",
      section: "firm",
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            return await fetchSelectionOptions({ field: "_client_owner_id", search });
          },
        },
      },
      sort: false,
    },
    contact_person: {
      fieldId: "contact_person",
      label: "Contact person",
      dataType: "string",
      width: "md",
      section: "business",
      filter: true,
      sort: true,
    },
    client_since: {
      fieldId: "client_since",
      label: "Client Since",
      dataType: "date_unix_ms",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    },
    client_type: {
      fieldId: "client_type",
      label: crmHierarchyEnabled ? "Client Status" : "Client Type",
      dataType: "string",
      width: "md",
      section: "client",
      cell: {
        formatValue: (value) => capitalize(value),
      },
      filter: {
        component: CpTable.SelectFilter,
        props: {
          getSelectionOptions: async () => {
            const results = await fetchSelectionOptions({ field: "client_type" });
            return results.map((r) => ({ id: r.name, name: capitalize(r.name) }));
          },
        },
      },
      sort: false,
    },
    date_established: {
      fieldId: "date_established",
      label: "Date Established",
      dataType: "date_unix_ms",
      width: "md",
      section: "business",
      filter: true,
      sort: true,
    },
    ein: {
      fieldId: "ein",
      label: "EIN",
      dataType: "string",
      width: "md",
      section: "business",
      cell: {
        component: MaskedCell,
      },
      filter: false,
      sort: false,
    },
    external_id: {
      fieldId: "external_id",
      label: "External ID",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    },
    filing_status: {
      fieldId: "filing_status",
      label: "Filing Status",
      dataType: "string",
      width: "md",
      section: "client",
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "filing_status", search });
            return results.map((r) => ({ id: r.name, name: r.name }));
          },
        },
      },
      sort: true,
    },
    industry: {
      fieldId: "industry",
      label: "Industry",
      dataType: "string",
      width: "md",
      section: "business",
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "industry", search });
            return results.map((r) => ({ id: r.name, name: r.name }));
          },
        },
      },
      sort: true,
    },
    is_active: {
      fieldId: "is_active",
      label: "Active",
      dataType: "bool",
      section: "client",
      width: "sm",
      filter: {
        props: {
          trueLabel: "Active",
          falseLabel: "Inactive",
        },
      },
      sort: {
        ascLabel: "Inactive - Active",
        descLabel: "Active - Inactive",
      },
    },
    referred_by: {
      fieldId: "referred_by",
      label: "Referred By",
      dataType: "string",
      width: "md",
      section: "client",
      filter: false,
      sort: false,
    },
    client_source: {
      fieldId: "client_source",
      label: "Source",
      dataType: "string",
      width: "md",
      section: "client",
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "client_sources", search });
            return results.map((r) => ({ id: r.name, name: r.name }));
          },
        },
      },
      sort: false,
    },
    ...(crmHierarchyEnabled
      ? {}
      : {
          spouse_name: {
            fieldId: "spouse_name",
            label: "Spouse Name",
            dataType: "string",
            width: "md",
            section: "client",
            filter: true,
            sort: true,
          },
        }),
    tags: {
      fieldId: "tags",
      label: "Tags",
      dataType: "array",
      width: "md",
      section: "firm",
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "tags", search });
            return results.map((r) => ({ id: r.name, name: r.name }));
          },
        },
      },
      sort: true,
    },
    team_members: {
      fieldId: "team_members",
      label: "Assigned Users",
      dataType: "array",
      width: "md",
      section: "firm",
      cell: {
        formatValue: (value) => {
          if (typeof value === "string") {
            return value;
          } else {
            return value?.map((tm) => tm.profile_name || tm.name) || "";
          }
        },
      },
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "team_members", search });
            return results.map((tm) => ({ id: tm.id, name: tm.profile_name || tm.name }));
          },
        },
      },
      sort: false,
    },
    teams: {
      fieldId: "teams",
      label: "Assigned Teams",
      dataType: "array",
      width: "md",
      section: "firm",
      cell: {
        formatValue: (value) => {
          return value?.map((team) => team.name);
        },
      },
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "teams", search });
            return results.map((t) => ({ id: t.id, name: t.name }));
          },
        },
      },
      sort: false,
    },
    ...(!featureEnabled("toggle_new_client_list_attributes") || !crmHierarchyEnabled
      ? {
          phone: {
            fieldId: "phone",
            label: "Phone",
            dataType: "string",
            width: "md",
            section: "client",
            cell: {
              component: PhoneNumberCell,
            },
            filter: true,
            sort: true,
          },
          email: {
            fieldId: "email",
            label: "Email",
            dataType: "string",
            width: "md",
            section: "client",
            cell: {
              component: CpTable.LinkCell,
              props: ({ value }) => ({
                href: `mailto:${value}`,
                label: value,
              }),
            },
            filter: true,
            sort: true,
          },
          country: {
            fieldId: "country",
            label: "Country",
            dataType: "string",
            width: "md",
            section: "client",
            filter: {
              component: CpTable.SelectFilter,
              props: {
                getSelectionOptions: async () => {
                  const results = await fetchSelectionOptions({ field: "country" });
                  return results.map((r) => ({ id: r.name, name: r.name }));
                },
              },
            },
            sort: true,
          },
          postal_code: {
            fieldId: "postal_code",
            label: "Zip",
            dataType: "string",
            width: "sm",
            section: "client",
            filter: true,
            sort: true,
          },
          locality: {
            fieldId: "locality",
            label: "City",
            dataType: "string",
            width: "md",
            section: "client",
            filter: true,
            sort: true,
          },
          region: {
            fieldId: "region",
            label: "State",
            dataType: "string",
            width: "sm",
            section: "client",
            filter: true,
            sort: true,
          },
          street_address: {
            fieldId: "street_address",
            label: "Street Address",
            dataType: "string",
            width: "md",
            section: "client",
            filter: true,
            sort: true,
          },
        }
      : {}),
    qbo: {
      fieldId: "qbo",
      label: "QBO",
      dataType: "string",
      section: "firm",
      cell: {
        component: QboCell,
      },
      filter: false,
      sort: false,
    },
    created_at: {
      fieldId: "created_at",
      label: "Created On",
      dataType: "date_unix_ms",
      width: "md",
      section: "client",
      filter: {
        component: CpTable.DateFilter,
        props: {
          hideNoDateAdded: true,
        },
      },
      sort: true,
    },
    ...(crmHierarchyEnabled
      ? {
          client_groups: {
            fieldId: "client_groups",
            label: "Client Group",
            dataType: "string",
            section: "client",
            cell: {
              formatValue: (value) => value?.name,
            },
            filter: {
              component: CpTable.ConditionalSelectFilter,
              props: {
                hideConditionOptions: ["is_all_of"],
                getSelectionOptions: async ({ search }) => {
                  const results = await fetchSelectionOptions({ field: "client_groups", search });
                  return results.map((r) => ({ id: r.id, name: r.value }));
                },
              },
            },
            sort: true,
          },
        }
      : {}),
  };

  if (crmHierarchyEnabled) {
    defs.contacts = {
      fieldId: "contacts",
      label: "Contacts",
      dataType: "array",
      width: "md",
      section: "client",
      filter: {
        component: CpTable.SelectFilter,
        props: {
          refetchOnSearch: true,
          getSelectionOptions: async ({ search }) => {
            const results = await fetchSelectionOptions({ field: "contacts", search });
            return results.map((r) => ({ ...r, name: r.name || `${r.first_name} ${r.last_name}` }));
          },
        },
      },
      sort: false,
      cell: {
        component: AssignedContactsCell,
      },
    };
    defs.cp_user_invited = {
      fieldId: "cp_user_invited",
      label: "Client Portal User",
      section: "client",
      dataType: "bool",
      filter: {
        component: CpTable.BoolFilter,
        props: {
          trueLabel: "With users",
          falseLabel: "Without users",
        },
      },
      sort: {
        ascLabel: "Without - With",
        descLabel: "With - Without",
      },
      cell: {
        component: ClientPortalUserInvitedCell,
      },
    };

    // Contact attributes
    if (featureEnabled("toggle_new_client_list_attributes")) {
      defs.primary_contact_name = {
        fieldId: "primary_contact_name",
        label: "Primary Contact Name",
        dataType: "string",
        width: 210,
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_phone = {
        fieldId: "primary_contact_phone",
        label: "Primary Contact Phone",
        dataType: "string",
        width: 208,
        section: "contact",
        cell: {
          component: PhoneNumberCell,
        },
        filter: true,
        sort: true,
      };
      defs.primary_contact_email = {
        fieldId: "primary_contact_email",
        label: "Primary Contact Email",
        dataType: "string",
        width: "md",
        section: "contact",
        cell: {
          component: CpTable.LinkCell,
          props: ({ value }) => ({
            href: `mailto:${value}`,
            label: value,
          }),
        },
        filter: true,
        sort: true,
      };
      defs.primary_contact_street_address = {
        fieldId: "primary_contact_street_address",
        label: "Primary Contact Street Address",
        dataType: "string",
        width: 261,
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_locality = {
        fieldId: "primary_contact_locality",
        label: "Primary Contact City",
        dataType: "string",
        width: "md",
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_region = {
        fieldId: "primary_contact_region",
        label: "Primary Contact State",
        dataType: "string",
        width: "md",
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_country = {
        fieldId: "primary_contact_country",
        label: "Primary Contact Country",
        dataType: "string",
        width: 219,
        section: "contact",
        filter: {
          component: CpTable.SelectFilter,
          props: {
            getSelectionOptions: async () => {
              const results = await fetchSelectionOptions({ field: "primary_contact_country" });
              return results.map((r) => ({ id: r.name, name: r.name }));
            },
          },
        },
        sort: true,
      };
      defs.primary_contact_employer = {
        fieldId: "primary_contact_employer",
        label: "Primary Contact Employer",
        dataType: "string",
        width: 225,
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_occupation = {
        fieldId: "primary_contact_occupation",
        label: "Primary Contact Occupation",
        dataType: "string",
        width: 243,
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_birthday = {
        fieldId: "primary_contact_birthday",
        label: "Primary Contact Birthday",
        dataType: "date_iso",
        width: 222,
        section: "contact",
        filter: {
          component: CpTable.DateFilter,
          props: {
            dateFormat: "MM-dd",
          },
        },
        sort: true,
      };
      defs.primary_contact_tin = {
        fieldId: "primary_contact_tin",
        label: "Primary Contact SSN",
        dataType: "string",
        width: 160,
        section: "contact",
        cell: {
          component: MaskedCell,
        },
        filter: false,
        sort: false,
      };
      defs.spouse_name = {
        fieldId: "spouse_name",
        label: "Spouse Name",
        dataType: "string",
        width: "md",
        section: "contact",
        filter: true,
        sort: true,
      };
      defs.primary_contact_postal_code = {
        fieldId: "primary_contact_postal_code",
        label: "Primary Contact Zip",
        dataType: "string",
        width: "md",
        section: "contact",
        filter: true,
        sort: true,
      };

      // Business attributes
      defs.business_phone = {
        fieldId: "business_phone",
        label: "Business Phone",
        dataType: "string",
        width: "md",
        section: "business",
        cell: {
          component: PhoneNumberCell,
        },
        filter: true,
        sort: true,
      };
      defs.business_email = {
        fieldId: "business_email",
        label: "Business Email",
        dataType: "string",
        width: "md",
        section: "business",
        cell: {
          component: CpTable.LinkCell,
          props: ({ value }) => ({
            href: `mailto:${value}`,
            label: value,
          }),
        },
        filter: true,
        sort: true,
      };
      defs.business_address = {
        fieldId: "business_address",
        label: "Business Street Address",
        dataType: "string",
        width: 218,
        section: "business",
        filter: true,
        sort: true,
      };
      defs.business_locality = {
        fieldId: "business_locality",
        label: "Business City",
        dataType: "string",
        width: "md",
        section: "business",
        filter: true,
        sort: true,
      };
      defs.business_region = {
        fieldId: "business_region",
        label: "Business State",
        dataType: "string",
        width: "md",
        section: "business",
        filter: true,
        sort: true,
      };
      defs.business_postal_code = {
        fieldId: "business_postal_code",
        label: "Business Zip Code",
        dataType: "string",
        width: "md",
        section: "business",
        filter: true,
        sort: true,
      };
      defs.business_country = {
        fieldId: "business_country",
        label: "Business Country",
        dataType: "string",
        width: "md",
        section: "business",
        filter: {
          component: CpTable.SelectFilter,
          props: {
            getSelectionOptions: async () => {
              const results = await fetchSelectionOptions({ field: "business_country" });
              return results.map((r) => ({ id: r.name, name: r.name }));
            },
          },
        },
        sort: true,
      };
    }
  } else {
    defs.full_name = {
      fieldId: "full_name",
      label: "Client Name",
      dataType: "string",
      sticky: "left",
      width: 250,
      filter: {
        props: {
          hideConditionOptions: ["include_empty"],
        },
      },
      sort: true,
      cell: {
        component: ClientNameCell,
      },
    };
    defs.first_name = {
      fieldId: "first_name",
      label: "First Name",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    };
    defs.middle_name = {
      fieldId: "middle_name",
      label: "Middle Name",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    };
    defs.last_name = {
      fieldId: "last_name",
      label: "Last Name",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    };
    defs.birthdate = {
      fieldId: "birthdate",
      label: "Birthday",
      dataType: "date_unix_ms",
      width: "md",
      section: "client",
      cell: {
        component: BirthdayCell,
      },
      filter: {
        component: CpTable.DateFilter,
        props: {
          dateFormat: "MM-dd",
        },
      },
      sort: true,
    };
    defs.ssn = {
      fieldId: "ssn",
      label: "SSN",
      dataType: "string",
      width: "md",
      section: "client",
      cell: {
        component: MaskedCell,
      },
      filter: false,
      sort: false,
    };
    defs.occupation = {
      fieldId: "occupation",
      label: "Occupation",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    };
    defs.employer_name = {
      fieldId: "employer_name",
      label: "Employer",
      dataType: "string",
      width: "md",
      section: "client",
      filter: true,
      sort: true,
    };
  }

  customFields?.forEach((field) => {
    const id = "custom_field_" + field.field_id;
    defs[id] = {
      fieldId: id,
      dataType: "object",
      label: field.field_name,
      width: "md",
      customField: field,
      section: "custom",
      cell: {
        component: CustomFieldCell,
      },
      filter: {
        component: customFieldTypeToFilter[field.field_type],
        props: {
          getSelectionOptions: async () => {
            const results = await fetchSelectionOptions({
              field: "custom_field_" + field.field_type,
              fieldId: field.field_id,
            });
            return results.map((r) => ({ id: r.id, name: r.name }));
          },
        },
      },
      sort: {
        fieldId: "cf_" + field.field_name,
      },
    };
  });

  roles?.forEach((role) => {
    const id = "roles_" + role.id;
    defs[id] = {
      fieldId: id,
      roleId: role.id,
      dataType: "object",
      label: role.label,
      width: "md",
      section: "roles",
      cell: {
        component: RoleCell,
      },
      filter: {
        component: CpTable.ConditionalSelectFilter,
        props: {
          getSelectionOptions: async () => {
            return await fetchSelectionOptions({
              field: "roles",
              fieldId: role.id,
            });
          },
        },
      },
      sort: false,
    };
  });

  return defs;
}
