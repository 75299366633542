import React from "react";
import { CpIcon, CpRadioField, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import { tw } from "src/tailwind";
import { TContact } from "src/common/types";
import { iconData } from "./merge-contacts.helpers";
import { QboIcon } from "src/common/qbo-icon.component";
import { ContactTileData } from "./contact-tile-data.component";
import { renderContactModal } from "src/contact-modal/contact-modal.component";

type ContactTileProps = {
  contact: TContact;
};

export default function ContactTile({ contact }: ContactTileProps) {
  const showCpIcon = contact.cp_user_invited;
  const cpIconData = showCpIcon ? iconData.hasCp : iconData.noCp;
  const showQboIcon = contact.qbo_connected;

  return (
    <CpWell level={2} className={tw("w-[24rem] h-[28.4rem] p-[1.6rem]")}>
      <div className={tw("flex flex-row items-center pb-[0.8rem]")}>
        <CpRadioField.Item id={contact.id} className={tw("flex-none mr-[0.8rem]")} />
        <div
          className={tw(
            "flex-1 flex flex-row justify-between items-center cp-body-sm cp-wt-semibold w-full overflow-hidden"
          )}
        >
          <div className={tw("truncate")}>
            <a
              className="cp-wt-semibold"
              onClick={() => {
                renderContactModal({ contactId: contact.id, mode: "view", disableEdit: true, disableAllLinks: true });
              }}
            >
              {contact.name}
            </a>
          </div>
          <div className={tw("flex-none flex flex-row gap-[0.8rem] pl-[0.8rem]")}>
            <CpTooltip text={cpIconData.tooltip}>
              <CpIcon name={cpIconData.name} fill={cpIconData.fill} />
            </CpTooltip>
            {showQboIcon && (
              <CpTooltip text="QBO sync contact">
                <QboIcon />
              </CpTooltip>
            )}
          </div>
        </div>
      </div>
      <hr className={tw("m-0 mb-[1.6rem]")} />
      <ContactTileData contact={contact} />
    </CpWell>
  );
}
