import React from "react";
import { a, k, useCss } from "kremling";
import { CpButton, CpIcon } from "..";
import { SingleCrumb } from "./cp-breadcrumbs.component";

export type CrumbProps = {
  crumb: SingleCrumb;
  firstCrumb: boolean;
  linkStyle: boolean;
  lastCrumb: boolean;
  onClick: (crumb: SingleCrumb) => void;
  disableCrumbs?: boolean;
};

export const Crumb = ({
  crumb,
  firstCrumb,
  linkStyle,
  lastCrumb,
  onClick,
  disableCrumbs = false,
}: CrumbProps) => {
  const scope = useCss(css);
  const icon = linkStyle ? "caret-small-right" : "caret-large-right";

  return (
    <div {...scope} className="cp-flex-center min-w-0">
      {!firstCrumb && <CpIcon name={icon} />}
      <CpButton
        btnType={linkStyle ? "unstyled" : "tertiary"}
        className={a("cp-ellipsis")
          .m("crumbLinkButton", linkStyle)
          .t("lastCrumb", "crumbButton", lastCrumb && !linkStyle)}
        disabled={lastCrumb || disableCrumbs}
        onClick={(e) => {
          e.stopPropagation();
          onClick(crumb);
        }}
      >
        {crumb?.name}
      </CpButton>
    </div>
  );
};

const css = k`
  .crumbButton {
    color: var(--cp-color-app-secondary-text) !important;
  }
  .lastCrumb {
    color: var(--cp-color-app-primary-text) !important;
    font-weight: 600 !important;
  }
  .crumbLinkButton {
    padding: 0 0.4rem !important;
    background-color: transparent !important;
    font-weight: 400 !important;
    color: var(--cp-color-app-secondary-text) !important;
    &:hover {
      text-decoration: underline !important;
    }
    &:hover::after {
      opacity: 0 !important
    }
    
  }
`;
