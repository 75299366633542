// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-create-edit-client-modal-create-edit-client-modal-styles__navSection {
  position: -webkit-sticky;
  position: sticky;
  top: 0.8rem;
  height: 100%;
  margin-top: 0.8rem;
  margin-left: 1.6rem;
  margin-right: 4rem;
}
`, "",{"version":3,"sources":["webpack://./src/create-edit-client-modal/create-edit-client-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAgB;EAAhB,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".navSection {\n  position: sticky;\n  top: 0.8rem;\n  height: 100%;\n  margin-top: 0.8rem;\n  margin-left: 1.6rem;\n  margin-right: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navSection": `src-create-edit-client-modal-create-edit-client-modal-styles__navSection`
};
export default ___CSS_LOADER_EXPORT___;
