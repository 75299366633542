import React from "react";
import { CpWell } from "canopy-styleguide!sofe";
import { tw } from "src/tailwind";

type MergeModalWellProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
};
export default function MergeModalWell({ title, children, className }: MergeModalWellProps) {
  return (
    <CpWell className={className}>
      <div className="cp-body-sm cp-wt-semibold cp-p-16">{title}</div>
      <hr className={tw("m-0")} />
      <div className={tw("p-4")}>{children}</div>
    </CpWell>
  );
}
