import React from "react";
import { property, partial, merge } from "lodash";
import { handleError } from "src/handle-error";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { CpDropdown, CpButton } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import canopyUrls from "canopy-urls!sofe";
import SectionProgress from "./cssf-section-progress.component";
import { SurveyFilePractitionerUpload } from "../files/survey-file-practitioner-upload.component";
import { useCrmHierarchy } from "../../common/use-crm-hierarchy.helper";
import { defaultNotification } from "../resolution-case-client-survey.resource";
import styles from "./cssf-progress.styles.css";

export default function CSSF_Progress(props) {
  const [user] = useWithUserAndTenant();
  const shouldUseNewHierarchy = useCrmHierarchy();
  const visibleSections = props.sections.filter(property("visible"));
  const visibleFinishedSections = visibleSections.filter(property("completed"));
  const isClient = user && !!user.permissions.client;
  const shouldShowSubmit = user && isClient;
  const taxPrep = props.surveyMetaData.surveyType === "taxPrep";
  const shouldShowClientUpload = taxPrep && isClient;
  const shouldShowPractitionerUpload = taxPrep && !isClient;
  const sendCommandText =
    props.clientSurvey.survey.status === "unsent" ? "Send" : "Resend";

  const surveyTypeText = taxPrep
    ? `${props.surveyMetaData?.surveyData?.returnType || ""} Organizer`
    : "Survey";

  const versionId = props.surveyMetaData?.surveyRevision?.version;
  const revisionId = props.surveyMetaData?.surveyRevision?.id;
  const version = versionId
    ? `${versionId}${revisionId ? `-${revisionId}` : ""}`
    : "";

  let downloadName = props.taskName ? props.taskName : "Organizer";
  const returnDownloadType =
    props.clientSurvey?.survey?.surveyData?.returnYear < 2022
      ? "fTaxOrganizer"
      : `fOrg${
          props.clientSurvey?.survey?.surveyData?.returnType?.replaceAll(
            "-",
            ""
          ) || ""
        }`;

  const downloadUrl = `${canopyUrls.getWebUrl()}/wg/clients/${
    props.clientId
  }/resolution_cases/${
    props.resolutionCaseId
  }/tax-form-pdfs/${returnDownloadType}?${
    version ? `version=${version}` : ""
  }&downloadName=${encodeURIComponent(downloadName)}`;

  const sectionsLength = props.sections.filter(
    (section) =>
      section.visible &&
      (!section.subSections || section.subSections.some(property("visible")))
  ).length;

  return (
    <div
      className={`cps-card ${styles.cssfProgress}`}
      style={{ top: props.displayingInClientPortal ? "96px" : "80px" }}
    >
      {shouldShowSubmit ? (
        <div className={`${styles.header}`}>
          <div className={styles.progressText}>
            <div className={`cps-wt-bold`}>Progress</div>
            <div className={`cps-color-primary`}>
              <span
                className={`cps-wt-bold`}
              >{`${visibleFinishedSections.length}/${visibleSections.length}`}</span>
              <span className={`cps-wt-light`}> Sections Complete</span>
            </div>
          </div>
          <div className={`cps-bg-gray-7 ${styles.progressBarBox}`}>
            <div
              className={`cps-bg-color-primary ${styles.progressBar}`}
              data-testid="progressBar"
              style={{
                width: `${
                  (visibleFinishedSections.length / visibleSections.length) *
                  100
                }%`,
              }}
            />
          </div>
        </div>
      ) : (
        <div className={`${styles.practitionerHeader}`}>
          <div className={`cps-wt-bold`}>{surveyTypeText}</div>
          <div className={`${styles.headerButtons}`}>
            <CpButton
              className="cp-mr-4"
              btnType="secondary"
              onClick={openClientModal.bind(
                this,
                props.clientId,
                props.clientSurvey,
                `${sendCommandText}`
              )}
            >
              {sendCommandText}
            </CpButton>
            <CpDropdown
              position="bottom-end"
              renderTrigger={({ toggle }) => (
                <CpButton
                  icon="misc-kabob"
                  className="cps-color-secondary-text"
                  aria-label="More"
                  onClick={toggle}
                />
              )}
              renderContent={() => (
                <div>
                  <div
                    className={`${styles.optionsListItem}`}
                    onClick={props.actions.openSurveyOptions}
                  >
                    {surveyTypeText} options
                  </div>
                  {taxPrep && (
                    <div>
                      <a
                        download
                        className={`${styles.optionsListLink}`}
                        href={downloadUrl}
                      >
                        Download form
                      </a>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </div>
      )}
      <div className="cps-padding-left-16 cps-padding-right-16">
        <hr />
      </div>
      <div className={`${styles.sections}`}>
        {props.sections.map((section, index) => {
          const activeSecId =
            props.activeSection !== "0"
              ? props.activeSection
              : property("[0].id")(props.sections);
          return (
            section.visible && (
              <SectionProgress
                key={index}
                getSubLink={(subIndex) =>
                  props.getSubLink(section.id, subIndex)
                }
                expanded={activeSecId == section.id}
                activeSection={activeSecId}
                activeSubsection={props.activeSubsection}
                sectionIndex={index}
                surveyId={props.clientSurvey.survey.id}
                section={section}
              />
            )
          );
        })}
        {shouldShowClientUpload && (
          <SectionProgress
            key="upload"
            getSubLink={() => `${props.baseUrl}/upload`}
            expanded={false}
            activeSection={
              window.location.hash.indexOf(`${props.baseUrl}/upload`) >= 0
                ? "upload"
                : null
            }
            activeSubsection={null}
            sectionIndex={sectionsLength}
            surveyId={props.clientSurvey.survey.id}
            section={{
              id: "upload",
              completed: false,
              name: "Upload documents",
            }}
          />
        )}
        {shouldShowSubmit && (
          <SectionProgress
            key="submit"
            getSubLink={() => `${props.baseUrl}/submit`}
            expanded={false}
            activeSection={
              window.location.hash.indexOf("/survey/submit") >= 0
                ? "submit"
                : null
            }
            activeSubsection={null}
            sectionIndex={
              shouldShowClientUpload ? sectionsLength + 1 : sectionsLength
            }
            surveyId={props.clientSurvey.survey.id}
            section={{
              id: "submit",
              completed: props.clientSurvey.survey.status === "submitted",
              name: "Submit to practitioner",
            }}
          />
        )}
      </div>
      {shouldShowPractitionerUpload && (
        <>
          <SurveyFilePractitionerUpload
            fileId={props.fileId}
            clientId={props.clientId}
            resolutionCaseId={props.resolutionCaseId}
            getSubLink={() => `#${props.baseUrl}/file`}
          />
        </>
      )}
    </div>
  );

  function openClientModal(clientId, clientSurvey, resendToClientText) {
    SystemJS.import("clients-ui!sofe")
      .then(({ showInviteClientModal }) => {
        let modalProps;
        let onInviteSuccess = (userIds, surveyNotification) => {
          let successCallback = partial(
            successToast,
            "Survey was successfully sent!"
          );
          if (clientSurvey.survey.surveyType === "taxRes") {
            successCallback = partial(
              successToast,
              "Client survey was successfully sent!"
            );
          } else if (clientSurvey.survey.surveyType === "taxPrep") {
            successCallback = () => {};
          }

          const clientAccess = merge({}, clientSurvey.survey.clientAccess, {
            hasAccess: true,
          });
          const patchedProperties = {
            previousClientRecipients: userIds,
            status: "sent",
            clientAccess,
          };
          if (surveyNotification) {
            patchedProperties.notification = surveyNotification;
          }
          props.actions.patchSurveyProperties(
            clientSurvey.survey.id,
            patchedProperties,
            successCallback,
            {
              clientNotifiees: userIds.join(","),
            }
          );
        };
        const disableNotification = clientSurvey.survey.surveyType === "taxRes"; // Don't send a notification since we'll be sending a survey specific one
        const infoText = `Need to ${resendToClientText.toLowerCase()} the survey to your client? Choose or invite the client you would like to send the survey to, and we'll notify them that you are ready for them to start working on it.`;
        const isSurveyNotification =
          clientSurvey.survey.surveyType === "taxRes"; // Survey notification experience
        const defaultNotif = defaultNotification(
          window.loggedInUser.name,
          props.surveyMetaData
        );
        const onlyNewUsers = clientSurvey.survey.surveyType === "taxPrep";
        if (shouldUseNewHierarchy) {
          modalProps = [
            {
              clientId,
              onInviteSuccess,
              infoText: `The ${
                clientSurvey.survey.surveyType === "taxRes"
                  ? "survey"
                  : "organizer"
              } will be sent to the client portal of the selected contacts below. If a selected client does not have a client portal login they will be sent an invite to create one.`,
              disableNotification,
              isSurveyNotification,
              defaultNotification: defaultNotif,
            },
          ];
        } else {
          modalProps = [
            clientId,
            onInviteSuccess,
            infoText,
            true, // Show existing users in the list
            disableNotification,
            isSurveyNotification,
            undefined, // close button text
            undefined, // close callback
            defaultNotif,
            [], // new users to add
            onlyNewUsers,
          ];
        }
        showInviteClientModal(...modalProps);
      })
      .catch(handleError);
  }
}
