import * as React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useFloatingTree, useListItem, useMergeRefs } from "@floating-ui/react";

import { MenuContext } from "../cp-floating-menu-context";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentType,
  ReactNode,
} from "react";
import { CpIcon, CpIconDynamic } from "../../cp-icon/cp-icon.component";
import { CpFloatingMenuInner } from "../cp-floating-menu-inner";
import { RenderIconDynamic } from "../../cp-icon/render-icon-dynamic";
import { always } from "kremling";

type Override<T, U> = Omit<T, keyof U> & U;

type CpFloatingMenuItemAsButton = {
  as?: "button";
} & Override<
  ButtonHTMLAttributes<HTMLButtonElement>,
  {
    onClick?: (e: any, meta: any) => void;
  }
>;
type CpFloatingMenuItemAsAnchor = {
  as?: "a";
} & AnchorHTMLAttributes<HTMLAnchorElement>;
type CpFloatingMenuItemAsLink = { as?: typeof Link } & LinkProps;

type CpFloatingMenuItemAs =
  | CpFloatingMenuItemAsButton
  | CpFloatingMenuItemAsAnchor
  | CpFloatingMenuItemAsLink;

type CpFloatingMenuItemProps = CpFloatingMenuItemAs & {
  children?: ReactNode;
  disabled?: boolean;
  iconLeft?: CpIconDynamic;
  iconRight?: CpIconDynamic;
  subtext?: string | ReactNode;
  text?: string | ReactNode;
};

export const CpFloatingMenuItem = React.forwardRef<
  HTMLButtonElement,
  CpFloatingMenuItemProps
>(
  (
    {
      text = "",
      subtext,
      iconLeft,
      iconRight,
      children,
      as = "button",
      ...props
    },
    forwardedRef,
  ) => {
    const menu = React.useContext(MenuContext);
    const item = useListItem({
      label: !props.disabled && typeof text === "string" ? text : null,
    });
    const tree = useFloatingTree();
    const isActive = item.index === menu.activeIndex;
    const selfRefs = useMergeRefs([item.ref, forwardedRef]);
    const Wrapper: ComponentType<any> | string = as;

    function renderItem({
      refs,
      isSubMenu,
      ...itemProps
    }: Record<string, any>) {
      return (
        <Wrapper
          {...props}
          {...itemProps}
          ref={refs}
          type="button"
          className={always("cp-floating-menu-item").maybe(
            "cp-floating-menu-item--disabled",
            props?.disabled,
          )}
          role="menuitem"
        >
          <div className="cp-floating-menu-item__inner">
            {!!iconLeft && (
              <RenderIconDynamic
                icon={iconLeft}
                fill={props.disabled ? "currentcolor" : undefined}
              />
            )}
            <div>
              <div>{text}</div>
              {subtext && (
                <div className="cp-floating-menu-item-sub-text">{subtext}</div>
              )}
            </div>
          </div>
          {isSubMenu && (
            <CpIcon
              className="sub-menu-icon"
              name="caret-large-right"
              fill={props.disabled ? "currentcolor" : undefined}
            />
          )}
          {!isSubMenu && !!iconRight && (
            <RenderIconDynamic
              icon={iconRight}
              fill={props.disabled ? "currentcolor" : undefined}
            />
          )}
        </Wrapper>
      );
    }

    if (children) {
      return (
        <CpFloatingMenuInner renderItem={renderItem}>
          {children}
        </CpFloatingMenuInner>
      );
    }

    return renderItem({
      refs: selfRefs,
      role: "menuitem",
      className: "MenuItem",
      tabIndex: isActive ? 0 : -1,
      ...menu.getItemProps({
        onClick(event: React.MouseEvent<HTMLButtonElement>) {
          props?.onClick?.(event as any, null);
          tree?.events.emit("click");
        },
        onFocus(event: React.FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event as any);
          menu.setHasFocusInside(true);
        },
      }),
    });
  },
);
