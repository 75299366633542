import React, { lazy, Suspense, useMemo, useState } from "react";
import { CpLoader, modalService, CpBadge } from "canopy-styleguide!sofe";
import { infoToast } from "toast-service!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { useBetas } from "cp-client-auth!sofe";

import { track } from "src/resources/analytics.resource";
import { translateFilterDataToJql } from "src/client-list/client-list.resource";
import BulkManageAssignmentsModal from "./bulk-manage-assignments-modal.component";
import BulkAssignClientOwnerModal from "./bulk-assign-client-owner-modal.component";
import BulkMakeInactiveModal from "./bulk-make-inactive-modal.component";
import BulkTagModal from "./bulk-tag-modal.component";
import BulkArchiveModal from "./bulk-archive-modal.component";
import { handleError } from "src/error";
import { BulkAddToClientGroupModal } from "./bulk-add-to-client-group-modal.component";
import { BulkClientPortalInviteModal } from "./bulk-client-portal-invite-modal";
import { clientQueries } from "src/queries";
import { ActionItemList } from "src/common/client-contact/action-item-list";

const CreateTaskModal = lazy(() => SystemJS.import("tasks-ui!sofe").then((tasksUI) => tasksUI.getCreateEditModal()));

const SendQuestionnaireModal = lazy(() => SystemJS.import("forms-ui!sofe").then((forms) => forms.getSendQnrModal()));

function trackOldAction(name, selection) {
  track("client_list.old-bulk-action-executed", {
    name,
    allSelected: selection.allSelected,
    selectionCount: selection.totalSelected,
  });
}

const bulkActions = {
  folder_templates: {
    permissions: ["files_client", "files_upload_move"],
    icon: "folder-add-template",
    label: "Add folder templates",
    translateJql: true,
    execute: async ({ jql, selection }) => {
      if (featureEnabled("toggle_files_folder_template_modal")) {
        const FolderTemplatesModal = await SystemJS.import("docs-ui!sofe").then((mod) =>
          mod.loadFolderTemplatesModal()
        );
        modalService.render(FolderTemplatesModal, { clientsJql: jql }, { width: 750 }).then((success) => {
          if (success) {
            infoToast(`Your folder templates are processing, we will notify you when they are done.`);
            trackOldAction("Folder Templates", selection);
          }
        });
      } else {
        SystemJS.import("docs-ui!sofe")
          .then((docsUI) => docsUI.loadFolderTemplatesModal())
          .then((modal) => {
            modal({ clientsJql: jql, showFolderTemplatesModal: true }).subscribe((success) => {
              if (success) {
                infoToast(`Your folder templates are processing, we will notify you when they are done.`);
                trackOldAction("Folder Templates", selection);
              }
            }, handleError);
          }, handleError);
      }
    },
  },
  ...(featureEnabled("toggle_gs_bulk_invoices") && {
    bulk_invoice: {
      permissions: ["bulk_invoicing"],
      icon: "billing-invoice",
      label: "Bulk invoice",
      translateJql: true,
      execute: ({ filters, ft_crm, jql, selection }) => {
        const bulkInvoiceClientData = ft_crm
          ? {
              filters,
              ...(selection.type === "includes" && { includes: selection.toArray() }),
              ...(selection.type === "excludes" && { excludes: selection.toArray() }),
            }
          : { jql };
        sessionStorage.setItem("bulkInvoiceClientData", JSON.stringify(bulkInvoiceClientData));
        window.location.href = "/#/billing/invoice/bulk/editor";
      },
    },
  }),
  bulk_file_copy: {
    permissions: ["files_client", "files_upload_move"],
    icon: "crud-duplicate",
    label: "Bulk copy files",
    translateJql: true,
    execute: ({ jql, selection }) => {
      const options = {
        clientsJql: jql,
        disableClients: true,
        hideNavActions: true,
        disableNewFolder: true,
        numOfSelectedClients: selection?.totalSelected,
        isBulkCopy: true,
        allowFolders: featureEnabled("toggle_bulk_copy_folders"),
      };
      SystemJS.import("docs-ui!sofe").then((docsUI) => {
        docsUI.loadFilePickerModal().then((modal) =>
          modal(options).subscribe((success) => {
            if (success) {
              infoToast(
                `Your "copy file/folder" requests are processing. You will be notified through the notification center when the process is finished.`
              );
            }
          }, handleError)
        );
      }, handleError);
    },
  },
  task: {
    permissions: ["tasks_generic", "tasks_create_edit"],
    icon: "checkmark-circle-open-large",
    label: "Bulk task",
    translateJql: true,
    renderModal: ({ jql, selection, closeModal, filters, search }) => {
      return (
        <Suspense fallback={<CpLoader />}>
          <CreateTaskModal
            show={true}
            onClose={closeModal}
            onSubmit={() => {
              closeModal();
              trackOldAction("Task", selection);
            }}
            mode="bulk"
            type="task"
            jql={jql}
            numOfSelectedClients={selection?.totalSelected}
            filters={filters}
            selection={selection}
            search={search}
          />
        </Suspense>
      );
    },
    execute: ({ jql, selection, showModal }) => {
      showModal("task", {
        jql,
        numOfSelectedClients: selection?.totalSelected,
      });
    },
  },
  send_organizer: {
    permissions: ["tasks_organizers", "tasks_create_edit"],
    icon: "misc-organizer",
    label: "Send organizer",
    translateJql: true,
    execute: async ({ jql, selection, filters, search }) => {
      const CreateOrganizerModal = await SystemJS.import("tax-prep-ui!sofe").then((mod) =>
        mod.getCreateOrganizerModal()
      );
      trackOldAction("Organizer", selection);
      modalService.render(CreateOrganizerModal, { jql, filters, selection, search }, { width: 600 });
    },
  },
  client_requests: {
    icon: "person-client-request",
    label: "Bulk client requests",
    translateJql: true,
    execute: async ({ jql, selection, filters, search }) => {
      const BulkClientRequestModal = await SystemJS.import("tasks-ui!sofe").then((mod) =>
        mod.getBulkClientRequestModal()
      );
      trackOldAction("Client Request", selection);
      modalService.render(
        BulkClientRequestModal,
        { jql, type: "bulk", mode: "create", filters, selection, search },
        { width: 624 }
      );
    },
  },
  email: {
    permissions: ["clients_bulk_email"],
    icon: "communication-envelope",
    label: "Bulk email",
    translateJql: true,
    execute: ({ jql, selection, filters, search, ft_crm }) => {
      const params = ft_crm
        ? { v2Filters: { [selection.type]: selection.toArray(), filters, search }, jql: [] }
        : { jql };

      SystemJS.import("communications-ui!sofe")
        .then((communicationsUI) => {
          communicationsUI.sendBulkEmail(params).subscribe((emailAction) => {
            trackOldAction("Email", selection);
            if (emailAction.updatedClients) {
              clientQueries.invalidate();
            }
          }, handleError);
        })
        .catch(handleError);
    },
  },
  ...(featureEnabled("toggle_bulk_engagement_create") && {
    engagements: {
      permissions: ["engagements_create_edit"],
      icon: "misc-briefcase",
      label: "Create engagements",
      execute: ({ selection }) => {
        SystemJS.import("engagements-ui!sofe")
          .then((engagementsUi) => {
            engagementsUi.getCreateEngagementOverlay().then((mod) => {
              mod.EngagementCreateOverlayService({
                mode: 'create',
                clientIds: selection.toArray(),
              }).then(() => {
                //not sure yet
              });
            }, handleError);
          })
          .catch(handleError);
      },
    }
  }),
  tags: {
    permissions: ["clients_create_edit"],
    icon: "organize-tag",
    label: "Tags",
    execute: ({ showModal }) => showModal("tags"),
    renderModal: ({ closeModal, selection, filters, search }) => (
      <BulkTagModal onClose={closeModal} selection={selection} filters={filters} search={search} />
    ),
  },
  archive: {
    permissions: ["clients_archive"],
    icon: "crud-archive",
    label: "Archive",
    execute: ({ showModal }) => showModal("archive"),
    renderModal: ({ closeModal, filters, selection, search }) => (
      <BulkArchiveModal onClose={closeModal} filters={filters} selection={selection} search={search} />
    ),
  },
  manage_assignments: {
    permissions: ["clients_assign_team_members"],
    icon: "person-people",
    label: "Manage assignments",
    execute: ({ showModal }) => {
      track("client-list.manage-assignments-clicked", { source: "Bulk actions" });
      showModal("manage_assignments");
    },
    renderModal: ({ closeModal, filters, selection, search }) => (
      <BulkManageAssignmentsModal onClose={closeModal} filters={filters} selection={selection} search={search} />
    ),
  },
  assign_client_owner: {
    permissions: ["clients_assign_team_members"],
    icon: "person",
    label: "Assign client owner",
    execute: ({ showModal }) => showModal("assign_client_owner"),
    renderModal: ({ closeModal, filters, selection, search }) => (
      <BulkAssignClientOwnerModal onClose={closeModal} filters={filters} selection={selection} search={search} />
    ),
  },
  inactivate: {
    permissions: ["clients_toggle_active"],
    icon: "person-x",
    label: "Make inactive",
    execute: ({ showModal }) => showModal("inactivate"),
    renderModal: ({ closeModal, filters, selection, search }) => (
      <BulkMakeInactiveModal onClose={closeModal} filters={filters} selection={selection} search={search} />
    ),
  },
  add_to_client_group: {
    permissions: ["client_groups_create_edit"],
    icon: "misc-client-group",
    label: "Add to client group",
    inclusiveOnly: true,
    execute: ({ showModal }) => showModal("add_to_client_group"),
    renderModal: ({ closeModal, selection }) => {
      return <BulkAddToClientGroupModal onClose={closeModal} selection={selection} />;
    },
  },
  client_portal_invite: {
    icon: "person-monitor",
    label: "Client portal invite",
    execute: ({ showModal }) => showModal("client_portal_invite"),
    renderModal: ({ closeModal, selection, filters, search }) => (
      <BulkClientPortalInviteModal onClose={closeModal} selection={selection} filters={filters} search={search} />
    ),
  },
  send_questionnaire: {
    permissions: ["questionnaires_send", "questionnaires", "templates_questionnaires"],
    icon: "communication-clipboard",
    iconRight: <CpBadge intent="secondary">Beta</CpBadge>,
    label: "Send questionnaire",
    disabledLabel: !featureEnabled("toggle_bulk_send_questionnaire") ? "Coming soon" : null,
    disabledCheck: (selection) => !featureEnabled("toggle_bulk_send_questionnaire"),
    execute: ({ showModal }) => showModal("send_questionnaire"),
    renderModal: ({ closeModal, selection, filters, search }) => (
      <Suspense fallback={null}>
        <SendQuestionnaireModal
          show={true}
          onClose={closeModal}
          selection={selection}
          filters={filters}
          search={search}
        />
      </Suspense>
    ),
  },
};
const bulkActionOrder = [
  "task",
  "email",
  "client_requests",
  "send_organizer",
  "folder_templates",
  ...(featureEnabled("toggle_gs_bulk_invoices") ? ["bulk_invoice"] : []),
  "bulk_file_copy",
  "send_questionnaire",
  "client_portal_invite",
  ...(featureEnabled("toggle_bulk_engagement_create") ? ["engagements"] : []),
  "assign_team_members",
  "manage_assignments",
  "assign_client_owner",
  "tags",
  "add_to_client_group",
  "inactivate",
  "archive",
];

export default function BulkActionList({ actions: allowedActions, filters, search, selection, ft_crm }) {
  const hasQuestionnaireBeta = useBetas("beta_questionnaires");
  const availableActions = useMemo(() => {
    const list = [];
    bulkActionOrder.forEach((actionId) => {
      if (allowedActions?.includes(actionId) === false) return;
      if (!ft_crm && actionId === "add_to_client_group") return;
      if (ft_crm && actionId === "email" && !featureEnabled("ft_crm_bulk_email")) return;
      if ((!ft_crm || !featureEnabled("ft_crm_bulk_client_portal_invite")) && actionId === "client_portal_invite")
        return;
      if (actionId === "send_questionnaire" && !featureEnabled("toggle_custom_forms") && !hasQuestionnaireBeta) return;
      const action = bulkActions[actionId];
      if (action) {
        const validInclusiveSelection = action.inclusiveOnly && selection.type === "includes";
        if (!action.inclusiveOnly || validInclusiveSelection) {
          list.push({ id: actionId, ...action });
        }
      }
    });
    return list;
  }, [allowedActions, selection, ft_crm, hasQuestionnaireBeta]);

  const [currentModal, setCurrentModal] = useState(null);
  function showModal(actionId, modalProps) {
    setCurrentModal({ actionId, modalProps });
  }
  function closeModal() {
    setCurrentModal(null);
  }

  function executeAction(action) {
    if (!action.execute) return;
    const executeArgs = {
      filters,
      selection,
      search,
      ft_crm,
    };

    if (action.translateJql) {
      translateFilterDataToJql({
        filters,
        search,
        [selection.type]: selection.toArray(),
      }).subscribe((res) => {
        executeArgs.jql = res.jql;
        executeArgs.showModal = (actionId) => showModal(actionId, { ...executeArgs });
        action.execute(executeArgs);
      }, handleError);
    } else {
      executeArgs.showModal = (actionId) => showModal(actionId, { ...executeArgs });
      action.execute(executeArgs);
    }
  }

  return (
    <div>
      <ActionItemList actionItems={availableActions} actionItemOrder={bulkActionOrder} onActionClick={executeAction} />
      {currentModal &&
        bulkActions[currentModal.actionId].renderModal({ ...currentModal.modalProps, closeModal, selection })}
    </div>
  );
}
